import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "../utils/axiosInstance";
import { BidOptimizerState, JobConfig, JobRun } from "../models/interfaces";
import { JobType } from "../constants/JobTypes";

// Initial State
const initialState: BidOptimizerState = {
  jobConfigList: [],
  jobRunList: [],
  loading: false,
};

// Async Thunks
export const fetchJobConfigBidOptimizer = createAsyncThunk<JobConfig[]>(
  "bidOptimizer/fetchJobConfig",
  async () => {
    const response = await axiosInstance.get(
      `/api/jobConfig/jobType/${JobType.BidOptimizer}`
    );
    return response.data.items;
  }
);

export const fetchJobRunBidOptimizer = createAsyncThunk<JobRun[]>(
  "bidOptimizer/fetchJobRun",
  async () => {
    const response = await axiosInstance.get(
      `/api/jobRun/jobType/${JobType.BidOptimizer}`
    );
    return response.data.items;
  }
);

// Redux Slice
const bidOptimizerSlice = createSlice({
  name: "bidOptimizer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchJobConfigBidOptimizer.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        fetchJobConfigBidOptimizer.fulfilled,
        (state, action: PayloadAction<JobConfig[]>) => {
          state.jobConfigList = action.payload;
          state.loading = false;
        }
      )
      .addCase(fetchJobConfigBidOptimizer.rejected, (state) => {
        state.loading = false;
      })
      .addCase(
        fetchJobRunBidOptimizer.fulfilled,
        (state, action: PayloadAction<JobRun[]>) => {
          state.jobRunList = action.payload;
        }
      );
  },
});

export default bidOptimizerSlice.reducer;
