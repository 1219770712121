import { JobType } from "../../constants/JobTypes";
import JobConfig from "../JobConfig";
import JobConfigCreateMMM from "./JobConfigCreateMMM";
import JobConfigBidOptimizer from './JobConfigBidOptimizer'
import JobConfigNextBestAction from "./JobConfigNextBestAction";
import JobConfigCreateGeoLift from "./JobConfigCreateGeoLift";
import GeoLiftStudy from "../../pages/measurement/geo-lift/components/GeoLiftStudy";
import MMMJobsCreate from "../../pages/measurement/marketing-mix-model/components/MMMJobsCreate";


export class JobConfigComponentFactory {
  createJobConfigComponent(jobType: JobType) {
    if (jobType === JobType.MarketingMixModel) {
      return <MMMJobsCreate />;
      // return <JobConfigCreateMMM />;
    }
    if (jobType === JobType.GeoLift) {
      return <GeoLiftStudy />;
      return <JobConfigCreateGeoLift />;
    }
    if (jobType === JobType.BidOptimizer) {
      return <JobConfigBidOptimizer />;
    }
    if (jobType === JobType.NextBestAction) {
      return <JobConfigNextBestAction />;
    }
    return <JobConfig jobType={jobType}></JobConfig>;
  }
}

