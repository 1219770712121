import Table, { TableColumn } from "../../components/Table";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../utils/axiosInstance";
import { Link, useNavigate } from "react-router-dom";

const columns: TableColumn[] = [
  { key: "email", label: "Email" },
  { key: "userName", label: "User Name" },
  { key: "firstName", label: "First Name" },
  { key: "lastName", label: "Last Name" },
  { key: "role", label: "Role" },
  { key: "title", label: "Title" },
  { key: "action", label: "" },
];

interface UserProps {
  users: {}[];
}

const breadcrumbs = [
  {
    name: "Home",
    url: "/",
  },
  {
    name: "Users",
    url: "/users",
  },
];

const Users: React.FC = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [isAlert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [isLoadingReseting, setIsLoadingReseting] = useState<string | null>(
    null
  ); // Updated to track loading for specific userId

  // Edit Action Handler (Can be filled later)
  const handleEdit = async (userId: string) => {
    navigate(`/users/update-user/${userId}`);
  };

  const handleAlert = () => {
    setAlert(true);
    setTimeout(() => {
      setAlert(false);
    }, 100000);
  };

  // Reset Password Handler
  const handleReset = async (userId: string, email: string) => {
    setIsLoadingReseting(userId); // Track which user is being reset
    try {
      // Make API call for password reset action
      const resetResponse = await axiosInstance.post(
        `/api/users/${userId}/passwordReset`
      );
      // Handle success (You can add toast or any UI change)
      handleAlert();
      setAlertMessage(
        `New Password for User "${email}" is "${resetResponse.data.password}"`
      );
    } catch (error) {
      console.error("Password reset action failed for user:", userId, error);
    } finally {
      setIsLoadingReseting(null);
    }
  };

  // Function to generate action buttons for each user
  const generateUserEditAction = (userId: string, email: string) => (
    <div className="flex space-x-4">
      <button className="btn btn-sm" onClick={() => handleEdit(userId)}>
        Edit Password
      </button>

      <button
        className={`btn btn-sm ${
          isLoadingReseting === userId ? "loading" : ""
        }`}
        onClick={() => handleReset(userId, email)}
        disabled={isLoadingReseting === userId}
      >
        {isLoadingReseting === userId ? "Reseting..." : "Reset Password"}
      </button>
    </div>
  );

  useEffect(() => {
    axiosInstance.get("/api/users").then((usersResponse) => {
      const userEdit = usersResponse.data.items.map((user: any) => {
        return {
          ...user,
          action: generateUserEditAction(user.userId, user.email), // Pass the userId to the action buttons
        };
      });
      setUsers(userEdit as any);
    });
  }, [generateUserEditAction]);

  return (
    <>
      <div className="p-8 flex flex-col">
        <div className="flex justify-between">
          <div className="text-sm breadcrumbs self-end">
            <ul>
              {breadcrumbs.map((entity) => (
                <li key={entity.name}>
                  <Link to={entity.url} className="font-mono underline">
                    {entity.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="self-end">
            <Link to={"/users/create-user"} className="w-40 mb-4">
              <button className="btn w-full">Create User</button>
            </Link>
          </div>
        </div>
        <div className="divider"></div>
        <Table columns={columns} records={users} />
      </div>
      {isAlert && (
        <div
          role="alert"
          className="alert alert-success absolute bottom-8 right-8 w-auto"
        >
          <span className="ml-2">{alertMessage}</span>
        </div>
      )}
    </>
  );
};

export default Users;
