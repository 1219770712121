export const DMA_LIST = [
    {
        "key": "500",
        "gg_ads_key": "200500",
        "name": "Portland-Auburn",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "501",
        "gg_ads_key": "200501",
        "name": "New York",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "502",
        "gg_ads_key": "200502",
        "name": "Binghamton",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "503",
        "gg_ads_key": "200503",
        "name": "Macon",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "504",
        "gg_ads_key": "200504",
        "name": "Philadelphia",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "505",
        "gg_ads_key": "200505",
        "name": "Detroit",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "506",
        "gg_ads_key": "200506",
        "name": "Boston (Manchester)",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "507",
        "gg_ads_key": "200507",
        "name": "Savannah",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "508",
        "gg_ads_key": "200508",
        "name": "Pittsburgh",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "509",
        "gg_ads_key": "200509",
        "name": "Ft. Wayne",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "510",
        "gg_ads_key": "200510",
        "name": "Cleveland-Akron (Canton)",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "511",
        "gg_ads_key": "200511",
        "name": "Washington, DC (Hagrstwn)",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "512",
        "gg_ads_key": "200512",
        "name": "Baltimore",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "513",
        "gg_ads_key": "200513",
        "name": "Flint-Saginaw-Bay City",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "514",
        "gg_ads_key": "200514",
        "name": "Buffalo",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "515",
        "gg_ads_key": "200515",
        "name": "Cincinnati",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "516",
        "gg_ads_key": "200516",
        "name": "Erie",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "517",
        "gg_ads_key": "200517",
        "name": "Charlotte",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "518",
        "gg_ads_key": "200518",
        "name": "Greensboro-H.Point-W.Salem",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "519",
        "gg_ads_key": "200519",
        "name": "Charleston, SC",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "520",
        "gg_ads_key": "200520",
        "name": "Augusta-Aiken",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "521",
        "gg_ads_key": "200521",
        "name": "Providence-New Bedford",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "522",
        "gg_ads_key": "200522",
        "name": "Columbus, GA (Opelika, Al)",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "523",
        "gg_ads_key": "200523",
        "name": "Burlington-Plattsburgh",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "524",
        "gg_ads_key": "200524",
        "name": "Atlanta",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "525",
        "gg_ads_key": "200525",
        "name": "Albany, GA",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "526",
        "gg_ads_key": "200526",
        "name": "Utica",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "527",
        "gg_ads_key": "200527",
        "name": "Indianapolis",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "528",
        "gg_ads_key": "200528",
        "name": "Miami-Ft. Lauderdale",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "529",
        "gg_ads_key": "200529",
        "name": "Louisville",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "530",
        "gg_ads_key": "200530",
        "name": "Tallahassee-Thomasville",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "531",
        "gg_ads_key": "200531",
        "name": "Tri-Cities, TN-VA",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "532",
        "gg_ads_key": "200532",
        "name": "Albany-Schenectady-Troy",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "533",
        "gg_ads_key": "200533",
        "name": "Hartford & New Haven",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "534",
        "gg_ads_key": "200534",
        "name": "Orlando-Daytona Bch-Melbrn",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "535",
        "gg_ads_key": "200535",
        "name": "Columbus, OH",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "536",
        "gg_ads_key": "200536",
        "name": "Youngstown",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "537",
        "gg_ads_key": "200537",
        "name": "Bangor",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "538",
        "gg_ads_key": "200538",
        "name": "Rochester, NY",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "539",
        "gg_ads_key": "200539",
        "name": "Tampa-St. Pete (Sarasota)",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "540",
        "gg_ads_key": "200540",
        "name": "Traverse City-Cadillac",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "541",
        "gg_ads_key": "200541",
        "name": "Lexington",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "542",
        "gg_ads_key": "200542",
        "name": "Dayton",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "543",
        "gg_ads_key": "200543",
        "name": "Springfield-Holyoke",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "544",
        "gg_ads_key": "200544",
        "name": "Norfolk-Portsmth-Newpt Nws",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "545",
        "gg_ads_key": "200545",
        "name": "Greenville-N.Bern-Washngtn",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "546",
        "gg_ads_key": "200546",
        "name": "Columbia, SC",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "547",
        "gg_ads_key": "200547",
        "name": "Toledo",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "548",
        "gg_ads_key": "200548",
        "name": "West Palm Beach-Ft. Pierce",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "549",
        "gg_ads_key": "200549",
        "name": "Watertown",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "550",
        "gg_ads_key": "200550",
        "name": "Wilmington",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "551",
        "gg_ads_key": "200551",
        "name": "Lansing",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "552",
        "gg_ads_key": "200552",
        "name": "Presque Isle",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "553",
        "gg_ads_key": "200553",
        "name": "Marquette",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "554",
        "gg_ads_key": "200554",
        "name": "Wheeling-Steubenville",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "555",
        "gg_ads_key": "200555",
        "name": "Syracuse",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "556",
        "gg_ads_key": "200556",
        "name": "Richmond-Petersburg",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "557",
        "gg_ads_key": "200557",
        "name": "Knoxville",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "558",
        "gg_ads_key": "200558",
        "name": "Lima",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "559",
        "gg_ads_key": "200559",
        "name": "Bluefield-Beckley-Oak Hill",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "560",
        "gg_ads_key": "200560",
        "name": "Raleigh-Durham (Fayetvlle)",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "561",
        "gg_ads_key": "200561",
        "name": "Jacksonville",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "563",
        "gg_ads_key": "200563",
        "name": "Grand Rapids-Kalmzoo-B.Crk",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "564",
        "gg_ads_key": "200564",
        "name": "Charleston-Huntington",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "565",
        "gg_ads_key": "200565",
        "name": "Elmira (Corning)",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "566",
        "gg_ads_key": "200566",
        "name": "Harrisburg-Lncstr-Leb-York",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "567",
        "gg_ads_key": "200567",
        "name": "Greenvll-Spart-Ashevll-And",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "569",
        "gg_ads_key": "200569",
        "name": "Harrisonburg",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "570",
        "gg_ads_key": "200570",
        "name": "Myrtle Beach-Florence",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "571",
        "gg_ads_key": "200571",
        "name": "Ft. Myers-Naples",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "573",
        "gg_ads_key": "200573",
        "name": "Roanoke-Lynchburg",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "574",
        "gg_ads_key": "200574",
        "name": "Johnstown-Altoona-St Colge",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "575",
        "gg_ads_key": "200575",
        "name": "Chattanooga",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "576",
        "gg_ads_key": "200576",
        "name": "Salisbury",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "577",
        "gg_ads_key": "200577",
        "name": "Wilkes Barre-Scranton-Hztn",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "581",
        "gg_ads_key": "200581",
        "name": "Terre Haute",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "582",
        "gg_ads_key": "200582",
        "name": "Lafayette, IN",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "583",
        "gg_ads_key": "200583",
        "name": "Alpena",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "584",
        "gg_ads_key": "200584",
        "name": "Charlottesville",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "588",
        "gg_ads_key": "200588",
        "name": "South Bend-Elkhart",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "592",
        "gg_ads_key": "200592",
        "name": "Gainesville",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "596",
        "gg_ads_key": "200596",
        "name": "Zanesville",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "597",
        "gg_ads_key": "200597",
        "name": "Parkersburg",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "598",
        "gg_ads_key": "200598",
        "name": "Clarksburg-Weston",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "600",
        "gg_ads_key": "200600",
        "name": "Corpus Christi",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "602",
        "gg_ads_key": "200602",
        "name": "Chicago",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "603",
        "gg_ads_key": "200603",
        "name": "Joplin-Pittsburg",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "604",
        "gg_ads_key": "200604",
        "name": "Columbia-Jefferson City",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "605",
        "gg_ads_key": "200605",
        "name": "Topeka",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "606",
        "gg_ads_key": "200606",
        "name": "Dothan",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "609",
        "gg_ads_key": "200609",
        "name": "St. Louis",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "610",
        "gg_ads_key": "200610",
        "name": "Rockford",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "611",
        "gg_ads_key": "200611",
        "name": "Rochester-Mason City-Austin",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "612",
        "gg_ads_key": "200612",
        "name": "Shreveport",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "613",
        "gg_ads_key": "200613",
        "name": "Minneapolis-St. Paul",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "616",
        "gg_ads_key": "200616",
        "name": "Kansas City",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "617",
        "gg_ads_key": "200617",
        "name": "Milwaukee",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "618",
        "gg_ads_key": "200618",
        "name": "Houston",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "619",
        "gg_ads_key": "200619",
        "name": "Springfield, MO",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "622",
        "gg_ads_key": "200622",
        "name": "New Orleans",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "623",
        "gg_ads_key": "200623",
        "name": "Dallas-Ft. Worth",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "624",
        "gg_ads_key": "200624",
        "name": "Sioux City",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "625",
        "gg_ads_key": "200625",
        "name": "Waco-Temple-Bryan",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "626",
        "gg_ads_key": "200626",
        "name": "Victoria",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "627",
        "gg_ads_key": "200627",
        "name": "Wichita Falls & Lawton",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "628",
        "gg_ads_key": "200628",
        "name": "Monroe-El Dorado",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "630",
        "gg_ads_key": "200630",
        "name": "Birmingham (Ann And Tusc)",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "631",
        "gg_ads_key": "200631",
        "name": "Ottumwa-Kirksville",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "632",
        "gg_ads_key": "200632",
        "name": "Paducah-Cape Girard-Harsbg",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "633",
        "gg_ads_key": "200633",
        "name": "Odessa-Midland",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "634",
        "gg_ads_key": "200634",
        "name": "Amarillo",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "635",
        "gg_ads_key": "200635",
        "name": "Austin",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "636",
        "gg_ads_key": "200636",
        "name": "Harlingen-Wslco-Brnsvl-Mca",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "637",
        "gg_ads_key": "200637",
        "name": "Cedar Rapids-Wtrlo-Iwc&Dub",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "638",
        "gg_ads_key": "200638",
        "name": "St. Joseph",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "639",
        "gg_ads_key": "200639",
        "name": "Jackson, TN",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "640",
        "gg_ads_key": "200640",
        "name": "Memphis",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "641",
        "gg_ads_key": "200641",
        "name": "San Antonio",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "642",
        "gg_ads_key": "200642",
        "name": "Lafayette, LA",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "643",
        "gg_ads_key": "200643",
        "name": "Lake Charles",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "644",
        "gg_ads_key": "200644",
        "name": "Alexandria, LA",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "647",
        "gg_ads_key": "200647",
        "name": "Greenwood-Greenville",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "648",
        "gg_ads_key": "200648",
        "name": "Champaign&Sprngfld-Decatur",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "649",
        "gg_ads_key": "200649",
        "name": "Evansville",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "650",
        "gg_ads_key": "200650",
        "name": "Oklahoma City",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "651",
        "gg_ads_key": "200651",
        "name": "Lubbock",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "652",
        "gg_ads_key": "200652",
        "name": "Omaha",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "656",
        "gg_ads_key": "200656",
        "name": "Panama City",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "657",
        "gg_ads_key": "200657",
        "name": "Sherman-Ada",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "658",
        "gg_ads_key": "200658",
        "name": "Green Bay-Appleton",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "659",
        "gg_ads_key": "200659",
        "name": "Nashville",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "661",
        "gg_ads_key": "200661",
        "name": "San Angelo",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "662",
        "gg_ads_key": "200662",
        "name": "Abilene-Sweetwater",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "669",
        "gg_ads_key": "200669",
        "name": "Madison",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "670",
        "gg_ads_key": "200670",
        "name": "Ft. Smith-Fay-Sprngdl-Rgrs",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "671",
        "gg_ads_key": "200671",
        "name": "Tulsa",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "673",
        "gg_ads_key": "200673",
        "name": "Columbus-Tupelo-W Pnt-Hstn",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "675",
        "gg_ads_key": "200675",
        "name": "Peoria-Bloomington",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "676",
        "gg_ads_key": "200676",
        "name": "Duluth-Superior",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "678",
        "gg_ads_key": "200678",
        "name": "Wichita-Hutchinson Plus",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "679",
        "gg_ads_key": "200679",
        "name": "Des Moines-Ames",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "682",
        "gg_ads_key": "200682",
        "name": "Davenport-R.Island-Moline",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "686",
        "gg_ads_key": "200686",
        "name": "Mobile-Pensacola (Ft Walt)",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "687",
        "gg_ads_key": "200687",
        "name": "Minot-Bsmrck-Dcknsn(Wlstn)",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "691",
        "gg_ads_key": "200691",
        "name": "Huntsville-Decatur (Flor)",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "692",
        "gg_ads_key": "200692",
        "name": "Beaumont-Port Arthur",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "693",
        "gg_ads_key": "200693",
        "name": "Little Rock-Pine Bluff",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "698",
        "gg_ads_key": "200698",
        "name": "Montgomery-Selma",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "702",
        "gg_ads_key": "200702",
        "name": "La Crosse-Eau Claire",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "705",
        "gg_ads_key": "200705",
        "name": "Wausau-Rhinelander",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "709",
        "gg_ads_key": "200709",
        "name": "Tyler-Longview(Lfkn&Ncgd)",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "710",
        "gg_ads_key": "200710",
        "name": "Hattiesburg-Laurel",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "711",
        "gg_ads_key": "200711",
        "name": "Meridian",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "716",
        "gg_ads_key": "200716",
        "name": "Baton Rouge",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "717",
        "gg_ads_key": "200717",
        "name": "Quincy-Hannibal-Keokuk",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "718",
        "gg_ads_key": "200718",
        "name": "Jackson, MS",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "722",
        "gg_ads_key": "200722",
        "name": "Lincoln & Hastings-Krny",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "724",
        "gg_ads_key": "200724",
        "name": "Fargo-Valley City",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "725",
        "gg_ads_key": "200725",
        "name": "Sioux Falls(Mitchell)",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "734",
        "gg_ads_key": "200734",
        "name": "Jonesboro",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "736",
        "gg_ads_key": "200736",
        "name": "Bowling Green",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "737",
        "gg_ads_key": "200737",
        "name": "Mankato",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "740",
        "gg_ads_key": "200740",
        "name": "North Platte",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "743",
        "gg_ads_key": "200743",
        "name": "Anchorage",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "744",
        "gg_ads_key": "200744",
        "name": "Honolulu",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "745",
        "gg_ads_key": "200745",
        "name": "Fairbanks",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "746",
        "gg_ads_key": "200746",
        "name": "Biloxi-Gulfport",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "747",
        "gg_ads_key": "200747",
        "name": "Juneau",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "749",
        "gg_ads_key": "200749",
        "name": "Laredo",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "751",
        "gg_ads_key": "200751",
        "name": "Denver",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "752",
        "gg_ads_key": "200752",
        "name": "Colorado Springs-Pueblo",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "753",
        "gg_ads_key": "200753",
        "name": "Phoenix (Prescott)",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "754",
        "gg_ads_key": "200754",
        "name": "Butte-Bozeman",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "755",
        "gg_ads_key": "200755",
        "name": "Great Falls",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "756",
        "gg_ads_key": "200756",
        "name": "Billings",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "757",
        "gg_ads_key": "200757",
        "name": "Boise",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "758",
        "gg_ads_key": "200758",
        "name": "Idaho Fals-Pocatllo(Jcksn)",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "759",
        "gg_ads_key": "200759",
        "name": "Cheyenne-Scottsbluff",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "760",
        "gg_ads_key": "200760",
        "name": "Twin Falls",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "762",
        "gg_ads_key": "200762",
        "name": "Missoula",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "764",
        "gg_ads_key": "200764",
        "name": "Rapid City",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "765",
        "gg_ads_key": "200765",
        "name": "El Paso (Las Cruces)",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "766",
        "gg_ads_key": "200766",
        "name": "Helena",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "767",
        "gg_ads_key": "200767",
        "name": "Casper-Riverton",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "770",
        "gg_ads_key": "200770",
        "name": "Salt Lake City",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "771",
        "gg_ads_key": "200771",
        "name": "Yuma-El Centro",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "773",
        "gg_ads_key": "200773",
        "name": "Grand Junction-Montrose",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "789",
        "gg_ads_key": "200789",
        "name": "Tucson (Sierra Vista)",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "790",
        "gg_ads_key": "200790",
        "name": "Albuquerque-Santa Fe",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "798",
        "gg_ads_key": "200798",
        "name": "Glendive",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "800",
        "gg_ads_key": "200800",
        "name": "Bakersfield",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "801",
        "gg_ads_key": "200801",
        "name": "Eugene",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "802",
        "gg_ads_key": "200802",
        "name": "Eureka",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "803",
        "gg_ads_key": "200803",
        "name": "Los Angeles",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "804",
        "gg_ads_key": "200804",
        "name": "Palm Springs",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "807",
        "gg_ads_key": "200807",
        "name": "San Francisco-Oak-San Jose",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "810",
        "gg_ads_key": "200810",
        "name": "Yakima-Pasco-Rchlnd-Knnwck",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "811",
        "gg_ads_key": "200811",
        "name": "Reno",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "813",
        "gg_ads_key": "200813",
        "name": "Medford-Klamath Falls",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "819",
        "gg_ads_key": "200819",
        "name": "Seattle-Tacoma",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "820",
        "gg_ads_key": "200820",
        "name": "Portland, OR",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "821",
        "gg_ads_key": "200821",
        "name": "Bend, OR",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "825",
        "gg_ads_key": "200825",
        "name": "San Diego",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "828",
        "gg_ads_key": "200828",
        "name": "Monterey-Salinas",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "839",
        "gg_ads_key": "200839",
        "name": "Las Vegas",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "855",
        "gg_ads_key": "200855",
        "name": "Santabarbra-Sanmar-Sanluob",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "862",
        "gg_ads_key": "200862",
        "name": "Sacramnto-Stkton-Modesto",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "866",
        "gg_ads_key": "200866",
        "name": "Fresno-Visalia",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "868",
        "gg_ads_key": "200868",
        "name": "Chico-Redding",
        "type": "geo_market",
        "country_code": "US",
    },
    {
        "key": "881",
        "gg_ads_key": "200881",
        "name": "Spokane",
        "type": "geo_market",
        "country_code": "US",
    }
];