import { Button, Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import Table, { TableColumn } from "../../../../components/Table";
import Maximize from "../../../../components/Maximize";
import HeatMap from "../../../../components/HeatMap";

import {
  FinalizeDependentVariablesProps,
  LiftData,
} from "../../../../models/interfaces";
import LineChart from "../../../../components/LineChart";

const multiColinearityColumn: TableColumn[] = [
  { key: "variable", label: "Variable" },
  { key: "value", label: "Value" },
];

const FinalizeDependentVariables: React.FC<FinalizeDependentVariablesProps> = ({
  csvData,
  columnMappings,
  metaFields,
  variableSummaryStatistic,
  multiColinearityRecords,
  heatMapData,
  allVariiables,
  dependentVariables,
  seasonLabel,
  seasonDataSet,
  setDependentVariables,
  submitFeatureForm, // Destructure the function
}) => {
  const [liftData] = useState<LiftData>({
    percentage: 4.1,
    title: "Geo lift for Adrsta",
    confidence: 60,
    date: "21 Jul | 08:20-10:30",
    spend: 20000,
    sales: 68000,
  });
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="p-4">
      <p className="text-lg font-medium text-center text-gray-500 mb-4">
        Execute the test considering the closely related variables with the KPIs
      </p>

      <div className="flex flex-col gap-6">
        <div>
          <h3 className="text-md font-semibold text-gray-700 mb-4">
            Multi Collinearity Check
          </h3>
          <Table
            columns={multiColinearityColumn}
            records={multiColinearityRecords}
          />
          <div className="width-full mt-4">
            {heatMapData && (
              <Maximize title={"Correlation Matrix"}>
                <HeatMap data={heatMapData} />
              </Maximize>
            )}
          </div>
        </div>

        <div className="flex flex-col-reverse justify-between mt-3">
          {/* <div className="bg-white rounded-xl shadow-md p-6 mt-4 w-1/2">
            <h3 className="text-lg font-semibold text-gray-700 mb-3 text-center">
              Correlation Graph
            </h3>
            <p className="text-gray-600 mb-4 text-center">
              Explore Mean, Sum, Median, and Standard Deviation for each
              variable. View variable statistics.
            </p>
            <div className="text-center">
              <Button
                onClick={() => setModalOpen(true)}
                type="primary"
                className="w-full"
              >
                View Correlation Chart
              </Button>
            </div>
          </div> */}
          <div className="bg-white rounded-xl shadow-md p-6 ">
            <div className="w-full">
              <Maximize title="Seasonality">
                <LineChart
                  labels={seasonLabel}
                  dataSets={seasonDataSet}
                  yAxisTitle="Level Component For Sale"
                  xAxisTitle="Date"
                />
              </Maximize>
            </div>
          </div>
        </div>
        <div>
          <label className="font-bold text-lg">Select dependent variable</label>
          <Select
            className="mt-1"
            mode="multiple"
            options={[
              ...allVariiables.spendVariable.items,
              ...allVariiables.impressionVariable.items,
              ...allVariiables.nonMediaVariable.items,
            ]
              .filter((ele: any) => ele.label !== "Date")
              .map((item) => ({
                value: item.label,
                label: item.label,
              }))}
            value={dependentVariables}
            onChange={(value) => setDependentVariables(value)}
            placeholder="Select an option"
            style={{ width: "100%" }}
          />
        </div>
      </div>
      <Modal
        width={"100%"}
        open={modalOpen}
        onCancel={() => setModalOpen(false)}
        onOk={() => setModalOpen(false)}
      >
        {heatMapData && (
          <Maximize title={"Correlation Matrix"}>
            <HeatMap data={heatMapData} />
          </Maximize>
        )}
      </Modal>
    </div>
  );
};

export default FinalizeDependentVariables;
