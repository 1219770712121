import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axiosInstance from '../utils/axiosInstance';
import { JobType } from '../constants/JobTypes';
import { createAction } from '@reduxjs/toolkit';

// Define interfaces for the state
interface GeoLiftOutput {
  BestMarkets?: any[];
  DetailedResults?: any[];
  PowerCurves?: any[];
}

interface GeoLiftState {
  jobConfigList: any[];
  jobRunList: any[];
  loading: boolean;
  outputJson: GeoLiftOutput | null;
  jobConfig: any | null;
  totalCount: number;
}

// Initial state
const initialState: GeoLiftState = {
  jobConfigList: [],
  jobRunList: [],
  loading: false,
  outputJson: null,
  jobConfig: null,
  totalCount: 0,
};

export const fetchJobConfigList = createAsyncThunk(
  'geoLift/fetchJobConfigList',
  async ({ limit, offset }: { limit: number; offset: number }, { rejectWithValue, signal }) => {
    try {
      const response = await axiosInstance.get(`/api/jobConfig/jobType/${JobType.GeoLift}`, {
        params: { limit, offset },
        signal
      });
      return {
        items: response.data.items,
        totalCount: response.data.total_count,
        offset
      };
    } catch (error: any) {
      if (error.name === 'CanceledError') {
        return rejectWithValue({ message: 'Request canceled' });
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchJobRunList = createAsyncThunk('geoLift/fetchJobRunList', async () => {
  const response = await axiosInstance.get('/api/jobRun/jobType/GEO_LIFT');
  return response.data.items;
});

export const fetchGeoLiftOutput = createAsyncThunk(
  'geoLift/fetchOutput',
  async (id: string) => {
    const [configResponse, outputResponse] = await Promise.all([
      axiosInstance.get(`/api/jobConfig/${id}`),
      axiosInstance.get(`/api/geo-lift/output/${id}`)
    ]);
    
    let outputJson = outputResponse.data.result;
    if (typeof outputJson === "string") {
      try {
        outputJson = JSON.parse(outputJson);
      } catch (e) {
        console.error("Error parsing JSON:", e);
      }
    }
    
    return {
      jobConfig: configResponse.data,
      outputJson
    };
  }
);

export const resetJobConfigList = createAction('geoLift/resetJobConfigList');

const geoLiftSlice = createSlice({
  name: 'geoLift',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchJobConfigList.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchJobConfigList.fulfilled, (state, action) => {
        const expectedOffset = state.jobConfigList.length;
        if (action.payload.offset !== expectedOffset) return;

        const newResults = action.payload.items.filter((newJob: any) => 
          !state.jobConfigList.some(existingJob => existingJob.id === newJob.id)
        );
        
        state.jobConfigList = [...state.jobConfigList, ...newResults];
        state.totalCount = action.payload.totalCount;
        state.loading = false;
      })
      .addCase(fetchJobConfigList.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchJobRunList.fulfilled, (state, action) => {
        state.jobRunList = action.payload;
      })
      .addCase(fetchGeoLiftOutput.fulfilled, (state, action) => {
        state.jobConfig = action.payload.jobConfig;
        state.outputJson = action.payload.outputJson;
      })
      .addCase(resetJobConfigList, (state) => {
        state.jobConfigList = [];
        state.totalCount = 0;
      });
  },
});

export default geoLiftSlice.reducer;
