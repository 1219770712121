import React, { useState } from "react";
import DynamicForm, {
  ExtendedFieldConfig,
} from "../../../components/DynamicForm";
import { z } from "zod";
import axiosInstance from "../../../utils/axiosInstance";

const extendedFormSchema: Record<string, ExtendedFieldConfig> = {
  firstName: {
    validation: z.string(),
    metadata: {
      type: "text",
      label: "First Name",
      group: "row1",
    },
  },
  lastName: {
    validation: z.string(),
    metadata: {
      type: "text",
      label: "Last Name",
      group: "row1",
    },
  },
  userName: {
    validation: z.string(),
    metadata: {
      type: "text",
      label: "Username",
      group: "row2",
    },
  },
  password: {
    validation: z.string(),
    metadata: {
      type: "text",
      label: "Password",
      group: "row2",
    },
  },
  email: {
    validation: z.string().email("Invalid email address"),
    metadata: {
      type: "text",
      label: "Email",
      group: "row3",
    },
  },
  title: {
    validation: z.string(),
    metadata: {
      type: "text",
      label: "Title",
      group: "row3",
    },
  },
  role: {
    validation: z.string(),
    metadata: {
      type: "select",
      label: "Role",
      options: [
        { value: "ADMIN", label: "Admin" },
        { value: "USER", label: "User" },
      ],
      group: "row4",
    },
  },
};

const CreateUserForm = () => {
  const [isLoading, setLoading] = useState(false);
  const [isAlert, setAlert] = useState(false);
  // const router = useRouter();
  const onSubmit = async (data: Record<string, any>) => {
    try {
      setLoading(true);
      const response = await axiosInstance.post("/api/users", data);
      handleAlert();

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  };
  const handleAlert = () => {
    setAlert(true);
    setTimeout(() => {
      setAlert(false);
      // router.push('/users');
    }, 1000);
  };

  return (
    <>
      <DynamicForm
        formName="User Create"
        formSchema={extendedFormSchema}
        onSubmit={onSubmit}
        onSubmitText={"Create User"}
        isLoading={isLoading}
      />
      {isAlert && (
        <div
          role="alert"
          className="alert alert-success absolute bottom-8 right-8 w-auto"
        >
          <span className="ml-2">User created successfully!</span>
        </div>
      )}
    </>
  );
};

export default CreateUserForm;
