import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../utils/axiosInstance";

export const fetchJobConfigMMM = createAsyncThunk(
  "jobMMM/fetchJobConfigMMM",
  async () => {
    const response = await axiosInstance.get("/api/jobConfig/jobType/MMM");
    return response.data.items;
  }
);

export const fetchJobRunMMM = createAsyncThunk(
  "jobMMM/fetchJobRunMMM",
  async () => {
    const response = await axiosInstance.get("/api/jobRun/jobType/MMM");
    return response.data.items;
  }
);

const jobMMMSlice = createSlice({
  name: "jobMMM",
  initialState: {
    jobConfigList: [],
    jobRunList: [],
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchJobConfigMMM.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchJobConfigMMM.fulfilled, (state, action) => {
        state.jobConfigList = action.payload;
        state.loading = false;
      })
      .addCase(fetchJobConfigMMM.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchJobRunMMM.fulfilled, (state, action) => {
        state.jobRunList = action.payload;
      });
  },
});

export default jobMMMSlice.reducer;
