import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import {
  Upload,
  Button,
  message,
  Input,
  Radio,
  RadioChangeEvent,
  Form,
  Select,
  Dropdown,
  Menu,
  Checkbox,
} from "antd";
import type { UploadFile, UploadProps } from "antd/es/upload/interface";
import Papa from "papaparse";
import Maximize from "../../../../components/Maximize";
import LineChart, { LineData } from "../../../../components/LineChart";
import { DownOutlined } from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";
import { JOB_CONFIG_API, S3_UPLOAD_API } from "../../../../constants/API";
import axiosInstance from "../../../../utils/axiosInstance";

interface VerifyTestInputProps {
  preCampaignData: {
    geoLiftStudyName: string;
    buyType: "national" | "non-national";
    fileList: File[];
  };
  isNationalBuy: boolean;
  csvData: any[];
  dateColumn: string;
  locationColumn: string;
  kpiColumn: string;
  onJobCreated: (jobId: string) => void;
  onFormSubmit: (data: any) => void;
  triggerFormSubmit?: boolean;
  jobConfigId: string;
}

// Add color generation functions at the top
const getRandomColor = () => {
  return `#${Math.floor(Math.random() * 16777215)
    .toString(16)
    .padStart(6, "0")}`;
};

const getRandomColorWithOpacity = (opacity = 0.3, baseColor?: string) => {
  const color = baseColor || getRandomColor();
  return (
    color +
    Math.floor(opacity * 255)
      .toString(16)
      .padStart(2, "0")
  );
};

// Define the type for ref
export interface VerifyTestInputRef {
  submitForm: () => void;
}

const VerifyTestInput = forwardRef<VerifyTestInputRef, VerifyTestInputProps>(
  (
    {
      preCampaignData,
      isNationalBuy,
      csvData,
      dateColumn,
      locationColumn,
      kpiColumn,
      onJobCreated,
      onFormSubmit,
      triggerFormSubmit,
      jobConfigId,
    },
    ref
  ) => {
    const [form] = Form.useForm();
    const [dates, setDates] = useState<string[]>([]);
    const [lineData, setLineData] = useState<LineData[]>([]);
    const [allLineData, setAllLineData] = useState<LineData[]>([]);
    const [selectedMarkets, setSelectedMarkets] = useState<Set<string>>(
      new Set()
    );

    useImperativeHandle(ref, () => ({
      submitForm: () => {
        form
          .validateFields()
          .then((values) => {
            console.log("Form Values:", values);
            onFormSubmit(values); // Call parent function with values
          })
          .catch((errorInfo) => {
            console.error("Validation Failed:", errorInfo);
          });
      },
    }));

    const updateChartData = (selectedMarkets: Set<string>) => {
      const filteredData = allLineData.filter((item) =>
        selectedMarkets.has(item.name)
      );
      setLineData([...filteredData]); // Create a new array to ensure state update
    };

    const [searchTerm, setSearchTerm] = useState<string>("");
    const handleSelectAll = () => {
      const allMarkets = new Set(allLineData.map((item) => item.name));
      setSelectedMarkets(allMarkets);
      updateChartData(allMarkets);
    };

    const handleClearAll = () => {
      // When clearing all, set to Average Market only
      const defaultMarket = new Set(["Average of All Markets"]);
      setSelectedMarkets(defaultMarket);
      updateChartData(defaultMarket);
    };
    const handleMarketSelection = (marketName: string) => {
      setSelectedMarkets((prevMarkets) => {
        const newSelectedMarkets = new Set(prevMarkets);
        if (newSelectedMarkets.has(marketName)) {
          newSelectedMarkets.delete(marketName);
        } else {
          newSelectedMarkets.add(marketName);
        }
        // Update chart data immediately after state change
        const filteredData = allLineData.filter((item) =>
          newSelectedMarkets.has(item.name)
        );
        setLineData([...filteredData]);
        return newSelectedMarkets;
      });
    };
    const filteredMarkets = allLineData
      .filter((item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .sort((a, b) => {
        // Always put Average Market at the top
        if (a.name === "Average of All Markets") return -1;
        if (b.name === "Average of All Markets") return 1;
        return a.name.localeCompare(b.name);
      });

    // Modified useEffect for data processing
    useEffect(() => {
      if (csvData && csvData.length > 0) {
        const dataByLocation: Record<string, number[]> = {};
        const dateLabels: string[] = Array.from(
          new Set(csvData.map((d) => d.date))
        ).sort();

        // Organize data by location
        csvData.forEach(({ location, date, value }) => {
          if (!dataByLocation[location]) {
            dataByLocation[location] = new Array(dateLabels.length).fill(0);
          }
          const dateIndex = dateLabels.indexOf(date);
          if (dateIndex !== -1) {
            dataByLocation[location][dateIndex] = value;
          }
        });

        // Create line data with colors
        const formattedData: LineData[] = Object.keys(dataByLocation).map(
          (location) => ({
            name: location,
            data: dataByLocation[location],
            borderColor: getRandomColor(),
            backgroundColor: getRandomColorWithOpacity(0.3),
            borderWidth: 2,
          })
        );

        // Add average line calculation
        const averageData = dateLabels.map((_, index) => {
          const sum = Object.values(dataByLocation).reduce(
            (acc, values) => acc + (values[index] || 0),
            0
          );
          return sum / Object.keys(dataByLocation).length;
        });

        formattedData.unshift({
          name: "Average of All Markets",
          data: averageData,
          borderColor: "#ff0000",
          backgroundColor: getRandomColorWithOpacity(0.3, "#ff0000"),
        });

        // Update state
        setDates(dateLabels);
        setAllLineData(formattedData);
        setLineData([formattedData[0]]);
        setSelectedMarkets(new Set(["Average of All Markets"]));
      }
    }, [csvData, dateColumn, locationColumn, kpiColumn]);

    // Add state for showing/hiding fields
    const [showAdvancedFields, setShowAdvancedFields] = useState(false);

    // Add state for dropdown visibility
    const [dropdownVisible, setDropdownVisible] = useState(false);

    //  Now you can access preCampaignData properties
    // useEffect(() => {
    //   if (triggerFormSubmit) {
    //     form.submit(); // Trigger submit when requested by parent
    //   }
    // }, [triggerFormSubmit]);

    // Use in your component logic as needed
    const studyType =
      preCampaignData.buyType === "national" ? "National Buy" : "Localized Buy";

    const handleSubmit = async (values: any) => {
      console.log("i am in handleSubmit");
      onFormSubmit(values);
    };
    useEffect(() => {
      if (showAdvancedFields) {
        form.setFieldsValue({
          numberOfTestMarket:
            form.getFieldValue("numberOfTestMarket") || "2,3,4,5",
          treatmentPeriod: form.getFieldValue("treatmentPeriod") || "5,10,15",
          budget: form.getFieldValue("budget") || "1000000",
          confidence: form.getFieldValue("confidence") || 90,
          cpic: form.getFieldValue("cpic") || "7.5",
          excludeMarkets: form.getFieldValue("excludeMarkets") || [],
        });
      }
    }, [showAdvancedFields, form]);

    return (
      <>
        <div title="Geo Market Trend">
          <div className="min-h-[800px] flex flex-col">
            {/* Chart Section */}
            {dates.length > 0 && (
              <>
                <div className="flex justify-end mb-4">
                  <Dropdown
                    visible={dropdownVisible}
                    onVisibleChange={(visible) => setDropdownVisible(visible)}
                    overlay={
                      <Menu className="p-2 w-64">
                        <div className="flex justify-between gap-2 mb-2">
                          <Button
                            type="link"
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleSelectAll();
                            }}
                          >
                            Select All
                          </Button>
                          <Button
                            type="link"
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleClearAll();
                            }}
                          >
                            Clear All
                          </Button>
                        </div>

                        <Input.Search
                          placeholder="Search markets..."
                          value={searchTerm}
                          onChange={(e) => {
                            e.stopPropagation();
                            setSearchTerm(e.target.value);
                          }}
                          className="mb-2"
                          onClick={(e) => e.stopPropagation()}
                        />

                        <div className="max-h-48 overflow-auto">
                          <Checkbox.Group
                            value={Array.from(selectedMarkets)}
                            onChange={(checkedValues) => {
                              const newSelected = new Set(
                                checkedValues as string[]
                              );
                              setSelectedMarkets(newSelected);
                              updateChartData(newSelected);
                            }}
                            className="w-full"
                          >
                            <Menu.ItemGroup className="!tw-block">
                              {filteredMarkets.map((item) => (
                                <Menu.Item
                                  key={item.name}
                                  className="!tw-p-0 hover:!tw-bg-transparent"
                                  onClick={({ domEvent }) =>
                                    domEvent.stopPropagation()
                                  }
                                >
                                  <Checkbox
                                    value={item.name}
                                    className="!tw-w-full hover:!tw-bg-gray-50 !tw-px-4 !tw-py-2"
                                  >
                                    {item.name}
                                  </Checkbox>
                                </Menu.Item>
                              ))}
                            </Menu.ItemGroup>
                          </Checkbox.Group>

                          {filteredMarkets.length === 0 && (
                            <div className="text-gray-500 p-2 text-center">
                              No markets found
                            </div>
                          )}
                        </div>
                      </Menu>
                    }
                    trigger={["click"]}
                  >
                    <Button
                      className="flex items-center"
                      onClick={() => setDropdownVisible(!dropdownVisible)}
                    >
                      Select Markets ({selectedMarkets.size}/
                      {allLineData.length})
                      <DownOutlined className="ml-2" />
                    </Button>
                  </Dropdown>
                </div>

                <div className="flex-1 relative">
                  {lineData.length > 0 ? (
                    <LineChart
                      key={lineData.map((d) => d.name).join(",")}
                      labels={dates}
                      dataSets={lineData}
                      xAxisTitle="Date"
                      yAxisTitle="Value"
                      height="700px"
                      showSymbols={false}
                    />
                  ) : (
                    <div className="absolute inset-0 flex items-center justify-center text-gray-500">
                      Please select at least one market to view the chart
                    </div>
                  )}
                </div>
              </>
            )}

            <Form
              form={form}
              layout="vertical"
              initialValues={{
                numberOfTestMarket: "2,3,4,5",
                treatmentPeriod: "5,10,15",
                budget: "1000000",
                confidence: 90,
                cpic: "7.5",
              }}
              onFinish={handleSubmit}
            >
              <div className="mt-8">
                {/* Include Markets (Conditional) */}
                {!isNationalBuy && (
                  <Form.Item
                    label="Select the Markets Where You Want to Execute the Test"
                    name="includeMarkets"
                    rules={[
                      {
                        required: true,
                        message: "Please select at least one market to include",
                      },
                    ]}
                    className="mb-6"
                  >
                    <Select
                      mode="multiple"
                      placeholder="Select markets to include"
                      options={allLineData
                        .filter(
                          (item) => item.name !== "Average of All Markets"
                        )
                        .map((market) => ({
                          value: market.name,
                          label: market.name,
                        }))}
                    />
                  </Form.Item>
                )}

                {/* Advanced Fields Toggle */}
                <div className="mb-4 flex justify-end">
                  <Button
                    type="link"
                    onClick={() => setShowAdvancedFields(!showAdvancedFields)}
                    className="text-blue-600"
                  >
                    {showAdvancedFields
                      ? "Hide Advanced Fields"
                      : "Show Advanced Fields"}
                  </Button>
                </div>

                {/* Advanced Fields */}
                {showAdvancedFields && (
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-8">
                    <Form.Item
                      label="Control Group Sizes"
                      name="numberOfTestMarket"
                      rules={[
                        { required: true },
                        {
                          pattern: /^\d+(,\d+)*$/,
                          message: "Please enter comma-separated numbers",
                        },
                      ]}
                    >
                      <Input placeholder="e.g., 100,200,300" />
                    </Form.Item>

                    <Form.Item
                      label="Validation Period (days)"
                      name="treatmentPeriod"
                      rules={[
                        { required: true },
                        {
                          pattern: /^\d+(,\d+)*$/,
                          message: "Please enter comma-separated numbers",
                        },
                      ]}
                    >
                      <Input placeholder="e.g., 7,14,30" />
                    </Form.Item>

                    <Form.Item
                      label="Campaign Budget ($)"
                      name="budget"
                      rules={[{ required: true, type: "number", min: 0 }]}
                    >
                      <Input type="number" min={0} />
                    </Form.Item>

                    <Form.Item
                      label="Confidence Level (%)"
                      name="confidence"
                      rules={[
                        { required: true, type: "number", min: 0, max: 100 },
                      ]}
                    >
                      <Input type="number" min={0} max={100} />
                    </Form.Item>

                    <Form.Item
                      label="Cost Per Incremental Conversion ($)"
                      name="cpic"
                      rules={[{ required: true, type: "number", min: 0 }]}
                    >
                      <Input type="number" min={0} step="0.01" />
                    </Form.Item>

                    <Form.Item label="Exclude Markets" name="excludeMarkets">
                      <Select
                        mode="multiple"
                        placeholder="Select markets to exclude"
                        options={allLineData
                          .filter(
                            (item) => item.name !== "Average of All Markets"
                          )
                          .map((market) => ({
                            value: market.name,
                            label: market.name,
                          }))}
                      />
                    </Form.Item>
                  </div>
                )}
                {!showAdvancedFields && (
                  <>
                    <Form.Item name="numberOfTestMarket" hidden>
                      <Input />
                    </Form.Item>
                    <Form.Item name="treatmentPeriod" hidden>
                      <Input />
                    </Form.Item>
                    <Form.Item name="budget" hidden>
                      <Input type="number" />
                    </Form.Item>
                    <Form.Item name="confidence" hidden>
                      <Input type="number" />
                    </Form.Item>
                    <Form.Item name="cpic" hidden>
                      <Input type="number" />
                    </Form.Item>
                    <Form.Item name="excludeMarkets" hidden>
                      <Input />
                    </Form.Item>
                  </>
                )}
              </div>
            </Form>
          </div>
        </div>
      </>
    );
  }
);

export default VerifyTestInput;
