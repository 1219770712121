import { Table, Button, Select, UploadFile, Spin, Alert, Modal } from "antd";
import React, { useState, useEffect } from "react";
import { getTenantId } from "../../../../utils/auth";
import axiosInstance from "../../../../utils/axiosInstance";
import LineChart, { LineData } from "../../../../components/LineChart";
import {
  GEO_LIFT_AGGREGATE_LIFT_API,
  GEO_LIFT_MARKET_SELECTION,
  GEO_LIFT_POST_TEST_API,
  GEO_LIFT_POWER_PLOT_API,
  SEASONALITY_API,
} from "../../../../constants/API";

interface MarketData {
  Holdout: number;
  ID: number;
  location: string;
  duration: number;
  EffectSize: number;
  Power: number;
  AvgScaledL2Imbalance: number;
  Investment: number;
  AvgATT: number;
  Average_MDE: number;
  ProportionTotal_Y: number;
  rank: number;
  abs_lift_in_perc: number;
  correlation: number;
}

interface ViewTestAndControlMarketProps {
  testMarkets: string[];
  selectedMarket: string;
  marketData: MarketData[];
  jobConfigId: string;
  allMarketData: Record<string, MarketData[]>;
  preCampaignData: {
    geoLiftStudyName: string;
    buyType: "national" | "non-national";
    fileList: File[];
  };
  onMarketChange: (value: string) => void;
  onTestMarketsChange?: (markets: string[]) => void;
}

const ViewTestAndControlMarket: React.FC<ViewTestAndControlMarketProps> = ({
  testMarkets,
  selectedMarket,
  marketData,
  allMarketData,
  preCampaignData,
  jobConfigId,
  onMarketChange,
  onTestMarketsChange,
}) => {


  const dataSource = [
    {
      key: "1",
      metric: "Control Markets",
      rank: marketData[0]?.location
        ?.replace(/[()'-]/g, '') // Remove '(', ')', "'", and '-'
        .replace(/multioutletc/gi, '') // Remove "multioutletc" case-insensitively
        .trim()
        .split(',')
        .map(location => location.trim().replace(/\b\w/g, char => char.toUpperCase()))
        .join(', ') || "-",
      rank2: marketData[1]?.location
        ?.replace(/[()'-]/g, '')
        .replace(/multioutletc/gi, '')
        .trim()
        .split(',')
        .map(location => location.trim().replace(/\b\w/g, char => char.toUpperCase()))
        .join(', ') || "-",
      rank3: marketData[2]?.location
        ?.replace(/[()'-]/g, '')
        .replace(/multioutletc/gi, '')
        .trim()
        .split(',')
        .map(location => location.trim().replace(/\b\w/g, char => char.toUpperCase()))
        .join(', ') || "-",
    },
    {
      key: "2",
      metric: "Investment",
      rank: marketData[0]
        ? `$${Number(marketData[0].Investment).toLocaleString(undefined, {
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
        })}`
        : "-",
      rank2: marketData[1]
      ? `$${Number(marketData[1].Investment).toLocaleString(undefined, {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      })}`
      : "-",
      rank3: marketData[2]
      ? `$${Number(marketData[2].Investment).toLocaleString(undefined, {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      })}`
      : "-",
    },
    {
      key: "3",
      metric: "Duration",
      rank: marketData[0]?.duration || "-",
      rank2: marketData[1]?.duration || "-",
      rank3: marketData[2]?.duration || "-",
    },
    {
      key: "4",
      metric: "Predicted Lift (%)",
      rank: marketData[0]
      ? `${Number(marketData[0].abs_lift_in_perc).toFixed(1)}%`
      : "-",
      rank2: marketData[1] ? `${Number(marketData[1].abs_lift_in_perc).toFixed(1)}%`
      : "-",
      rank3: marketData[2] ?  `${Number(marketData[2].abs_lift_in_perc).toFixed(1)}%`
      : "-",
    },
    {
      key: "5",
      metric: "Predicted Lift ($)",
      rank: marketData[0]
      ? `$${Number(marketData[0].AvgATT).toFixed(2)}`
      : "-",
      rank2: marketData[1] ? `$${Number(marketData[1].AvgATT).toFixed(2)}`
      : "-",
      rank3: marketData[2] ? `$${Number(marketData[2].AvgATT).toFixed(2)}`
      : "-",
    },
    {
      key: "6",
      metric: "Power",
      rank: marketData[0] ? `${(marketData[0].Power * 100).toFixed(1)}%` : "-",
      rank2: marketData[1] ? `${(marketData[1].Power * 100).toFixed(1)}%` : "-",
      rank3: marketData[2] ? `${(marketData[2].Power * 100).toFixed(1)}%` : "-",
    },
  ];

  const columns = [
    {
      title: "Rank Based on Performance",
      dataIndex: "metric",
      key: "metric",
    },
    {
      title: "Rank 1",
      dataIndex: "rank",
      key: "rank",
    },
    {
      title: "Rank 2",
      dataIndex: "rank2",
      key: "rank2",
    },
    {
      title: "Rank 3",
      dataIndex: "rank3",
      key: "rank3",
    },
  ];
  const [loading, setLoading] = useState(true);

  // generate control market
  const [loadingMarketId, setLoadingMarketId] = useState<string | null>(null);
  const [selectedLocations, setSelectedLocations] = useState<Set<string>>(
    new Set()
  );
  const [selectedLocation, setSelectedLocation] = useState<string>("");
  const [powerPlotData, setPowerPlotData] = useState<any>();
  const [geoLiftObsLabel, setGeoLiftObsLabel] = useState<string[] | undefined>();
  const [plotDuration, setPlotDuration] = useState<number | undefined>();
  const [treatmentValue, setTreatmentValue] = useState<{ name: string; data: number[] }[] | undefined>();
  const [chartKey, setChartKey] = useState<number>(0);
  const [powerWellPlotData, setPowerWellPlotData] = useState<any>();
  const [liftPercentage, setLiftPercentage] = useState<number | null>(null);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedChartData, setSelectedChartData] = useState<any>(null);
  const [selectedTitle, setSelectedTitle] = useState<string>("");
  const [buttonLoading, setButtonLoading] = useState(false);

const handleButtonClick = async () => {
  setButtonLoading(true); // Start loading when button is clicked

  try {
    await handleViewControlMarket(marketData[0], jobConfigId);
  } finally {
    setLoading(false); // Stop loading
  }

  setButtonLoading(false); // Stop loading when modal is open
};

  useEffect(() => {
    if (isModalVisible) {
      setTimeout(() => setChartKey(Date.now()), 300);
    }
  }, [isModalVisible]);

  useEffect(() => {
    if (dataSource[0].rank !== "-") {
      setLoading(false);
    }
  }, [dataSource]);

  useEffect(() => {
    // Update selectedLocation whenever selectedMarket changes
    setSelectedLocation(selectedMarket);
  }, [selectedMarket]);

  const handleViewControlMarket = async (record: any, jobConfigId: string) => {
    setLoadingMarketId(record.ID);
    const marketSelectionPayload = {
      marketSelectionId: record.ID,
      marketName: selectedLocation,
      jobConfigId: jobConfigId,
      jobConfig: jobConfigId,
      tenantId: getTenantId(),
    };
    axiosInstance
      .post(GEO_LIFT_MARKET_SELECTION, marketSelectionPayload)
      .then((response) => {
        let responseData = response.data;

        // Check if the response data is a string and parse it as JSON if necessary
        if (typeof responseData === "string") {
          try {
            responseData = JSON.parse(responseData);
          } catch (error) {
            console.error("Failed to parse response data as JSON:", error);
          }
        }

        if (responseData) {
          const geoLiftPlot = responseData.lift_plot;
          const powerPlot = responseData.power_plot;
          const liftPercentage = responseData.lift_percentage;

          // Update power plot data with the current selected location
          const updatedPowerPlot = {
            ...powerPlot,
            location: selectedLocation, // Update location to match selected market
          };
          setPowerPlotData(updatedPowerPlot);

          const labels = Object.values(geoLiftPlot.Time).map((time: any) =>
            time.toString()
          );
          setGeoLiftObsLabel(labels);
          const c_obsData = Object.values(geoLiftPlot.c_obs);
          const t_obsData = Object.values(geoLiftPlot.t_obs);

          setPlotDuration((t_obsData.length - record?.duration) as any);

          const dataSets = [
            {
              lineType: "dashed",
              name: "Synthetic Control", // Name for legend
              data: c_obsData, // y-axis values
              borderColor: "#d6279a", // Line color (blue for example)
              backgroundColor: "rgba(59, 130, 246, 0.0)", // Background fill color (light blue)
            },
            {
              name: `Test Location (${selectedLocation})`, // Name for legend with selected location
              data: t_obsData, // y-axis values
              borderColor: "#006400", // Line color (blue for example)
              backgroundColor: "rgba(59, 2, 246, 0.0)", // Background fill color (light blue)
            },
          ];

          setTreatmentValue(dataSets as any);

          setLiftPercentage(liftPercentage);
          setChartKey(prev => prev + 1);

          setLoading(false);
          setLoadingMarketId(null);
          setSelectedTitle(`Test vs Synthetic Control Graph (Lift: ${liftPercentage?.toFixed(2)}%)`);
          setIsModalVisible(true);

        }
      })
      .catch((e) => {
        setLoadingMarketId(null);
        setLoading(false);
      });

    axiosInstance
      .post(GEO_LIFT_POWER_PLOT_API, marketSelectionPayload)
      .then((response) => {
        let responseData = response.data;

        // Check if the response data is a string and parse it as JSON if necessary
        if (typeof responseData === "string") {
          try {
            responseData = JSON.parse(responseData);
          } catch (error) {
            console.error("Failed to parse response data as JSON:", error);
          }
        }

        if (responseData) {
          // Update power well plot data with the current selected location
          const updatedPowerWellPlot = {
            ...responseData,
            PowerCurves: responseData.PowerCurves?.map((curve: any) => ({
              ...curve,
              location: selectedLocation, // Update location to match selected market
            })),
          };
          setPowerWellPlotData(updatedPowerWellPlot);

          setLoading(false);
        }
      })
      .catch((e) => {
        setLoadingMarketId(null);
        setLoading(false);
      });
  };
  const handleModalClose = () => {
    setIsModalVisible(false);
  };


  return (
    <div className="min-h-screen flex items-center justify-center">
      {/* Show Spinner and Message until Data is Loaded */}
      {loading ? (
        <div className="text-center">
          <Spin size="large" />
          <Alert
            message="AI is at work!"
            description="Please allow 10 to 15 minutes for the magic to unfold."
            type="info"
            showIcon
            className="mt-4"
          />
        </div>
      ) : (
        <div className="">
          <div className="mb-4">
            <Select
              value={selectedMarket}
              onChange={onMarketChange}
              style={{ width: "100%" }}
              placeholder="Select a market"
              options={testMarkets.map((market) => ({
                value: market,
                label: market,
              }))}
            />
          </div>
          <Table
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            bordered
            summary={() => (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>
                    Actions
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1} align="center">
                    <Button
                      type="primary"
                      onClick={handleButtonClick}
                      loading={loading}
                    >
                      View Control Market
                    </Button>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2} align="center">
                    <Button
                      type="primary"
                      onClick={() =>
                        handleViewControlMarket(marketData[1], jobConfigId)
                      }
                    >
                      View Control Market
                    </Button>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3} align="center">
                    <Button
                      type="primary"
                      onClick={() =>
                        handleViewControlMarket(dataSource[2], jobConfigId)
                      }
                    >
                      View Control Market
                    </Button>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            )}
          />
          {/* Modal to Show Graph */}
          <Modal
            title={selectedTitle || "Loading..."}
            open={isModalVisible}
            onCancel={handleModalClose}
            footer={null}
            width={800}
          >
            <div style={{ width: "100%", height: "600px", display: "flex", justifyContent: "center", alignItems: "center" }}>
              {selectedTitle && geoLiftObsLabel?.length && treatmentValue?.length ? (
                <LineChart
                  key={`control-treatment-${chartKey}`}
                  labels={geoLiftObsLabel as any}
                  dataSets={treatmentValue as any}
                  xAxisTitle="Time"
                  yAxisTitle="Test Location"
                  highlightAreaFrom={plotDuration}
                  verticalLineAt={plotDuration}
                />
              ) : (
                <Spin size="large" />
              )}
            </div>
          </Modal>

        </div>
      )
      }
    </div>
  )
};

export default ViewTestAndControlMarket;
