import React from "react";
import {
  CalendarIcon,
  TrendingUpIcon,
  DollarSign,
  PlayCircle,
} from "lucide-react";
import DashboardCharts from "./DashboardCharts";
import axiosInstance from "../utils/axiosInstance";
import mixpanel from "mixpanel-browser";
import { Events } from "../constants/Event";
import { useNavigate } from "react-router-dom";

interface TestItem {
  name: string;
  date: string;
  status: "in_progress" | "error" | "completed";
  showControls?: boolean;
  mediaVariables?: string[];
  jobConfigId: string;
}

interface SalesData {
  withoutCampaign: number;
  withCampaign: number;
}

interface SpendData {
  spend: number;
  sales: number;
}

interface DashboardChartsProps {
  salesData: SalesData;
  spendData: SpendData;
  showLegend?: boolean;
  height?: number;
  chartType?: "sales" | "metrics";
  mmmTests?: TestItem[];
}

const MMMDashboardCard: React.FC<DashboardChartsProps> = ({
  salesData,
  spendData,
  showLegend = true,
  height = 300,
  chartType = "sales",
  mmmTests = [],
}) => {
  const navigate = useNavigate();
  const handleExecute = async (jobConfigId: string) => {
    await axiosInstance.post(
      "/api/jobRun",
      {
        jobConfigId,
      },
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("authToken"),
        },
      }
    );
    mixpanel.track(Events.BUTTON_CLICK, {
      jobType: "GEO_LIFT",
      action: "Execute",
      jobConfigId,
    });
    window.location.reload();
  };
  console.log(mmmTests);
  return (
    <div className="bg-gray-100 rounded-xl shadow-md p-6">
      <div className="grid grid-cols-12 gap-8 ">
        {/* Left Section - Marketing Mix Model */}
        <div className="col-span-4 bg-white p-6 rounded-lg">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-semibold text-gray-700">
              Marketing Mix Model
            </h2>
            <button
              onClick={() => navigate("/planning/marketing-mix-model")}
              className="text-blue-500 text-sm hover:text-blue-600"
            >
              View All
            </button>
          </div>

          <p className="text-sm text-gray-600 mb-4">
            Cross-Channel Budget Allocation
          </p>
          <div className="space-y-4">
            {mmmTests.map((test, index) => (
              <div
                key={index}
                className="flex items-center justify-between border-b pb-3"
              >
                <div className="flex-1">
                  <p className="text-sm font-medium text-gray-800">
                    {test.name}
                  </p>
                  <div className="flex items-center text-xs text-gray-400 mt-1">
                    <CalendarIcon className="w-3 h-3 mr-1 flex-shrink-0" />
                    <span>{test.date}</span>
                  </div>
                </div>
                <div className="flex flex-col gap-2 ml-4">
                  {test.status === "completed" ? (
                    <button className="bg-blue-500 text-xs px-3 py-1 rounded text-white whitespace-nowrap">
                      VIEW RESULTS
                    </button>
                  ) : test.status === "in_progress" ? (
                    <span className="text-orange-500 bg-gray-100 px-2 py-0.5 rounded-full text-xs whitespace-nowrap">
                      In progress
                    </span>
                  ) : test.status === "error" ? (
                    <span className="text-red-500 bg-gray-100 px-2 py-0.5 rounded-full text-xs whitespace-nowrap">
                      In error
                    </span>
                  ) : (
                    <button
                      onClick={() => handleExecute(test.jobConfigId)}
                      className="flex items-center gap-1 bg-green-500 text-xs px-3 py-1 rounded text-white whitespace-nowrap hover:bg-green-600"
                    >
                      <PlayCircle className="w-3 h-3" />
                      EXECUTE
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
          <button
            onClick={() => navigate("/jobs/MMM")}
            className="w-full mt-6 bg-blue-500 text-white py-2 rounded text-sm font-medium hover:bg-blue-600"
          >
            CREATE NEW TEST
          </button>
        </div>

        {/* Middle Section - Latest Sales Lift */}
        {/* <div className="col-span-3 flex flex-col items-center justify-space-between bg-white py-4">
          <h3 className="text-2xl font-semibold text-gray-800 mb-1">
            Latest Sales Lift
          </h3>
          <p className="text-xs text-gray-500 text-center">
            Average Across Test Markets
          </p>
          <p className="text-xs text-gray-500 text-center">
            (Confidence {">"}60%)
          </p>

          <div className="mt-8 flex items-center gap-2">
            <div className="bg-purple-50 rounded-full p-1">
              <TrendingUpIcon className="w-5 h-5 text-purple-500" />
            </div>
            <span className="text-6xl font-bold text-green-500">+4.1%</span>
          </div>

          <p className="text-gray-700 mt-4">Geo lift for Adrsta</p>
          <div className="flex items-center text-xs text-gray-400 mt-1">
            <CalendarIcon className="w-3 h-3 mr-1 flex-shrink-0" />
            <span>{dummyTests[4].date}</span>
          </div>
        </div> */}

        {/* Right Section - ROAS */}
        {/* <div className="col-span-5 bg-white p-6 rounded-lg">
          <h3 className="text-2xl font-semibold text-gray-800 mb-6">ROAS</h3>
          <DashboardCharts
            salesData={{
              withCampaign: salesData?.withCampaign || 0,
              withoutCampaign: salesData?.withoutCampaign || 0,
            }}
            spendData={{
              spend: 0,
              sales: 0,
            }}
            height={300}
            showLegend={false}
            chartType="sales"
          />
        </div> */}
      </div>
    </div>
  );
};

export default MMMDashboardCard;
