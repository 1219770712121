"use client";

import React from "react";
import DynamicForm, {
  ExtendedFieldConfig,
} from "../../../components/DynamicForm";
import { z } from "zod";
import axiosInstance from "../../../utils/axiosInstance";

const extendedFormSchema: Record<string, ExtendedFieldConfig> = {
  name: {
    validation: z.string(),
    metadata: {
      type: "text",
      label: "name",
      group: "row1",
    },
  },
  displayName: {
    validation: z.string(),
    metadata: {
      type: "text",
      label: "Display Name",
      group: "row2",
    },
  },
  email: {
    validation: z.string().email("Invalid email address"),
    metadata: {
      type: "text",
      label: "Email",
      group: "row4",
    },
  },
};

const CreateTenantForm = () => {
  const onSubmit = async (data: Record<string, any>) => {
    try {
      const response = await axiosInstance.post("/api/tenants", data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <DynamicForm
      formName="Create Tenant Form"
      formSchema={extendedFormSchema}
      onSubmit={onSubmit}
      onSubmitText={"Create Tenant"}
      isLoading={false}
    />
  );
};

export default CreateTenantForm;
