import React, { useState, useEffect } from "react";
import ReactECharts from "echarts-for-react";

interface HeatmapProps {
  data: {
    xLabels: string[];
    yLabels: string[];
    zData: number[][]; 
  };
}

// Helper function to capitalize labels and remove underscores
const formatLabel = (label: string) => {
  return label
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

const HeatMap: React.FC<HeatmapProps> = ({ data }) => {
  const { xLabels, yLabels, zData } = data;

  // Responsive chart dimensions
  const [chartSize, setChartSize] = useState({
    width: "100%",
    height: "800px",
  });

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;

      setChartSize({
        width: width > 1200 ? "100%" : "90%",
        height: height > 800 ? "800px" : `${height * 0.8}px`,
      });
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial resize
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Transform zData into the format required by ECharts
  const formattedData = zData.flatMap((row, rowIndex) =>
    row.map((value, colIndex) => [colIndex, rowIndex, value])
  );

  const option = {
    tooltip: {
      position: "top",
      formatter: (params: { value: [number, number, number] }) => {
        const value = params.value[2];
        return `${value.toFixed(2)}`;
      },
    },
    grid: {
      left: "10%",
      right: "10%",
      bottom: "15%",
      top: "10%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      data: xLabels.map(formatLabel),
      splitArea: {
        show: true,
      },
      axisLabel: {
        rotate: window.innerWidth < 768 ? 30 : 45, // Adjust rotation for smaller screens
        interval: 0,
        fontSize: window.innerWidth < 768 ? 12 : 15, // Smaller font size on mobile
      },
    },
    yAxis: {
      type: "category",
      data: yLabels.map(formatLabel),
      splitArea: {
        show: true,
      },
      axisLabel: {
        fontSize: window.innerWidth < 768 ? 12 : 15,
      },
    },
    visualMap: {
      min: -1,
      max: 1,
      calculable: true,
      orient: window.innerWidth < 768 ? "vertical" : "horizontal",
      left: "center",
      bottom: "5%",
      inRange: {
        color: [
          "rgba(255, 107, 107, 0.9)",
          "rgba(255, 255, 255, 0.9)",
          "rgba(20, 57, 105, 0.9)",
        ],
      },
      textStyle: {
        color: "#143969",
        fontSize: 14,
      },
    },
    series: [
      {
        name: "Heatmap",
        type: "heatmap",
        data: formattedData,
        label: {
          show: true,
          formatter: function (params: any) {
            const value = params.value[2];
            return value.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
          },
          fontSize: window.innerWidth < 768 ? 10 : 12, // Adjust label size
          fontWeight: "bold",
          color: "#000000",
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
        itemStyle: {
          borderWidth: 1,
          borderColor: "#fff",
        },
      },
    ],
  };

  return (
    <ReactECharts
      option={option}
      style={{ width: chartSize.width, height: chartSize.height }}
    />
  );
};

export default HeatMap;
