import React, { useState, ChangeEvent, useEffect, useRef } from "react";
import PostTestControlMarketSelection from "./PostTestControlMarketSection";
import ViewTestAndControlMarket from "./ViewTestAndControlMarket";
import { Steps, Input, Radio, Upload, Button, Select } from "antd";
import { CloseOutlined, UploadOutlined } from "@ant-design/icons";
import type { RadioChangeEvent } from "antd";
import type { UploadProps, UploadFile } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import LineChart, { LineData } from "../../../../components/LineChart";
import Papa from "papaparse";
import {
  getRandomColor,
  getRandomColorWithOpacity,
} from "../../../../utils/utils";
import Maximize from "../../../../components/LineChart";
import PreCampaign from "./PreCampaign";
import VerifyTestInput from "./VerifyTestInput";
import ViewResults from "./ViewResults";
import axiosInstance from "../../../../utils/axiosInstance";
import { useParams } from "react-router-dom";
import { GeoLiftJobConfigService } from "../../../../services/jobConfigServiceFactory/GeoLiftJobConfigService";
import { getTenantId } from "../../../../utils/auth";
import { v4 as uuidv4 } from "uuid";
import { JobType } from "../../../../constants/JobTypes";

dayjs.extend(customParseFormat);

interface MarketData {
  Holdout: number;
  ID: number;
  location: string;
  duration: number;
  EffectSize: number;
  Power: number;
  AvgScaledL2Imbalance: number;
  Investment: number;
  AvgATT: number;
  Average_MDE: number;
  ProportionTotal_Y: number;
  rank: number;
  abs_lift_in_perc: number;
  correlation: number;
}
interface CSVRow {
  date: string;
  Y: number;
  location: string;
}

const GeoLiftStudy: React.FC = () => {
  const geoLiftJobConfigService = new GeoLiftJobConfigService();
  const { id } = useParams<{ id: string }>();
  const [current, setCurrent] = useState(0);
  const [selectedLocations, setSelectedLocations] = useState<Set<string>>(
    new Set()
  );
  const [isJobConfigPresent, setIsJobConfigPresent] = useState(false);
  const verifyTestInputRef = useRef<{ submitForm: () => void } | null>(null); // Ref for form submission
  const [testMarkets, setTestMarkets] = useState<string[]>([]);
  const [jobId, setJobId] = useState<string>(id || "");
  const [selectedMarket, setSelectedMarket] = useState<string>("");
  const [marketData, setMarketData] = useState<MarketData[]>([]);
  const [postTestData, setPostTestData] = useState<any>(null);
  const [allMarketData, setAllMarketData] = useState<
    Record<string, MarketData[]>
  >({});

  // Add state for post campaign data form
  const [postCampaignData, setPostCampaignData] = useState({
    locationVariable: "",
    kpiVariable: "",
    dateVariable: "",
    dateFormat: "MM/DD/YYYY",
  });

  // Chart related states
  const [lineData, setLineData] = useState<LineData[]>([]);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [allLineData, setAllLineData] = useState<LineData[]>([]);
  const [dates, setDates] = useState<string[]>([]);
  const [jobConfig, setJobConfig] = useState<any>({});
  const preCampaignRef = useRef<any>(null);
  const [selectedMarkets, setSelectedMarkets] = useState<Set<string>>(
    new Set()
  );
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [dataByLocation, setDataByLocation] = useState<{
    [location: string]: { date: string; value: number }[];
  }>({});

  const [fileData, setFileData] = useState<string | undefined>(undefined);

  const [columnOptions, setColumnOptions] = useState<{
    locationOptions: { label: string; value: string }[];
    kpiOptions: { label: string; value: string }[];
    dateOptions: { label: string; value: string }[];
  }>({
    locationOptions: [],
    kpiOptions: [],
    dateOptions: [],
  });
  const getNextButtonLabel = () =>
    current === 1 ? "Create Control & Test Market" : "Next";

  const [csvData, setCsvData] = useState<any[]>([]);
  const [alertMessage, setAlertMessage] = useState<string | undefined>(
    undefined
  );

  // Add new state for column mappings
  const [columnMappings, setColumnMappings] = useState<{
    dateColumn: string;
    locationColumn: string;
    kpiColumn: string;
  }>({
    dateColumn: "date",
    locationColumn: "location",
    kpiColumn: "y",
  });

  const isNextDisabled = () => {
    switch (current) {
      case 0:
        return !fileUploaded; // Step 1: Disable if no file uploaded
      case 1:
        return !csvData.length || !columnMappings.dateColumn || !columnMappings.locationColumn || !columnMappings.kpiColumn; // Step 2: Disable if columns are not mapped
      case 2:
        return testMarkets.length === 0 || !selectedMarket; // Step 3: Disable if no test markets selected
      case 3:
        return false; // Step 4: No specific condition
      default:
        return false; // Enable Next by default for all other cases
    }
  };

  // Add new state for preserving PreCampaign data
  const [preCampaignData, setPreCampaignData] = useState<{
    geoLiftStudyName: string;
    buyType: "national" | "non-national";
    fileList: File[];
  }>({
    geoLiftStudyName: "",
    buyType: "non-national",
    fileList: [],
  });

  const prevDataRef = useRef<string>(JSON.stringify(preCampaignData));

  useEffect(() => {
    if (id) {
      setJobId(id);
      getMarketDetails(id);
      getJobConfig(id);
    } else if (jobId) {
      getMarketDetails(jobId);
    }
  }, [id, jobId]);

  // Handle the Verifytest input step form submisssion
  const handleFormSubmit = async (formData: Record<string, any>) => {
    console.log("Form submitted in Parent:", formData);

    if (formData.confidence) {
      formData.confidence = (parseFloat(formData.confidence) / 100).toString();
    }

    // Get the total number of records for a location
    const locationVariable = preCampaignData.buyType;
    const firstLocation = Object.keys(dataByLocation)[0];
    const totalRecords = dataByLocation[firstLocation]?.length || 0;

    // Convert validation period percentages to actual numbers
    // if (formData.treatmentPeriod) {
    //   const percentages = formData.treatmentPeriod
    //     .split(",")
    //     .map((p: string) => p.trim());
    //   const actualPeriods = percentages.map((p: string) => {
    //     const percent = parseInt(p);
    //     return Math.round((percent / 100) * totalRecords);
    //   });
    //   formData.treatmentPeriod = actualPeriods.join(",");
    // }

    // Process includeMarket and excludeMarket
    if (formData.includeMarket) {
      formData.includeMarket = formData.includeMarket
        .map((market: any) => market.value)
        .join(";;;");
    }
    if (formData.excludeMarkets) {
      formData.excludeMarkets = formData.excludeMarkets
        .map((market: any) => market.value)
        .join(";;;");
    }

    try {
      const locationVariable = "location";
      const kpiVariable = "Y";
      const dateVariable = "date";

      const jobId = id == undefined ? uuidv4() : id;
      if (id == undefined) {
        const geoLiftFormData = new FormData();
        geoLiftFormData.append("file", preCampaignData.fileList[0]);
        const geoLiftFilePath = jobId + "/input/geo_lift_data.csv";
        geoLiftFormData.append("path", geoLiftFilePath);
        await axiosInstance.post("/api/s3/upload", geoLiftFormData);
      }

      // Parse CSV data to calculate durationType
      const csvData = await new Promise<any[]>((resolve) => {
        Papa.parse(preCampaignData.fileList[0], {
          complete: (results) => resolve(results.data),
          header: true,
        });
      });

      // Get unique locations
      const locations = csvData
        .map((row) => row[locationVariable])
        .filter((value, index, self) => self.indexOf(value) === index);

      // Take the first location's data
      const firstLocation = locations[0];
      const locationData = csvData.filter(
        (row) => row[locationVariable] === firstLocation
      );

      // Get dates for the selected location and parse them
      const dates = locationData.map((row) => {
        const dateStr = row[dateVariable];
        const format = "YYYY-MM-DD";
        // Convert the date format string from Python to JavaScript
        const jsFormat = format
          .replace(/%Y/g, "YYYY")
          .replace(/%y/g, "YY")
          .replace(/%m/g, "MM")
          .replace(/%d/g, "DD")
          .replace(/%H/g, "HH")
          .replace(/%M/g, "mm")
          .replace(/%S/g, "ss");
        return dayjs(dateStr, jsFormat).toDate();
      });

      const sortedDates = dates
        .filter((d) => !isNaN(d.getTime()))
        .sort((a, b) => a.getTime() - b.getTime());

      // Calculate the average difference between consecutive dates
      let totalDiff = 0;
      for (let i = 1; i < sortedDates.length; i++) {
        totalDiff +=
          (sortedDates[i].getTime() - sortedDates[i - 1].getTime()) /
          (1000 * 60 * 60 * 24); // Convert to days
      }
      const avgDiffDays = totalDiff / (sortedDates.length - 1);

      // If average difference is close to 7 days, consider it weekly data
      const durationType = Math.abs(avgDiffDays - 7) < 1 ? "weeks" : "days";

      const payload = {
        jobConfigId: jobId,
        name: preCampaignData.geoLiftStudyName,
        jobType: JobType.GeoLift,
        tenantId: getTenantId(),
        dataSourceConfig: {
          dataSourceType: "FILE_CSV",
        },
        dataTransformationConfig: {},
        dataMappingConfig: {
          mappings: {
            geoLift: {
              columnMapping: {
                date_variables: [dateVariable],
                kpi_variables: [kpiVariable],
                location_variables: [locationVariable],
              },
            },
          },
        },
        modelVersion: "V1",
        modelParameterConfig: {
          parameters: {
            dateFormat: "%Y-%m-%d",
            buyType: preCampaignData.buyType,
            durationType: durationType,
            ...formData,
          },
        },
        modelOutputConfig: {},
      };

      // Send the POST request using Axios
      // if (id == undefined) {
      const response = await axiosInstance.post("/api/jobConfig", payload);

      // Automatically execute the newly created job
      await axiosInstance.post(
        "/api/jobRun",
        { jobConfigId: jobId },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("authToken"),
          },
        }
      );
      // } else {
      //   const response = await axiosInstance.put("/api/jobConfig/${id}", payload);
      // }
    } catch (error) {
      setAlertMessage(
        `Failed to ${id == undefined ? "create" : "update"} Job Configuration.`
      );
    }
  };

  // Perform additional actions in parent after form submission

  const getMarketDetails = async (id: string) => {
    try {
      const response = await axiosInstance.get(`/api/geo-lift/output/${id}`);
      let outputJson = response.data.result;

      const locations = Object.keys(outputJson)
        .filter((key) => key.includes("/BestMarkets"))
        .map((key) => key.split("/")[0]);

      setTestMarkets(locations);

      const marketDataMap: Record<string, MarketData[]> = {};
      locations.forEach((location) => {
        const key = `${location}/BestMarkets`;
        if (outputJson[key]) {
          marketDataMap[location] = outputJson[key];
        }
      });
      setAllMarketData(marketDataMap);

      if (locations.length > 0) {
        // Only set first location if no valid selection exists
        const isValidSelection = locations.includes(selectedMarket);
        if (!isValidSelection) {
          setSelectedMarket(locations[0]);
          setMarketData(marketDataMap[locations[0]] || []);
        } else {
          // Refresh data for existing selection
          setMarketData(marketDataMap[selectedMarket] || []);
        }
      }
    } catch (error) {
      console.error("Error fetching market details:", error);
    }
  };

  const getJobConfig = async (id: string) => {
    try {
      // 1. Get Job Config
      const response = await axiosInstance.get(`/api/jobConfig/${id}`);
      const jobConfig = response.data;
      setJobConfig(jobConfig);

      // 2. Get File Data
      const fileResponse = await geoLiftJobConfigService.getFile(
        `${id}/input/geo_lift_data.csv`
      );
      setFileData(fileResponse);

      // Convert the fileResponse to a Blob and create a File if it exists
      let files: File[] = [];
      if (fileResponse && jobConfig.fileName) {
        const blob = new Blob([fileResponse], { type: "text/csv" });
        const file = new File([blob], jobConfig.fileName, { type: "text/csv" });
        files = [file];
      }

      setIsJobConfigPresent(true);

      // 3. Update PreCampaign data with File[]
      setPreCampaignData({
        geoLiftStudyName: jobConfig.name || "",
        buyType: jobConfig.modelParameterConfig.parameters.isNationalBuy
          ? "national"
          : "non-national",
        fileList: files,
      });

      // Update column mappings
      setColumnMappings({
        dateColumn: jobConfig.dateColumn || "date",
        locationColumn: jobConfig.locationColumn || "location",
        kpiColumn: jobConfig.kpiColumn || "y",
      });

      // Set CSV data if available
      if (jobConfig.processedData) {
        setCsvData(jobConfig.processedData);
      }

      return jobConfig;
    } catch (error) {
      console.error("Error fetching job config:", error);
      return null;
    }
  };

  const handleMarketChange = (value: string) => {
    setSelectedMarket(value);
    setMarketData(allMarketData[value] || []);
  };

  const handleMarketSelection = (marketName: string) => {
    setSelectedMarkets((prevMarkets) => {
      const newSelectedMarkets = new Set(prevMarkets);
      if (newSelectedMarkets.has(marketName)) {
        newSelectedMarkets.delete(marketName);
      } else {
        newSelectedMarkets.add(marketName);
      }
      // Update chart data immediately after state change
      const filteredData = allLineData.filter((item) =>
        newSelectedMarkets.has(item.name)
      );
      setLineData([...filteredData]);
      return newSelectedMarkets;
    });
  };

  const handleSelectAll = () => {
    const allMarkets = new Set(allLineData.map((item) => item.name));
    setSelectedMarkets(allMarkets);
    updateChartData(allMarkets);
  };

  const handleClearAll = () => {
    // When clearing all, set to Average Market only
    const defaultMarket = new Set(["Average of All Markets"]);
    setSelectedMarkets(defaultMarket);
    updateChartData(defaultMarket);
  };

  const updateChartData = (selectedMarkets: Set<string>) => {
    const filteredData = allLineData.filter((item) =>
      selectedMarkets.has(item.name)
    );
    setLineData([...filteredData]); // Create a new array to ensure state update
  };
  const [triggerFormSubmit, setTriggerFormSubmit] = useState(false);

  const filteredMarkets = allLineData
    .filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      // Always put Average Market at the top
      if (a.name === "Average of All Markets") return -1;
      if (b.name === "Average of All Markets") return 1;
      return a.name.localeCompare(b.name);
    });

  const handlePreCampaignChange = (values: {
    geoLiftStudyName: string;
    buyType: "national" | "non-national";
    fileList: File[];
  }) => {
    setPreCampaignData(values); // Preserve all PreCampaign data
    if (values.fileList.length > 0) {
      const file: any = values.fileList[0];
      if (file) {
        Papa.parse(file, {
          header: true,
          complete: (result) => {
            const { data, meta } = result;

            // Auto-detect columns based on common patterns
            const autoDetectedColumns = {
              dateColumn:
                meta.fields?.find((f) => f.toLowerCase().includes("date")) ||
                "date",
              locationColumn:
                meta.fields?.find((f) =>
                  f.toLowerCase().includes("location")
                ) || "location",
              kpiColumn:
                meta.fields?.find((f) =>
                  f.toLowerCase().match(/y|sales|revenue/)
                ) || "y",
            };

            setColumnMappings(autoDetectedColumns);

            const parsedData = data
              .filter(
                (item: any) =>
                  item[autoDetectedColumns.dateColumn] &&
                  item[autoDetectedColumns.locationColumn]
              )
              .map((item: any) => ({
                date: item[autoDetectedColumns.dateColumn],
                location: item[autoDetectedColumns.locationColumn].trim(),
                value: parseFloat(item[autoDetectedColumns.kpiColumn]) || 0,
              }));

            setCsvData(parsedData);
          },
          error: (error) => {
            console.error("CSV parsing error:", error);
            setCsvData([]);
          },
        });
      }
    } else {
      setCsvData([]);
    }
  };

  const handleJobCreated = (newJobId: string) => {
    setJobId(newJobId);
  };
  const handleNext = () => {
    const currentData = JSON.stringify(preCampaignData);
    if (current === 0) {
      // if (preCampaignRef.current) {
      //   const formData = preCampaignRef.current.getData();
      //   // Update the state using the received data
      //   setPreCampaignData((prevState) => {
      //     const updatedData = {
      //       ...prevState,
      //       geoLiftStudyName: formData.geoLiftStudyName || prevState.geoLiftStudyName,
      //       buyType: formData.buyType || prevState.buyType,
      //       fileList: formData.fileList || prevState.fileList,
      //     };
      //     // Process data
      //     processPreCampaignData(updatedData);

      //     return updatedData;
      // });
      // try {
      console.log("Data changed, calling update API...");
      //  const response = await axiosInstance.post("/api/jobConfig", preCampaignData);
      // if (response.status === 200) {
      //   console.log("API updated successfully");
      //   prevDataRef.current = currentData; // Update ref to store latest state
      // }
      // } catch (error) {
      //   console.error("Failed to update preCampaignData:", error);
    }

    console.log("verifyTestInputRef.current ", verifyTestInputRef.current);
    if (current === 1 && verifyTestInputRef.current) {
      setTriggerFormSubmit(true);
      verifyTestInputRef.current.submitForm();
    }
    if (current < steps.length - 1) {
      setCurrent(current + 1);
    }
  };
  const handlePrev = () => {
    setCurrent((current) => Math.max(current - 1, 0)); // Ensure it doesn't go below 0
  };

  const steps = [
    {
      title: "Pre Test",
      description: "Upload pre-campaign data",
      content: (
        <PreCampaign
          ref={preCampaignRef}
          jobConfigId={id || ""}
          fileData={fileData}
          onValuesChange={handlePreCampaignChange}
          initialValues={preCampaignData}
          setFileUploaded={setFileUploaded}
        />
      ),
    },
    {
      title: "Pre Test",
      description: "Verify test inputs",
      content: (
        <VerifyTestInput
          ref={verifyTestInputRef}
          jobConfigId={id || ""}
          preCampaignData={preCampaignData}
          isNationalBuy={preCampaignData.buyType === "national"}
          csvData={csvData}
          dateColumn={columnMappings.dateColumn}
          locationColumn={columnMappings.locationColumn}
          onFormSubmit={handleFormSubmit}
          kpiColumn={columnMappings.kpiColumn}
          onJobCreated={handleJobCreated}
        />
      ),
    },
    {
      title: "Pre Test",
      description: "View Test and Control Markets",
      content: (
        <ViewTestAndControlMarket
          testMarkets={testMarkets}
          selectedMarket={selectedMarket}
          jobConfigId={id || ""}
          marketData={marketData}
          allMarketData={allMarketData}
          preCampaignData={preCampaignData}
          onMarketChange={handleMarketChange}
          onTestMarketsChange={setTestMarkets}
        />
      ),
    },
    {
      title: "Post Test",
      description: "Upload post campaign data",
      content: (
        <div>
          <PostTestControlMarketSelection 
          jobConfigId={jobId}
          setPostTestData={setPostTestData}
          onNext={handleNext} />
        </div>
      ),
    },
    {
      title: "Post Test",
      description: "Results",
      content: <ViewResults 
      resultData={postTestData}/>,
    },
  ];
  const isFinalStep = current === steps.length - 1;

  const handleViewControlMarket = (rank: string) => {
    console.log(`Viewing control market for ${rank}`);
    // Add your logic to handle different ranks
  };

  return (
    <div className="w-full mx-auto bg-white rounded-lg shadow-sm">
      <div className="flex">
        {/* Stepper Section */}
        <div className="w-1/3 pr-8 ml-10">
          <h2 className="text-2xl font-semibold mt-5 mb-9 text-gray-900">
            {preCampaignData.geoLiftStudyName}
          </h2>
          <Steps
            direction="vertical"
            current={current}
            onChange={setCurrent}
            items={steps.map((step) => ({
              title: step.title,
              description: step.description,
            }))}
            className="[&_.ant-steps-item]:mb-16 [&_.ant-steps-item-tail]:!top-3 [&_.ant-steps-item-tail]:!h-[110px] [&_.ant-steps-item-tail]:!w-[4px] [&_.ant-steps-item-icon]:!text-2xl [&_.ant-steps-item-icon]:!w-10 [&_.ant-steps-item-icon]:!h-10 [&_.ant-steps-item-icon]:!font-bold [&_.ant-steps-item-icon]:!flex [&_.ant-steps-item-icon]:!items-center [&_.ant-steps-item-icon]:!justify-center [&_.ant-steps-item-title]:!font-medium [&_.ant-steps-item-title]:!text-base"
          />
        </div>

        {/* Content Section */}
        <div
          className="min-h-screen py-7 w-3/4 px-10 border-l overflow-y-auto"
          style={{ height: "100vh" }}
        >
          {steps[current].content}
        </div>
      </div>

      {/* Button Section (Flexbox for Alignment) */}
      {current > 0 && (
        <Button
          onClick={handlePrev}
          size="large"
          className="fixed bottom-8 left-1/2 transform -translate-x-1/2 z-50"
        >
          Previous
        </Button>
      )}

      {current < steps.length - 1 ? (
        <Button
          type="primary"
          size="large"
          className="fixed bottom-8 right-8 z-50"
          onClick={handleNext}
          disabled={isNextDisabled()}
        >
          {getNextButtonLabel()}
        </Button>
      ) : null}
    </div>
  );
};

export default GeoLiftStudy;
