import {
  Input,
  Select,
  Upload,
  Button,
  Space,
  Radio,
  Checkbox,
  message,
  Table,
} from "antd";
import {
  CloseOutlined,
  LeftOutlined,
  RightOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import type { UploadFile, UploadProps } from "antd";
import Papa from "papaparse";
import axiosInstance from "../../../../utils/axiosInstance";
import {
  VARIABLE_SUMMARY_API,
  SEASONALITY_API,
  SIMILARITY_MATRIX_API,
  CORRELATION_MATRIX_API,
  VARIATION_INFLATION_FACTOR_API,
} from "../../../../constants/API";
import { TableRecord } from "../../../../components/Table";
import { DataStructure, UploadDataProps } from "../../../../models/interfaces";
const UploadData: React.FC<UploadDataProps> = ({
  setFileUploaded,
  initialValues,
  onValuesChange,
  setVariableSummaryStatistic,
  setHeatMapData,
  setMultiColinearityRecords,
  setCurrent,
  current,
  onDataUpload,
  setAllVariiables,
  setName,
  setFile,
  trigger,
  setTrigger,
  setLoading,
  name,
  file,
  setIsBusiness,
  IsBusiness,
  setDataType,
  seasonLabel,
  seasonDataSet,
  setSeasonLabel,
  setSeasonDataSet,
  dataType,
}) => {
  const [data, setData] = useState<DataStructure>({
    spendVariable: {
      title: "Spend Variable",
      subtitle: "Marketing Spends",
      items: [],
    },
    impressionVariable: {
      title: "Impression Variable",
      subtitle: "KPIs to track",
      items: [],
    },
    nonMediaVariable: {
      title: "Non-Media Variable",
      subtitle: "Additional Variables",
      items: [],
    },
  });

  // const [dataType, setDataType] = useState<string>("sales");
  const [responseVariable, setResponseVariable] = useState<string>(
    "Select Response Variable"
  );
  const [csvData, setCsvData] = useState<any[]>([]);
  const [columns, setColumns] = useState<
    { title: string; dataIndex: string }[]
  >([]);
  const [fileList, setFileList] = useState<UploadFile[] | any>(
    file ? [file] : []
  );
  // console.log(initialValues, "initialValues");
  const submitImportForm = async (formData: any) => {
    try {
      setLoading(true);
      const file = fileList[0]; // This is a Blob (File)
      setFile(file);
      const similarityFormData = new FormData();
      if (file) {
        similarityFormData.append("csv_file", file); // Type-safe append
      }
      similarityFormData.append("target_variable", formData.responseVariable);
      data.spendVariable.items.forEach((variable: any) => {
        similarityFormData.append("spend_variables", variable.label);
      });
      data.impressionVariable.items.forEach((variable: any) => {
        similarityFormData.append("impression_variables", variable.label);
      });

      data.nonMediaVariable.items.forEach((variable: any) => {
        similarityFormData.append("non_media_variables", variable.label);
      });

      // await axiosInstance.post(SIMILARITY_MATRIX_API, similarityFormData);

      const seasonalityFormData = new FormData();

      if (file) {
        seasonalityFormData.append("csv_file", file); // Type-safe append
      }
      seasonalityFormData.append("date_column", "Date");
      seasonalityFormData.append("response_variable_column", responseVariable);
      seasonalityFormData.append("yearly_seasonality", "true");

      const seasonalityResponse = await axiosInstance.post(
        SEASONALITY_API,
        seasonalityFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + localStorage.getItem("authToken"),
          },
        }
      );

      const seasonalityData = seasonalityResponse.data.seasonality_data;

      const labels = seasonalityData.map((item: any) => item["Date"]);
      const seasonData = seasonalityData.map((item: any) => item.Season);
      const trendData = seasonalityData.map((item: any) => item.Trend);

      setSeasonLabel(labels);

      setSeasonDataSet([
        {
          name: "Season",
          data: seasonData,
          borderColor: "rgba(75, 192, 192, 1)",
          backgroundColor: "rgba(75, 192, 192, 0.3)",
        },
      ]);

      const variableSummaryFormData = new FormData();
      if (file) {
        variableSummaryFormData.append("csv_file", file); // Type-safe append
      }
      variableSummaryFormData.append("date_column", "Date");
      variableSummaryFormData.append("date_format", formData.dateFormat);
      data.impressionVariable.items.forEach((variable: any) => {
        variableSummaryFormData.append("columns", variable.label);
      });
      data.nonMediaVariable.items.forEach((variable: any) => {
        variableSummaryFormData.append("columns", variable.label);
      });
      data.spendVariable.items.forEach((variable: any) => {
        variableSummaryFormData.append("columns", variable.label);
      });
      [
        ...formData.spendVariable,
        ...formData.impressionVariable,
        ...formData.nonMediaVariableVariable,
      ].forEach((column: any) => {
        variableSummaryFormData.append("columns", column.value);
      });

      const variableSummaryResponse = await axiosInstance.post(
        VARIABLE_SUMMARY_API,
        variableSummaryFormData
      );
      setVariableSummaryStatistic(
        variableSummaryResponse.data.variable_summary
      );

      const correlationMatrixForm = new FormData();

      if (file) {
        correlationMatrixForm.append("csv_file", file); // Type-safe append
      }
      correlationMatrixForm.append(
        "target_variable",
        data.spendVariable.items[0].label
      );
      data.spendVariable.items.map((item: any) => {
        correlationMatrixForm.append("spend_variables", item.label);
      });
      if (data.nonMediaVariable.items instanceof Array) {
        data.nonMediaVariable.items.map((item: any) => {
          correlationMatrixForm.append("non_media_variables", item.label);
        });
      }
      data.impressionVariable.items.map((item: any) => {
        correlationMatrixForm.append("impression_variables", item.label);
      });

      const correlationMatrixResponse = await axiosInstance.post(
        CORRELATION_MATRIX_API,
        correlationMatrixForm,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + localStorage.getItem("authToken"),
          },
        }
      );

      const correlationMatrix =
        correlationMatrixResponse.data.correlation_matrix;
      const xLabels = Object.keys(correlationMatrix);
      const yLabels = xLabels;
      const zData = xLabels.map((x) =>
        yLabels.map((y) => correlationMatrix[x][y])
      );
      setHeatMapData({
        xLabels,
        yLabels,
        zData,
      });
      const multiColinearityForm = new FormData();
      if (file) {
        multiColinearityForm.append("csv_file", file);
      }
      data.spendVariable.items.map((item: any) => {
        multiColinearityForm.append("spend_variables", item.label);
      });
      if (data.nonMediaVariable.items instanceof Array) {
        data.nonMediaVariable.items.map((item: any) => {
          multiColinearityForm.append("non_media_variables", item.label);
        });
      }
      data.impressionVariable.items.map((item: any) => {
        multiColinearityForm.append("impression_variables", item.label);
      });

      const multiColinearityResponse = await axiosInstance.post(
        VARIATION_INFLATION_FACTOR_API,
        multiColinearityForm,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + localStorage.getItem("authToken"),
          },
        }
      );
      if (typeof multiColinearityResponse.data === "string") {
        try {
          multiColinearityResponse.data = JSON.parse(
            multiColinearityResponse.data.replaceAll("Infinity", "1")
          );
        } catch (error) {
          console.error("Failed to parse JSON string:", error);
        }
      }

      const records: TableRecord[] = [];
      Object.keys(multiColinearityResponse.data.variance_inflation_factors).map(
        (key) => {
          records.push({
            variable: key,
            value:
              multiColinearityResponse.data.variance_inflation_factors[key],
          });
        }
      );
      setMultiColinearityRecords(records);
      setLoading(false);
    } catch (error) {
      console.error("Error in submitImportForm:", error);
      setLoading(false);
    } finally {
      setLoading(false);
      setCurrent(current + 1);
    }
  };
  useEffect(() => {
    if (responseVariable === "Select Response Variable" && trigger) {
      message.error("Please select a response variable");
      setTrigger(false);
      return;
    }
    if (trigger) {
      submitImportForm({
        responseVariable: responseVariable, // Example value, replace with actual form data
        dateVariable: "date",
        dateFormat: "%m/%d/%Y",
        spendVariable: [],
        impressionVariable: [],
        nonMediaVariableVariable: [],
      });
      setTrigger(false);
    }
  }, [trigger]);
  // Handle checkbox change with proper typing
  const handleCheckboxChange = (
    category: keyof DataStructure,
    itemId: number
  ) => {
    setData((prevData) => {
      const updatedCategory = { ...prevData[category] };
      updatedCategory.items = updatedCategory.items.map((item) =>
        item.id === itemId ? { ...item, checked: !item.checked } : item
      );
      return { ...prevData, [category]: updatedCategory };
    });
  };

  // Handle moving items between categories with proper typing
  const moveItems = (
    fromCategory: keyof DataStructure,
    toCategory: keyof DataStructure,
    direction: "left" | "right"
  ) => {
    setData((prevData) => {
      const from = { ...prevData[fromCategory] };
      const to = { ...prevData[toCategory] };

      const itemsToMove = from.items.filter((item) => item.checked);
      if (itemsToMove.length === 0) return prevData;

      from.items = from.items.filter((item) => !item.checked);
      to.items = [
        ...to.items,
        ...itemsToMove.map((item) => ({ ...item, checked: false })),
      ];

      return { ...prevData, [fromCategory]: from, [toCategory]: to };
    });
  };
  const processGeneratedColumns = (
    columns: { title: string; dataIndex: string }[]
  ) => {
    const spendItems = columns
      .filter((col) => col.title.includes("_Spend"))
      .map((col, index) => ({
        id: index + 1,
        label: col.title,
        checked: false,
      }));

    const impressionItems = columns
      .filter((col) => col.title.includes("_Impressions"))
      .map((col, index) => ({
        id: index + 1 + spendItems.length,
        label: col.title,
        checked: false,
      }));

    const nonMediaItems = columns
      .filter(
        (col) =>
          !col.title.includes("_Spend") && !col.title.includes("_Impressions")
      )
      .map((col, index) => ({
        id: index + 1 + spendItems.length + impressionItems.length,
        label: col.title,
        checked: false,
      }));

    setData({
      spendVariable: {
        title: "Spend Variable",
        subtitle: "Marketing Spends",
        items: spendItems.filter((ele) => ele.label !== "Date"),
      },
      impressionVariable: {
        title: "Impression Variable",
        subtitle: "KPIs to track",
        items: impressionItems.filter((ele) => ele.label !== "Date"),
      },
      nonMediaVariable: {
        title: "Non-Media Variable",
        subtitle: "Additional Variables",
        items: nonMediaItems.filter((ele) => ele.label !== "Date"),
      },
    });
    setAllVariiables({
      spendVariable: {
        title: "Spend Variable",
        subtitle: "Marketing Spends",
        items: spendItems,
      },
      impressionVariable: {
        title: "Impression Variable",
        subtitle: "KPIs to track",
        items: impressionItems,
      },
      nonMediaVariable: {
        title: "Non-Media Variable",
        subtitle: "Additional Variables",
        items: nonMediaItems,
      },
    });
  };
  const uploadProps: UploadProps = {
    name: "file",
    accept: ".csv",
    beforeUpload: (file) => {
      setLoading(true);
      Papa.parse(file, {
        complete: (result) => {
          if (result.data.length > 0) {
            const headers = result.data[0] as string[];
            const generatedColumns = headers.map((header) => ({
              title: header,
              dataIndex: header.toLowerCase().replace(/\s+/g, "_"),
              // width: 150,
            }));
            processGeneratedColumns(generatedColumns); // Process and categorize columns
            const tableData = result.data.slice(1).map((row: any, index) => ({
              key: index,
              ...headers.reduce((acc, header, i) => {
                acc[header.toLowerCase().replace(/\s+/g, "_")] = row[i];
                return acc;
              }, {} as Record<string, any>),
            }));
            setColumns(generatedColumns);
            setCsvData(tableData);
            onDataUpload?.(tableData, generatedColumns); // Pass data and columns to parent
            setFileUploaded?.(true); // Enable the Next button
            message.success(`${file.name} uploaded successfully`);
          } else {
            message.error("Empty CSV or invalid format.");
            setFileUploaded?.(false);
          }
          setLoading(false);
        },
        error: (error) => {
          console.error("CSV parsing error:", error);
          setCsvData([]);
          setLoading(false);
          setFileUploaded?.(false);
        },
        skipEmptyLines: true,
      });
      setFileList([file]);
      return false; // Prevent automatic upload
    },
    fileList,
    onRemove: () => {
      setFileList([]);
      setCsvData([]);
      setFileUploaded?.(false); // Disable the Next button on file removal
    },
    showUploadList: {
      showRemoveIcon: true,
      removeIcon: <CloseOutlined className="text-red-500" />,
    },
  };
  console.log(fileList, "fileList");
  useEffect(() => {
    if (fileList.length) {
      const file: any = fileList[0];
      if (file) {
        Papa.parse(file, {
          complete: (result) => {
            if (result.data.length > 0) {
              const headers = result.data[0] as string[];
              const generatedColumns = headers.map((header) => ({
                title: header,
                dataIndex: header.toLowerCase().replace(/\s+/g, "_"),
              }));
              processGeneratedColumns(generatedColumns);
              const tableData = result.data.slice(1).map((row: any, index) => ({
                key: index,
                ...headers.reduce((acc, header, i) => {
                  acc[header.toLowerCase().replace(/\s+/g, "_")] = row[i];
                  return acc;
                }, {} as Record<string, any>),
              }));

              setColumns(generatedColumns);
              setCsvData(tableData);
            }
          },
          error: (error) => {
            console.error("CSV parsing error:", error);
            setCsvData([]);
          },
          skipEmptyLines: true,
        });
      }
    }
  }, []);
  useEffect(() => {
    if (onValuesChange) {
      onValuesChange({
        geoLiftStudyName: name,
        buyType: dataType,
        fileList,
      });
    }
  }, [name, dataType]);
  return (
    <div>
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        <div>
          <h3 className="mb-1 font-bold">Name</h3>
          <Input
            type="text"
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter Your Test Name"
            value={name ?? ""}
          />
        </div>
        <div>
          <h3 className="mb-1 font-bold">Select Data Type</h3>
          <Select
            style={{ width: "100%" }}
            placeholder="Select data type"
            value={dataType}
            onChange={(value) => setDataType(value)}
            options={[
              { value: "sales-model", label: "Sales Data" },
              {
                value: "conversion/lead model",
                label: "Conversion/Lead Model",
              },
            ]}
          />
        </div>

        <div>
          <h3 className="font-bold">Upload File</h3>
          <Upload {...uploadProps} className="w-full">
            <Button
              icon={<UploadOutlined />}
              size="large"
              className="flex items-center mt-4 "
            >
              Upload Your Historic Campaign Data
            </Button>
          </Upload>
        </div>
        <div>
          <div className="flex justify-between w-full my-2">
            <Radio.Group
              value={IsBusiness}
              onChange={(e) => setIsBusiness(e.target.value)}
            >
              <Radio
                checked={IsBusiness === "IsBusinessNot"}
                value="IsBusinessNot"
              >
                My Business is not Seasonal
              </Radio>
              <Radio
                checked={IsBusiness === "IsBusinessYes"}
                value="IsBusinessYes"
              >
                My Business is Seasonal
              </Radio>
            </Radio.Group>
          </div>
        </div>
        {csvData && csvData?.length > 0 && (
          <div>
            <Table
              columns={columns}
              dataSource={csvData}
              pagination={false}
              scroll={{ x: true, y: 240 }}
              bordered
              size="middle"
              className="w-full shadow-sm"
            />
          </div>
        )}
        {fileList.length > 0 && (
          <div>
            <h3 className="mb-1 font-bold">Response Variable</h3>
            <Select
              style={{ width: "100%" }}
              placeholder="Response Variable"
              value={responseVariable}
              onChange={(value) => setResponseVariable(value)}
              options={[
                ...data.spendVariable.items,
                ...data.impressionVariable.items,
                ...data.nonMediaVariable.items,
              ].map((item) => ({
                value: item.label,
                label: item.label,
              }))}
            />
          </div>
        )}

        {data.spendVariable.items.length > 0 && (
          <div className="p-6">
            <h3 className="text-lg font-semibold text-gray-600 mb-4">
              Verify the category before submission, Move variables to correct
              category using arrows
            </h3>
            <div className="flex justify-between gap-4 relative">
              {/* Spend Variable Card */}
              <div className="w-1/3 bg-white rounded-lg shadow-md p-4">
                <h4 className="font-bold text-gray-700">
                  {data.spendVariable.title}
                </h4>
                <p className="text-gray-500 text-sm">
                  {data.spendVariable.subtitle}
                </p>
                <div className="space-y-2 mt-3">
                  {data.spendVariable.items.map((item) => (
                    <label
                      key={item.id}
                      className={`flex items-center p-1 hover:bg-gray-100 rounded ${
                        item.checked ? "bg-gray-100" : ""
                      }`}
                    >
                      <Checkbox
                        className="mr-2"
                        checked={item.checked}
                        onChange={() =>
                          handleCheckboxChange("spendVariable", item.id)
                        }
                      />
                      <span>{item.label}</span>
                    </label>
                  ))}
                </div>
              </div>

              {/* Arrows between Spend Variable and Impression Variable */}
              <div className=" relative flex flex-col items-center justify-center">
                <Button
                  className="w-10 h-6 rounded flex items-center justify-center hover:bg-gray-300"
                  onClick={() =>
                    moveItems("spendVariable", "impressionVariable", "right")
                  }
                >
                  <RightOutlined />
                </Button>
                <Button
                  className="w-10 mt-1 h-6 rounded flex items-center justify-center hover:bg-gray-300"
                  onClick={() =>
                    moveItems("impressionVariable", "spendVariable", "left")
                  }
                >
                  <LeftOutlined />
                </Button>
              </div>

              {/* Impression Variable Card */}
              <div className="w-1/3 bg-white rounded-lg shadow-md p-4">
                <h4 className="font-bold text-gray-700">
                  {data.impressionVariable.title}
                </h4>
                <p className="text-gray-500 text-sm">
                  {data.impressionVariable.subtitle}
                </p>
                <div className="space-y-2 mt-3">
                  {data.impressionVariable.items.map((item) => (
                    <label
                      key={item.id}
                      className={`flex items-center p-1 hover:bg-gray-100 rounded ${
                        item.checked ? "bg-gray-100" : ""
                      }`}
                    >
                      <Checkbox
                        className="mr-2"
                        checked={item.checked}
                        onChange={() =>
                          handleCheckboxChange("impressionVariable", item.id)
                        }
                      />
                      <span>{item.label}</span>
                    </label>
                  ))}
                </div>
              </div>

              {/* Arrows between Impression Variable and Non-Media Variable */}
              <div className="  relative flex flex-col items-center justify-center">
                <Button
                  className="w-10 h-6 rounded flex items-center justify-center hover:bg-gray-300"
                  onClick={() =>
                    moveItems("impressionVariable", "nonMediaVariable", "right")
                  }
                >
                  <RightOutlined />
                </Button>
                <Button
                  className="w-10 mt-1 h-6 rounded flex items-center justify-center hover:bg-gray-300"
                  onClick={() =>
                    moveItems("nonMediaVariable", "impressionVariable", "left")
                  }
                >
                  <LeftOutlined />
                </Button>
              </div>

              {/* Non-Media Variable Card */}
              <div className="w-1/3 bg-white rounded-lg shadow-md p-4">
                <h4 className="font-bold text-gray-700">
                  {data.nonMediaVariable.title}
                </h4>
                <p className="text-gray-500 text-sm">
                  {data.nonMediaVariable.subtitle}
                </p>
                <div className="space-y-2 mt-3">
                  {data.nonMediaVariable.items.map((item) => (
                    <label
                      key={item.id}
                      className={`flex items-center p-1 hover:bg-gray-100 rounded ${
                        item.checked ? "bg-gray-100" : ""
                      }`}
                    >
                      <Checkbox
                        className="mr-2"
                        checked={item.checked}
                        onChange={() =>
                          handleCheckboxChange("nonMediaVariable", item.id)
                        }
                      />
                      <span>{item.label}</span>
                    </label>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </Space>
    </div>
  );
};

export default UploadData;
