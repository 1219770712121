import { Button, Form, Select, Upload, Table, Spin } from "antd";
import { RuleObject } from "antd/es/form";
import { useEffect, useState } from "react";
import { UploadOutlined, CloseOutlined } from "@ant-design/icons";
import type { UploadFile, UploadProps } from "antd";
import { any, z } from "zod";
import Papa from "papaparse";
import axiosInstance from "../../../../utils/axiosInstance";
import { getTenantId } from "../../../../utils/auth";

interface AgFormValues {
  testMarket: string;
  postTestData: UploadFile[];
  startDuration: string;
  endDuration: string;
  adSpendColumn?: any;
  adsSpent?: any;
}

interface FormValues {
  testMarket: string;
  postTestData: UploadFile[];
  syntheticMarket: string;
}
interface PostTestData {
  success: boolean;
  message: string;
  data: Record<string, any>;
}

interface PostTestControlMarketSelectionProps {
  jobConfigId: string;
  setPostTestData: React.Dispatch<React.SetStateAction<PostTestData>>;
  onNext: () => void;
}

const PostTestControlMarketSelection: React.FC<
  PostTestControlMarketSelectionProps
> = ({ jobConfigId, setPostTestData,onNext }) => {
  const [form] = Form.useForm<FormValues>();
  const [agform] = Form.useForm<AgFormValues>();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [loading, setLoading] = useState(false);
  const [csvData, setCsvData] = useState<any[]>([]);
  const [durationType, setDurationType] = useState<string>('days');
  const [testMarkets, setTestMarkets] = useState<any[]>([]);
  const [columns, setColumns] = useState<
    { title: string; dataIndex: string }[]
  >([]);
  const [syntheticLocationMap, setSyntheticLocationMap] = useState<
    Record<string, string[]>
  >({});
  const [syntheticMarketOptions, setSyntheticMarketOptions] = useState<
    { value: string; label: string }[]
  >([]);
  const [showSyntheticField, setShowSyntheticField] = useState(true);

  const onFinish = async (values: FormValues | AgFormValues) => {
    console.log("Form values:", values);

    // Check if the file is available
    if (values.postTestData && values.postTestData.length > 0) {
      const file = values.postTestData[0].originFileObj as File;
      console.log("Selected File:", file);

      // Construct form data
      const formData = new FormData();
      formData.append("csv_file", file); // Use the actual file object

      if (values.testMarket === "Aggregated Locations") {
        // Call the API for aggregated result
        const aggregatedValues = values as AgFormValues;
        setLoading(true);
        // let totalAdSpend = parseFloat(aggregatedValues.adsSpent);
        // if (isNaN(totalAdSpend)) {
        //   throw new Error('Total Ad Spend is required and must be a valid number.');
        // }
        let totalAdSpend = 0;
        const adSpendColumn = "Ad Spend";
        if (file) {
          await new Promise<void>((resolve, reject) => {
            Papa.parse(file, {
              complete: (results: any) => {
                // Validate if the Ad Spend column exists
                if (!results.meta.fields?.includes(adSpendColumn)) {
                  console.error(`Column "${adSpendColumn}" not found in CSV.`);
                  reject(new Error(`Ad Spend Column "${adSpendColumn}" not found in CSV.`));
                  return;
                }
  
                console.log("CSV Headers:", results.meta.fields);
  
                // Calculate Total Ad Spend
                totalAdSpend = results.data.reduce((sum: number, row: any, index: number) => {
                  const value = parseFloat(row[adSpendColumn]);
                  if (isNaN(value)) {
                    console.warn(`Invalid Ad Spend value in row ${index + 1}:`, row[adSpendColumn]);
                    return sum;
                  }
                  return sum + value;
                }, 0);
  
                console.log(`Total Ad Spend: ${totalAdSpend}`);
                resolve();
              },
              error: (error) => {
                console.error("Error parsing CSV:", error);
                reject(error);
              },
              header: true,
              skipEmptyLines: true,
              dynamicTyping: true,
            });
          });
        }
        console.log("total ad spend ", totalAdSpend)

        formData.append("jobConfigId", jobConfigId); // Use the passed jobConfigId
        formData.append("tenantId", getTenantId());
        formData.append("confidenceThreshold", "50"); // Example value, replace with actual if needed
        formData.append("startDuration", "97"); // Example value, replace with actual if needed
        formData.append("endDuration", "105"); // Example value, replace with actual if needed
        formData.append("adSpendColumn", "Ad Spend"); // Example value, replace with actual if needed
        formData.append("totalAdSpend", totalAdSpend.toString()); // Example value, replace with actual if needed
        formData.append("adsSpent", "0"); // Example value, replace with actual if needed

        axiosInstance
          .post("/model/geo_lift/aggregate_lift_percentage", formData)
          .then((response) => {
            console.log("API response:", response.data);
            if (setPostTestData) {
              setPostTestData(response.data);
              setLoading(false);
              onNext();
            }
          })
          .catch((error) => {
            console.error("API error:", error);
          });

      } else {
        const InValues = values as FormValues;
        setLoading(true);
        // Call the existing API for specific market results
        formData.append("marketSelectionId", "0"); // Example value, replace with actual if needed
        formData.append("startDuration", "97"); // Example value, replace with actual if needed
        formData.append("endDuration", "105"); // Example value, replace with actual if needed
        formData.append("marketsList", InValues.syntheticMarket); // Append the selected synthetic market
        formData.append("jobConfigId", jobConfigId); // Use the passed jobConfigId
        formData.append("market_name", InValues.testMarket); // Use the selected test market location
        formData.append("adSpendColumn", "Ad Spend"); // Example value, replace with actual if needed
        formData.append("calculatedAdSpend", "153636.30552602257"); // Example value, replace with actual if needed

        axiosInstance
          .post("/model/geo_lift/post_test", formData)
          .then((response) => {
            console.log("API response:", response.data);
            if (setPostTestData) {
              setPostTestData(response.data);
              setLoading(false);
              onNext();
            }
          })
          .catch((error) => {
            console.error("API error:", error);
          });
      }

      setFileList([]);
    } else {
      console.error("No file selected");
    }
  };

  const getMarketDetails = (id: any) => {
    axiosInstance.get(`/api/geo-lift/output/${id}`).then((response) => {
      let outputJson = response.data.result;

      // Extract unique locations from the response keys
      const locations = Object.keys(outputJson)
        .filter((key) => key.includes("/BestMarkets"))
        .map((key) => key.split("/")[0]);
      locations.push("Aggregated Locations"); // Add the "Aggregated Locations" option
      setTestMarkets(locations);

      // Create the synthetic location map
      const syntheticLocationMap: Record<string, string[]> = {};

      // Iterate over each location
      Object.keys(outputJson).forEach((key) => {
        if (key.includes("/BestMarkets")) {
          const marketName = key.split("/")[0];
          const syntheticLocations = outputJson[key].map((market: any) =>
            market.location
              .replaceAll("(", "")
              .replace(")", "")
              .replaceAll("'", "")
          );
          syntheticLocationMap[marketName] = syntheticLocations;
        }
      });

      // Store the synthetic location map in state
      setSyntheticLocationMap(syntheticLocationMap);
    });
  };

  useEffect(() => {
    getMarketDetails(jobConfigId);
  }, [jobConfigId]);

  const handleTestMarketChange = (value: string) => {
    if (value === "Aggregated Locations") {
      setShowSyntheticField(false);
    } else {
      setShowSyntheticField(true);
      // Update synthetic market options based on selected test market
      const syntheticLocations = syntheticLocationMap[value] || [];
      setSyntheticMarketOptions(
        syntheticLocations.slice(0, 1).map((location) => ({
          value: location,
          label: location,
        }))
      );
    }
  };

  const uploadProps: UploadProps = {
    beforeUpload: (file) => {
      // Prevent automatic upload
      return false;
    },
    onChange: ({ fileList: newFileList }) => {
      setFileList(newFileList);
      form.setFieldsValue({ postTestData: newFileList });
    },
    fileList,
    accept: ".csv",
    onRemove: () => {
      setCsvData([]);
      setFileList([]);
      form.setFieldsValue({ postTestData: [] });
    },
  };

  const validatePassword = (_: RuleObject, value: string) => {
    if (value && value.length < 8) {
      return Promise.reject("Password must be at least 8 characters!");
    }
    return Promise.resolve();
  };

  return (
    <div className="mt-10">
      <Form form={form} layout="vertical" onFinish={onFinish}>
      {loading ? (
          <div className="flex justify-center items-center h-40">
            <Spin size="large" />
          </div>
        ) : (
        <div className="space-y-8">
          {/* Form Fields Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-6">
            {/* Test Markets */}
            <Form.Item
              label="Select Test Markets to Produce Lift Results"
              name="testMarket"
              rules={[
                { required: true, message: "Please select a test market!" },
              ]}
              className="mb-0 w-full"
            >
              <Select
                placeholder="Select a test market..."
                options={testMarkets.map((location) => ({
                  label: location,
                  value: location,
                }))}
                className="w-full"
                onChange={handleTestMarketChange}
              />
            </Form.Item>

            {/* Post-Test Data Upload */}
            <Form.Item
              label="Upload Post-Test Data"
              name="postTestData"
              rules={[{ required: true, message: "Please upload CSV file!" }]}
              className="mb-0 w-full [&_.ant-upload]:w-full [&_.ant-upload-select]:w-full"
            >
              <div className="space-y-2 w-full">
                <Upload {...uploadProps} className="w-full">
                  <Button icon={<UploadOutlined />} className="w-full">
                    Click to upload CSV
                  </Button>
                </Upload>
                <div className="text-sm text-gray-500">
                  <a
                    href="/sample-post-test.csv"
                    download
                    className="text-blue-600 hover:underline"
                  >
                    Download Sample CSV
                  </a>
                </div>
              </div>
            </Form.Item>

            {/* Synthetic Control Markets */}
            {showSyntheticField && (
              <Form.Item
                label="Best Synthetic Control Market"
                name="syntheticMarket"
                rules={[
                  {
                    required: true,
                    message: "Please select a control market!",
                  },
                ]}
                className="mb-0 md:col-span-2 w-full"
              >
                <Select
                  placeholder="Select a control market..."
                  options={syntheticMarketOptions}
                  className="w-full"
                />
              </Form.Item>
            )}
          </div>

          {/* CSV Preview Table - Now outside the upload section */}
          {csvData.length > 0 && (
            <div className="w-full mt-6">
              <div className="flex justify-between items-center mb-3">
                <h3 className="text-lg font-semibold">CSV Data Preview</h3>
                <Button
                  type="default"
                  onClick={() => {
                    setCsvData([]);
                    setFileList([]);
                  }}
                  icon={<CloseOutlined />}
                >
                  Clear Preview
                </Button>
              </div>
              <Table
                columns={columns}
                dataSource={csvData.slice(0, 10)}
                pagination={false}
                scroll={{ x: true }}
                bordered
                size="middle"
                className="w-full shadow-sm"
              />
            </div>
          )}

          {/* Form Action */}
          <Form.Item className="w-full flex justify-end mt-8">
            <Button
              type="primary"
              htmlType="submit"
              className="w-full py-4 text-lg"
            >
              Generate Analysis
            </Button>
          </Form.Item>
        </div>
        )}
      </Form>
    </div>
  );
};

export default PostTestControlMarketSelection;
