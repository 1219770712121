import React, { useEffect, useState } from "react";
import axiosInstance from "../../../utils/axiosInstance";
import logo from "../../../assets/facebook_logo.webp";
import { getTenantId } from "../../../utils/auth";

declare global {
  interface Window {
    fbAsyncInit: () => void;
    FB: any;
  }
}
const FacebookLogin: React.FC = () => {
  useEffect(() => {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: "3120378421464026",
        cookie: true,
        xfbml: true,
        version: "v17.0",
      });

      window.FB.getLoginStatus((response: any) => {
        if (response.status === "connected") {
          handleTokenStorage(response.authResponse);
        }
      });
    };

    (function (d, s, id) {
      let js: HTMLScriptElement;
      let fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s) as HTMLScriptElement;
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs?.parentNode?.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }, []);

  const handleLogin = () => {
    window.FB.login(
      (response: any) => {
        if (response.authResponse) {
          handleTokenStorage(response.authResponse);
        }
      },
      { scope: "business_management,ads_management,email,public_profile" }
    );
  };

    const handleTokenStorage = async (authResponse: any) => {
        try {
            const { accessToken, expiresIn, userID } = authResponse;
            await axiosInstance.post("/api/facebook/auth", {
                tenantId: getTenantId(),
                client_id: userID,
                access_token: accessToken,
                expires_in: expiresIn,
            });
            alert("Login Successful");
        } catch (error) {
            console.error("Error saving token", error);
        }
    };

  return (
    <div className="p-4 md:p-6 grid grid-cols-1 md:grid-cols-12 gap-4 md:gap-6 bg-gray-100 min-h-screen">
      <div className="col-span-1 md:col-span-3 space-y-4">
        <div className="bg-white p-4 rounded-xl shadow-md">
          <h3 className="text-lg text-center font-semibold text-gray-700 mb-6">
            Facebook
          </h3>
          <>
            <div className="text-center">
              <img src={logo} alt="Facebook Logo" />
              <button
                className="w-full bg-blue-500 text-white py-2 rounded text-sm font-medium hover:bg-blue-600 mt-4"
                onClick={handleLogin}
              >
                Login with Facebook
              </button>
            </div>
          </>
        </div>
      </div>
    </div>
  );
};

export default FacebookLogin;
