import React, { useEffect, useState } from "react";
import JobConfigView from "../../../components/JobConfigView";
import { TableColumn } from "../../../components/Table";
import { JobType } from "../../../constants/JobTypes";
import { fetchJobConfigMMM, fetchJobRunMMM } from "../../../redux/jobMMMSlice";
import { AppDispatch, RootState } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/Loader";

const columns: TableColumn[] = [
  { key: "name", label: "Name" },
  {
    key: "createdDate",
    label: "Created Date",
    formatter: {
      type: "date",
    },
  },
  { key: "status", label: "Status" },
];

const nbaBreadCrumbs = [
  {
    name: "Home",
    url: "/",
  },
  {
    name: "Measurement",
    url: "/planning/marketing-mix-model",
  },
  {
    name: "Marketing Mix Model",
    url: "/planning/marketing-mix-model",
  },
];

const MarketingMixModel: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>(); // Typed dispatch
  const { jobConfigList, jobRunList, loading } = useSelector(
    (state: RootState) => state.jobMMM
  );

  useEffect(() => {
    dispatch(fetchJobConfigMMM());
    dispatch(fetchJobRunMMM());
  }, [dispatch]);

  const variables = ["Budget", "Week", "Google Spend", "Meta Spend"];
  const defaultValues = {
    "Scenario 1": {
      Budget: "1000",
      Week: "1",
      "Google Spend": "500",
      "Meta Spend": "500",
    },
    "Scenario 2": {
      Budget: "2000",
      Week: "2",
      "Google Spend": "1000",
      "Meta Spend": "1000",
    },
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <JobConfigView
          jobConfigList={jobConfigList}
          jobRunList={jobRunList}
          breadCrumbs={nbaBreadCrumbs}
          tableColumns={columns}
          jobType={JobType.MarketingMixModel}
        />
      )}
    </>
  );
};

export default MarketingMixModel;
