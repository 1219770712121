import React, { useState } from "react";
import { z } from "zod";
import DynamicForm, { ExtendedFieldConfig } from "./DynamicForm";
import NavBar from "./NavBar";
import {
  JobConfigService,
  JobConfigServiceFactory,
} from "../services/jobConfigServiceFactory/JobConfigServiceFactory";
import { JobType } from "../constants/JobTypes";

const extendedFormSchema: Record<string, ExtendedFieldConfig> = {
  name: {
    validation: z.string(),
    metadata: { type: "text", label: "Name", group: "row1" },
  },
  jobType: {
    validation: z.string(),
    metadata: {
      type: "select",
      label: "Job Type",
      options: [
        { value: JobType.MarketingMixModel, label: "Marketing Mix Model" },
        { value: JobType.NextBestAction, label: "Next Best Action" },
        {
          value: JobType.MultiTouchAttribution,
          label: "Multi Touch Attribution",
        },
        { value: JobType.CustomTargeting, label: "Custom Targeting" },
        { value: JobType.GeoLift, label: "Geo Lift" },
        { value: JobType.BidOptimizer, label: "Bid Optimizer" },
      ],
      group: "row1",
      defaultValue: JobType.CustomTargeting,
    },
  },
  dataSourceType: {
    validation: z.string(),
    metadata: {
      type: "select",
      label: "Data Source Type",
      options: [
        { value: "file", label: "Files" },
        { value: "snowflake", label: "Snowflake" },
        { value: "facebook", label: "Facebook" },
      ],
      group: "row2",
      defaultValue: "file",
    },
  },
  snowflakeUrl: {
    validation: z.string().optional(),
    metadata: {
      type: "text",
      label: "Snowflake Url",
      group: "row3",
      showIf: [
        {
          field: "dataSourceType",
          value: "snowflake",
          validation: z.string(),
        },
      ],
    },
  },
  snowflakeAccessToken: {
    validation: z.string().optional(),
    metadata: {
      type: "text",
      label: "Snowflake Access Token",
      group: "row3",
      showIf: [
        {
          field: "dataSourceType",
          value: "snowflake",
          validation: z.string(),
        },
      ],
    },
  },
  facebookClientId: {
    validation: z.string().optional(),
    metadata: {
      type: "text",
      label: "Facebook Client Id",
      group: "row3",
      showIf: [
        {
          field: "dataSourceType",
          value: "facebook",
          validation: z.string(),
        },
      ],
    },
  },
  facebookClientSecret: {
    validation: z.string().optional(),
    metadata: {
      type: "text",
      label: "Facebook Client Secret",
      group: "row3",
      showIf: [
        {
          field: "dataSourceType",
          value: "facebook",
          validation: z.string(),
        },
      ],
    },
  },
  salesColumn: {
    validation: z.string().optional(),
    metadata: {
      type: "text",
      label: "Sales - Mapping Column",
      group: "row3a",
      showIf: [
        {
          field: "jobType",
          value: JobType.GeoLift,
          validation: z
            .any()
            .refine((file) => file && file.length, "Please select action file"),
        },
      ],
    },
  },
  adsSpentColumn: {
    validation: z.string().optional(),
    metadata: {
      type: "text",
      label: "Ads Spent - Mapping Column",
      group: "row3a",
      showIf: [
        {
          field: "jobType",
          value: JobType.GeoLift,
          validation: z
            .any()
            .refine((file) => file && file.length, "Please select action file"),
        },
      ],
    },
  },
  salesFile: {
    validation: z.any(),
    metadata: {
      type: "file",
      label: "Sales File CSV",
      group: "row3a",
      accepts: ".csv",
      showIf: [
        {
          field: "dataSourceType",
          value: "file",
          validation: z
            .any()
            .refine((file) => file && file.length, "Please select action file"),
        },
        {
          field: "jobType",
          value: JobType.GeoLift,
          validation: z
            .any()
            .refine((file) => file && file.length, "Please select action file"),
        },
      ],
    },
  },
  budgetAllocationFile: {
    validation: z.any(),
    metadata: {
      type: "file",
      label: "Ads Budget Allocate File CSV",
      group: "row3b",
      accepts: ".csv",
      showIf: [
        {
          field: "dataSourceType",
          value: "file",
          validation: z
            .any()
            .refine((file) => file && file.length, "Please select action file"),
        },
        {
          field: "jobType",
          value: JobType.GeoLift,
          validation: z
            .any()
            .refine((file) => file && file.length, "Please select action file"),
        },
      ],
    },
  },
  actionFile: {
    validation: z.any(),
    metadata: {
      type: "file",
      label: "Action CSV",
      group: "row3c",
      accepts: ".csv",
      showIf: [
        {
          field: "dataSourceType",
          value: "file",
          validation: z
            .any()
            .refine((file) => file && file.length, "Please select action file"),
        },
        {
          field: "jobType",
          value: JobType.NextBestAction,
          validation: z
            .any()
            .refine((file) => file && file.length, "Please select action file"),
        },
      ],
    },
  },
  customerFile: {
    validation: z.any(),
    metadata: {
      type: "file",
      label: "Customer CSV",
      group: "row6d",
      accepts: ".csv",
      showIf: [
        {
          field: "dataSourceType",
          value: "file",
          validation: z
            .any()
            .refine(
              (file) => file && file.length,
              "Please select customer file"
            ),
        },
        {
          field: "jobType",
          value: JobType.NextBestAction,
          validation: z
            .any()
            .refine(
              (file) => file && file.length,
              "Please select customer file"
            ),
        },
      ],
    },
  },
  channelFile: {
    validation: z.any(),
    metadata: {
      type: "file",
      label: "Channel CSV",
      group: "row7e",
      accepts: ".csv",
      showIf: [
        {
          field: "dataSourceType",
          value: "file",
          validation: z
            .any()
            .refine(
              (file) => file && file.length,
              "Please select channel file"
            ),
        },
        {
          field: "jobType",
          value: JobType.NextBestAction,
          validation: z
            .any()
            .refine(
              (file) => file && file.length,
              "Please select channel file"
            ),
        },
      ],
    },
  },
  modelVersion: {
    validation: z.string(),
    metadata: {
      type: "select",
      label: "Job Version",
      options: [
        { value: "v1", label: "Version 1" },
        { value: "v2", label: "Version 2" },
        { value: "v3", label: "Version 3" },
      ],
      group: "row4",
    },
  },
  confidence: {
    validation: z.string(),
    metadata: {
      type: "text",
      label: "Confidence",
      group: "row5",
    },
  },
};

interface JobConfigProps {
  jobType: JobType;
}

const JobConfig: React.FC<JobConfigProps> = ({ jobType }) => {
  if (jobType) {
    extendedFormSchema["jobType"].metadata.defaultValue = jobType;
  }
  const [isLoading, setLoading] = useState(false); 
  console.log("loading",isLoading);
  const [isAlert, setAlert] = useState(false);
  // const router = useRouter();
  const onSubmit = async (data: Record<string, any>) => {
    try {
      setLoading(true);
      // const jobConfigService: JobConfigService = new JobConfigServiceFactory(router).createJobConfigService(jobType);
      // await jobConfigService.createJob(data);

      handleAlert();

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  };
  const handleAlert = () => {
    setAlert(true);
    setTimeout(() => {
      setAlert(false);
    }, 1000);
  };
  return (
    <div className="h-full flex flex-col justify-center font-mono">
      <div className="w-full flex justify-center">
        <div className="md:w-1/2 w-full">
          <div className="text-center font-bold text-2xl my-10">
            Create New Job
          </div>
          <DynamicForm
            formName="Create Job Form"
            formSchema={extendedFormSchema}
            onSubmit={onSubmit}
            onSubmitText={"Create Job"}
            isLoading={isLoading}
          />
          {isAlert && (
            <div
              role="alert"
              className="alert alert-success absolute bottom-8 right-8 w-auto"
            >
              <span className="ml-2">Job Config created successfully!</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default JobConfig;
