import { Route, Routes } from "react-router-dom";
import Login from "../pages/auth/login";
import CreateTenant from "../pages/auth/create-tenant";
import ShopifyConnections from "../pages/integration/shopify";
import FacebookLogin from "../pages/integration/facebook";
import NextBestAction from "../pages/optimization/next-best-action";
import GeoLift from "../pages/measurement/geo-lift";
import BidOptimizer from "../pages/optimization/bid-optimizer";
import BidOptimizerDashboard from "../pages/optimization/bid-optimizer/id";
import MarketingMixModel from "../pages/measurement/marketing-mix-model";
import MMMDashboard from "../pages/measurement/marketing-mix-model/id";
import JobCreate from "../pages/job-create";
import DataSourceCreate from "../pages/data-source-create";
import Users from "../pages/users";
import CreateUser from "../pages/users/create-user";
import UpdateUser from "../pages/users/update-user";

import mixpanel from "mixpanel-browser";
import { jwtDecode } from "jwt-decode";
import PrivateRoute from "./PrivateRoute";
import NotFound from "../pages/NotFound";
import Dashboard from "../pages/dashboard";
import GeoLiftStudy from "../pages/measurement/geo-lift/components/GeoLiftStudy";

mixpanel.init("590857ca7f2572c75c7f1c443e749dfc", {
  debug: true,
  track_pageview: true,
  persistence: "localStorage",
});

if (localStorage.getItem("authToken") != null) {
  const decoded = jwtDecode(localStorage.getItem("authToken") as string);
  mixpanel.identify(decoded.sub);
  mixpanel.people.set(decoded);
}

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/auth/login" element={<Login />} />
      <Route element={<PrivateRoute />}>
        <Route path="/" element={<Dashboard />} />
        {/* <Route path="/dashboard/dashboard" element={<Dashboard />} /> */}
        <Route path="/auth/create-tenant" element={<CreateTenant />} />
        <Route path="/integrations/shopify" element={<ShopifyConnections />} />
        <Route path="/integrations/facebook" element={<FacebookLogin />} />
        <Route
          path="/optimization/next-best-action"
          element={<NextBestAction />}
        />
        <Route path="/measurement/geo-lift" element={<GeoLift />} />
        {/* <Route path="/measurement/geo-lift/:id" Component={GeoLiftDashboard} /> */}
        <Route path="/measurement/geo-lift/:id" Component={GeoLiftStudy} />

        <Route path="/optimization/bid-optimizer" element={<BidOptimizer />} />

        <Route
          path="/optimization/bid-optimizer/:id"
          Component={BidOptimizerDashboard}
        />

        <Route
          path="/planning/marketing-mix-model"
          element={<MarketingMixModel />}
        />

        <Route
          path="/planning/marketing-mix-model/:id"
          Component={MMMDashboard}
        />

        <Route path="/jobs/:jobType" element={<JobCreate />} />
        <Route
          path="/integrations/create/:dataSourceTypes"
          element={<DataSourceCreate />}
        />

        <Route path="/users" element={<Users />} />
        <Route path="/users/create-user" element={<CreateUser />} />
        <Route path="/users/update-user/:userId" element={<UpdateUser />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;
