import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";

interface SalesData {
  withoutCampaign: number;
  withCampaign: number;
}

interface SpendData {
  spend: number;
  sales: number;
}

interface DashboardChartsProps {
  salesData: SalesData;
  spendData: SpendData;
  showLegend?: boolean;
  height?: number;
  chartType?: "sales" | "metrics";
}

const formatCurrency = (value: number): string =>
  `$${Math.floor(value / 1000).toLocaleString()}k`;

const DashboardCharts: React.FC<DashboardChartsProps> = ({
  salesData,
  spendData,
  showLegend = true,
  height = 300,
  chartType = "sales",
}) => {
  const getData = () => {
    if (chartType === "sales") {
      return [
        {
          name: "Sales",
          WithoutCampaign: salesData.withoutCampaign,
          WithCampaign: salesData.withCampaign,
        },
      ];
    }
    return [
      {
        name: "Performance",
        Spend: spendData.spend,
        Sales: spendData.sales,
      },
    ];
  };

  return (
    <div className="space-y-4 p-4 bg-white  rounded-xl">
      <h3 className="text-lg font-semibold text-gray-700">
        {chartType === "sales" ? "Sales Comparison" : "ROAS"}
      </h3>
      <div style={{ height }}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={getData()}
            margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
          >
            <CartesianGrid vertical={false} stroke="#E5E7EB" />
            <XAxis dataKey="name" axisLine={false} tickLine={false} />
            <YAxis
              tickFormatter={formatCurrency}
              axisLine={false}
              tickLine={false}
              style={{fontSize:"12px"}}
            />
            <Tooltip
              formatter={(value: number) => formatCurrency(value)}
              contentStyle={{
                background: "white",
                border: "1px solid #E5E7EB",
                borderRadius: "8px",
              }}
            />
            {showLegend && <Legend />}
            <Bar
              dataKey={chartType === "sales" ? "WithoutCampaign" : "Spend"}
              fill="#9CA3AF"
              radius={[4, 4, 0, 0]}
              maxBarSize={60}
            />
            <Bar
              dataKey={chartType === "sales" ? "WithCampaign" : "Sales"}
              fill="#4CAF50"
              radius={[4, 4, 0, 0]}
              maxBarSize={60}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
      {/* <div className="flex justify-between text-gray-600 text-sm">
        <span>Total Ad Spend: {formatCurrency(spendData.spend)}</span>
        <span>
          Incremental Sales:{" "}
          <span className="text-green-600 font-semibold">
            +{((spendData.sales / spendData.spend) * 100).toFixed(0)}%
          </span>
        </span>
      </div> */}
    </div>
  );
};

export default DashboardCharts;
