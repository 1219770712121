import React from "react";

type LoaderProps = {
  size?: "small" | "medium" | "large";
  color?: "primary" | "secondary" | "white";
};

const sizeClasses = {
  small: "h-6 w-6 border-2",
  medium: "h-10 w-10 border-4",
  large: "h-16 w-16 border-4",
};

const colorClasses = {
  primary: "border-blue-500 border-t-transparent",
  secondary: "border-gray-500 border-t-transparent",
  white: "border-white border-t-transparent",
};

const Loader: React.FC<LoaderProps> = ({
  size = "medium",
  color = "primary",
}) => {
  return (
    <div className="flex items-center justify-center min-h-screen">
      <div
        className={`rounded-full animate-spin ${sizeClasses[size]} ${colorClasses[color]}`}
      ></div>
    </div>
  );
};

export default Loader;
