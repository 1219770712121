import { CalendarIcon, DollarSignIcon, TrendingUpIcon } from "lucide-react";
import React, { useEffect, useState } from "react";
import { Link} from "react-router-dom";
import {Avatar} from "antd";
import DashboardCharts from "./DashboardCharts";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../utils/axiosInstance";
import mixpanel from "mixpanel-browser";
import { Events } from "../constants/Event";
import { v4 as uuidv4 } from 'uuid';

interface ApiResponse {
  lift: {
    totalLiftPercentage: number;
    totalSalesWithCampaign: number;
    totalSalesWithoutCampaign: number;
  };
  roas: {
    totalROAS: number;
    totalAdSpendForSyntheticMarkets: number;
    totalIncrementSales: number;
  };
  graph: {
    totalLiftPercentage: number;
    totalSalesWithCampaign: number;
    totalSalesWithoutCampaign: number;
    salesWithCampaignData: number[];
    salesWithoutCampaignData: number[];
  };
}

interface GeoTest {
  title: string;
  subtitle?: string;
  date: string;
  status?: "in_progress" | "error" | "completed";
  action?: "VIEW_RESULTS" | "VIEW_CONTROLS";
  jobConfigId?: string;
}

interface LiftData {
  percentage: number;
  title: string;
  confidence: number;
  date: string;
  spend: number;
  sales: number;
}

interface SalesData {
  withoutCampaign: number;
  withCampaign: number;
  adSpend: number;
  incrementalSales: number;
}

interface JobRun {
  jobRunId: string;
  tenantId: string;
  jobConfigId: string;
  jobType: string;
  jobStatus: string;
  jobRunCount: number;
  jobRunTimeout: number;
  jobExecutionTime: string;
  createdDate: string;
  modifiedDate: string;
}

interface GeoBasedIncrementilityProps {
  geoTests: GeoTest[];
  liftData?: LiftData;
  salesData?: SalesData;
  totalLiftPercentage?: number;
  jobRuns: JobRun[];
}

const GeoBasedIncrementility: React.FC<GeoBasedIncrementilityProps> = ({
  geoTests,
  liftData,
  salesData: initialSalesData,
  totalLiftPercentage,
  jobRuns,
}) => {
  const navigate = useNavigate();
  const [salesData, setSalesData] = useState<SalesData | undefined>(
    initialSalesData
  );

  useEffect(() => {
    const fetchSalesData = async () => {
      try {
        // TODO: Replace with actual API endpoint when ready
        const response = await fetch("YOUR_API_ENDPOINT");
        const data: ApiResponse = await response.json();

        // Transform API data to match SalesData interface
        const transformedData: SalesData = {
          withoutCampaign: data.lift.totalSalesWithoutCampaign,
          withCampaign: data.lift.totalSalesWithCampaign,
          adSpend: data.roas.totalAdSpendForSyntheticMarkets,
          incrementalSales: data.lift.totalLiftPercentage * 100, // Convert to percentage
        };

        setSalesData(transformedData);
      } catch (error) {
        console.error("Error fetching sales data:", error);
        // You might want to handle the error state here
      }
    };

    fetchSalesData();
  }, []);

  const handleViewResults = (test: GeoTest) => {
    if (test.jobConfigId) {
      const encodedName = encodeURIComponent(test.title);
      navigate(`/measurement/geo-lift/${test.jobConfigId}?name=${encodedName}`);
    }
  };
  const onExecuteClick = async (jobConfigId: string) => {
    await axiosInstance.post(
      "/api/jobRun",
      {
        jobConfigId,
      },
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("authToken"),
        },
      }
    );
    mixpanel.track(Events.BUTTON_CLICK, {
      jobType: "GEO_LIFT",
      action: "Execute",
      jobConfigId,
    });
    window.location.reload();
  };

  const getJobStatus = (jobConfigId: string) => {
    const jobRun = jobRuns.find((run) => run.jobConfigId === jobConfigId);
    // console.log(jobRuns);
    return jobRun;
  };

  const renderActionButton = (test: GeoTest) => {
    const jobStatus = getJobStatus(test.jobConfigId || "");
    if (jobStatus?.jobStatus === "RUNNING") {
      return (
        <button className="text-white text-xs px-3 py-1 rounded w-full sm:w-auto bg-yellow-500 hover:bg-yellow-600">
          IN PROGRESS
        </button>
      );
    } else if (!jobStatus) {
      return (
        <button
          onClick={() => onExecuteClick(test.jobConfigId || "")}
          className="text-white text-xs px-3 py-1 rounded w-full sm:w-auto bg-green-500 hover:bg-green-600"
        >
          Execute
        </button>
      );
    } else {
      return (
        <button
          onClick={() => handleViewResults(test)}
          className="text-white text-xs px-3 py-1 rounded w-full sm:w-auto bg-blue-500 hover:bg-blue-600"
        >
          VIEW RESULTS
        </button>
      );
    }
  };

  return (
    <div className="p-4 md:p-6 grid grid-cols-1 md:grid-cols-12 gap-4 md:gap-6 bg-gray-100 min-h-screen">
      {/* Geo-Based Incrementality */}
      <div className="col-span-1 md:col-span-4 bg-white p-4 rounded-xl shadow-md">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold text-gray-700">
            Geo-Based Incrementality
          </h3>
          <button
            onClick={() => navigate("/measurement/geo-lift")}
            className="text-blue-500 text-sm hover:text-blue-600"
          >
            View All
          </button>
        </div>
        <p className="text-sm text-gray-600 mb-4">
          Test and Learn + ROI Measurement
        </p>
        <div className="space-y-4">
          {geoTests.map((test, index) => (
            <div
              key={index}
              className="flex flex-col sm:flex-row items-start sm:items-center justify-between border-b pb-3 gap-2"
            >
              <div>
                {test.subtitle && (
                  <p className="text-sm text-gray-600">{test.subtitle}</p>
                )}
                <p className="text-sm font-medium">{test.title}</p>
                <div className="flex items-center text-xs text-gray-400 mt-1">
                  <CalendarIcon className="w-3 h-3 mr-1 flex-shrink-0" />
                  <span>{test.date}</span>
                </div>
              </div>
              {renderActionButton(test)}
            </div>
          ))}
          <Link to={`/measurement/geo-lift/${uuidv4()}`}>
          <button
            onClick={() => navigate("/measurement/geo-lift/${uuidv4()}")}
            className="w-full bg-blue-500 text-white py-2 rounded text-sm font-medium hover:bg-blue-600 mt-4"
          >
            CREATE NEW TEST
          </button>
          </Link>

        </div>
      </div>

      {/* Latest Lift */}
      <div className="col-span-1 md:col-span-3 space-y-4">
        <div className="bg-white p-4 rounded-xl shadow-md">
          <h3 className="text-lg text-center font-semibold text-gray-700 mb-6">
            Latest Lift
          </h3>
          {liftData && (
            <>
              <div className="text-center">
                <p className="text-6xl font-bold text-green-500 mb-4">
                  + {totalLiftPercentage}%
                </p>
                <p className="text-gray-700 mb-2">{liftData.title}</p>
                <p className="text-gray-600 mb-4">
                  Confidence &gt;{liftData.confidence}%
                </p>
                <div className="flex items-center justify-center text-sm text-gray-400">
                  <CalendarIcon className="w-4 h-4 mr-1" />
                  <span>{liftData.date}</span>
                </div>
              </div>
            </>
          )}
        </div>

        {/* Metrics Chart */}
        <div className="bg-white p-4 rounded-xl shadow-md">
          <DashboardCharts
            spendData={{
              spend: salesData?.adSpend || 0,
              sales: salesData?.withCampaign || 0,
            }}
            salesData={{
              withCampaign: salesData?.withCampaign || 0,
              withoutCampaign: salesData?.adSpend || 0,
            }}
            height={200}
            showLegend={false}
            chartType="metrics"
          />
          <div className="grid grid-cols-2 gap-4">
            <div className="flex items-center gap-2">
              <div className="p-2 bg-purple-50 rounded-lg">
                <TrendingUpIcon className="w-5 h-5 text-purple-500" />
              </div>
              <div>
                <p className="text-lg font-semibold">
                  ${salesData && Math.floor(salesData?.adSpend / 1000)}k
                </p>
                <p className="text-sm text-gray-500">Spend</p>
              </div>
            </div>
            <div className="flex items-center gap-2">
              <div className="p-2 bg-green-50 rounded-lg">
                <DollarSignIcon className="w-5 h-5 text-green-500" />
              </div>
              <div>
                <p className="text-lg font-semibold">
                  ${salesData && Math.floor(salesData?.withCampaign / 1000)}k
                </p>
                <p className="text-sm text-gray-500">Sales</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Sales Performance */}
      <div className="col-span-1 md:col-span-5 bg-white p-4 rounded-xl shadow-md">
        <DashboardCharts
          salesData={{
            withCampaign: salesData?.withCampaign || 0,
            withoutCampaign: salesData?.withoutCampaign || 0,
          }}
          spendData={{
            spend: 0,
            sales: 0,
          }}
          height={300}
          showLegend={false}
          chartType="sales"
        />

        <div className="space-y-4">
          <div className="flex items-center justify-between p-3 bg-gray-50 rounded-lg">
            <div className="flex items-center gap-2">
            <Avatar shape="square" size="large" />
              <DollarSignIcon className="w-5 h-5 text-gray-600" />
              <span className="text-gray-600">Sales without Campaign</span>
            </div>
            <span className="font-semibold">
              ${(salesData?.withoutCampaign || 0).toLocaleString()}
            </span>
          </div>

          <div className="flex items-center justify-between p-3 bg-gray-50 rounded-lg">
            <div className="flex items-center gap-2">
            <Avatar shape="square" size="large" style={{ backgroundColor: '#52c41a', color: '#fff' }} />
              <DollarSignIcon className="w-5 h-5 text-gray-600" />
              <span className="text-gray-600">Sales with Campaign</span>
            </div>
            <span className="font-semibold">
              ${(salesData?.withCampaign || 0).toLocaleString()}
            </span>
          </div>

          <div className="flex items-center justify-between p-3 bg-gray-50 rounded-lg">
            <div className="flex items-center gap-2">
              <TrendingUpIcon className="w-5 h-5 text-green-500" />
              <span className="text-gray-600">
                Incremental Sales per Ad Spend
              </span>
            </div>
            <span className="font-semibold text-green-500">
              +{salesData?.incrementalSales}%
            </span>
          </div>

          <button className="w-full bg-blue-500 hover:bg-blue-600 text-white py-2 rounded-lg font-medium">
            VIEW MARKETS
          </button>
        </div>
      </div>
    </div>
  );
};

export default GeoBasedIncrementility;
