import React from "react";
import { useParams } from "react-router-dom";
import { DataSourceTypes } from "../constants/DataSourceTypes";
import { DataSourceConfigComponentFactory } from "../components/dataSourceConfigComponentFactory/DataSourceConfigComponentFactory";

const DataSourceCreate: React.FC<{}> = () => {
  const { dataSourceTypes } = useParams<{ dataSourceTypes: DataSourceTypes }>();
  const dataSourceConfigComponentFactory = new DataSourceConfigComponentFactory();
  return dataSourceConfigComponentFactory.createDataSourceConfigComponent(
    dataSourceTypes as DataSourceTypes
  );
};

export default DataSourceCreate;
