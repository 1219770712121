import { JobConfig } from "../../models/interfaces";
import axiosInstance from "../../utils/axiosInstance";
import {JobConfigService} from "./JobConfigServiceFactory";
import {v4 as uuidv4} from 'uuid';

export class GeoLiftJobConfigService {

    async uploadFile(file: File, path: string) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('path', path);
        await axiosInstance.post('/api/s3/upload', formData);
    }

    async getFile(path: string) {
        const response = await axiosInstance.get(`/api/s3/getFile${path ? `?path=${path}` : ''}`);
        return response.data;
    }

    async createJobConfig(jobConfigId: string, data: JobConfig) {

        const jobConfig = {
            
            jobConfigId: jobConfigId,
            name: data.name,
            status: data.status,
            jobType: 'GEO_LIFT',
            dataSourceConfig: {
                dataSourceType: 'FILE_CSV',
                dataSourceConfigMap: {
                    geoLiftPreTestFile: `${jobConfigId}/input/geo_lift_data.csv`,
                },
                url: ''
            },
            maxJobRunCount: 3,
            modelParameterConfig: {
                parameters: {
                    isNationalBuy: data?.modelParameterConfig?.parameters?.isNationalBuy,
                    includeMarket: data?.modelParameterConfig?.parameters?.includeMarket
                },
                confidence: data?.modelParameterConfig?.confidence
            },
        };

        try {
            await axiosInstance.post('/api/jobConfig', jobConfig);
        } catch (error: any) {
            // if (error.response && error.response.status === 401) {
            //     this.router.push('/auth/login?logout=true');
            // }
        }
    }

    async getJobConfig(jobConfigId: string) {
        const response = await axiosInstance.get(`/api/jobConfig/${jobConfigId}`);
        return response.data;
    }

    async updateJobConfig(jobConfigId: string, data: any) {
        const response = await axiosInstance.put(`/api/jobConfig/${jobConfigId}`, data);
        return response.data;
    }

}
