import React from "react";
import ReactECharts from "echarts-for-react";
import { EChartsOption, SeriesOption } from "echarts";

export interface SalesDecompositionData {
  Base: string;
  [key: string]: string; // Allow for dynamic keys
}

interface GradientAreaChartProps {
  data: SalesDecompositionData[];
  labels: string[];
}

const GradientStackedAreaChart: React.FC<GradientAreaChartProps> = ({
  data,
  labels,
}) => {
  // Convert string data to numbers for charting
  const convertToNumber = (value: string) => parseFloat(value);

  // Extract unique keys from the data (excluding "Base")
  const keys = Object.keys(data[0]).filter((key) => key !== "Base");

  // Function to get a color from our predefined palette
  const getSeriesColor = (index: number) => {
    const colors = [
      "#143969", // Primary adrsta-blue
      "#2E5C94", // Lighter shade of adrsta-blue
      "#4B7FBF", // Even lighter shade of adrsta-blue
      "#6FA2EA", // Lightest shade of adrsta-blue
      "#93C5FF", // Very light blue
      "#1F4D8A", // Darker shade of adrsta-blue
      "#0A2847", // Darkest shade of adrsta-blue
      "#3D5A80", // Muted blue
      "#98C1D9", // Pale blue
      "#294C71", // Deep blue
      "#5B7BA6", // Steel blue
      "#7895B2"  // Gray blue
    ];
    return colors[index % colors.length];
  };

  // Extract data for each series dynamically
  const seriesData: SeriesOption[] = keys.map((key, index) => ({
    name: key.replace(/_/g, " ").split(" ") // Split by spaces into words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
      .join(" "), // Replace underscores with spaces for display
    type: "line",
    stack: "total",
    emphasis: {
      focus: "series",
    },
    data: data.map((item) => convertToNumber(item[key])),
  }));

  // Add "Base" series at the bottom of the stack
  seriesData.unshift({
    name: "Base",
    type: "line",
    stack: "total",
    emphasis: {
      focus: "series",
    },
    data: data.map((item) => convertToNumber(item.Base)),
  });

  // Function to format numbers to K for thousands, M for millions
  const formatNumber = (value: number) => {
    if (value >= 1000000) {
      return (value / 1000000).toFixed(1) + "M";
    } else if (value >= 1000) {
      return (value / 1000).toFixed(1) + "K";
    } else {
      return value.toString();
    }
  };

  const option: EChartsOption = {
    color: [
      "#143969", // Primary adrsta-blue
      "#2E5C94", // Lighter shade of adrsta-blue
      "#4B7FBF", // Even lighter shade of adrsta-blue
      "#6FA2EA", // Lightest shade of adrsta-blue
      "#93C5FF", // Very light blue
      "#1F4D8A", // Darker shade of adrsta-blue
      "#0A2847", // Darkest shade of adrsta-blue
      "#3D5A80", // Muted blue
      "#98C1D9", // Pale blue
      "#294C71", // Deep blue
      "#5B7BA6", // Steel blue
      "#7895B2"  // Gray blue
    ],
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
        label: {
          backgroundColor: "#6a7985",
        },
      },
      formatter: (params: any) => {
        const result = params.map((param: any) => {
          const value = formatNumber(Number(param.value)); // Apply number formatting
          return `${param.seriesName}: ${value}`;
        });
        return `${params[0].axisValue}<br/>${result.join("<br/>")}`;
      },
    },
    legend: {
      data: ["Base", ...keys.map((key) => key.replace(/_/g, " ").split(" ") // Split by spaces into words
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
        .join(" "))],
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: [
      {
        type: "category",
        boundaryGap: false,
        data: labels,
      },
    ],
    yAxis: [
      {
        type: "value",
        axisLabel: {
          formatter: (value: number) => formatNumber(value), // Apply formatting to y-axis values
        },
      },
    ],
    series: seriesData.map((series, index) => ({
      ...series,
      lineStyle: {
        color: getSeriesColor(index),
        width: 2
      },
      areaStyle: {
        color: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            { 
              offset: 0, 
              color: series.name === "Base" ? "#E5E7EB" : getSeriesColor(index)
            },
            { 
              offset: 1, 
              color: series.name === "Base" ? "#E5E7EB00" : `${getSeriesColor(index)}00`
            }
          ]
        },
        opacity: 0.8
      }
    }))
  };

  return (
    <ReactECharts option={option} style={{ height: "400px", width: "100%" }} />
  );
};

export default GradientStackedAreaChart;
