import { jwtDecode } from 'jwt-decode';

interface JWTPayload {
  sub: string;
  // Add other JWT claims if needed
}

export const getTenantId = (): string => {
  const token = localStorage.getItem('authToken');
  if (!token) return '';
  
  try {
    const decoded = jwtDecode<JWTPayload>(token);
    return decoded.sub;
  } catch (error) {
    console.error('Error decoding JWT:', error);
    return '';
  }
};
