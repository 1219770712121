import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import AppLayout from "../components/Layout/AppLayout";

const PrivateRoute: React.FC = () => {
  const navigate = useNavigate();
  const isLogged: string | null = localStorage.getItem("authToken");

  useEffect(() => {
    if (!isLogged) {
      navigate("/auth/login?logout=true");
    }
  }, [isLogged, navigate]);

  return (
    <AppLayout>
      <Outlet />
    </AppLayout>
  );
};

export default PrivateRoute;
