import React from "react";

export interface AlertProps {
  message: string;
  type: "success" | "error" | "warning" | "info" | "normal";
  callback: () => void;
}

const Alert: React.FC<AlertProps> = ({ message, type, callback }) => {
  setTimeout(() => {
    callback();
  }, 3000);
  const getCSSClassesByType = (type: AlertProps["type"]) => {
    switch (type) {
      case "error":
        return "alert-error";
      case "success":
        return "alert-success";
      case "warning":
        return "alert-warning";
      case "info":
        return "alert-info";
      case "normal":
        return "";
    }
  };
  return (
    <div
      role="alert"
      className={`alert ${getCSSClassesByType(
        type
      )} fixed bottom-8 right-8 w-auto shadow-xl`}
    >
      <span className="ml-2">{message}</span>
    </div>
  );
};

export default Alert;
