import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { Spin, Typography } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Award } from "lucide-react";
import { ViewResultsProps } from "../../../../models/interfaces";
import axiosInstance from "../../../../utils/axiosInstance";
import outPutDataJson from "../../../../dummy/output.json";
import jobIdData from "../../../../dummy/jobIdData.json";
import inputDataJson2 from "../../../../dummy/inputData.json";
import LineChart, {
  LineChartProps,
  LineData,
} from "../../../../components/LineChart";
import PieChartECharts from "../../../../components/PieChart";
import WaterFallChart from "../../../../components/WaterFallChart";
import HeatMap from "../../../../components/HeatMap";
import MultiBarLineChartECharts, {
  MultiBarLineChartProps,
} from "../../../../components/BarChart";
import GradientStackedAreaChart from "../../../../components/GradientStackedAreaChart";
import Table, { TableColumn, TableRecord } from "../../../../components/Table";
import Maximize from "../../../../components/Maximize";
import {
  CONTRIBUTION_PERC_API,
  MMM_INPUT_DATA,
  OPTIMIZE_SPEND_API,
  MMM_CHAT_API,
} from "../../../../constants/API";
import { getTenantId } from "../../../../utils/auth";

interface LiftData {
  percentage: number;
  title: string;
  confidence: number;
  date: string;
  spend: number;
  sales: number;
}

const ViewResults: React.FC<ViewResultsProps> = ({ jobId }) => {
  const inputDataJson: any = inputDataJson2;
  const [liftData, setLiftData] = useState<LiftData | undefined>({
    percentage: 4.1,
    title: "Geo lift for Adrsta",
    confidence: 60,
    date: "21 Jul | 08:20-10:30",
    spend: 20000,
    sales: 68000,
  });
  console.log(jobId, "jobId");
  const { Title, Paragraph } = Typography;
  const normalizeData = (data: any) => {
    console.log(data, "adajhakj");
    const min = Math.min(...data);
    const max = Math.max(...data);
    return data.map((value: any) => (value - min) / (max - min));
  };
  // State variables from index.tsx
  const [contributionChartData, setContributionChartData] = useState([]);
  const [contributionChartLabel, setContributionChartLabel] = useState([]);
  const [saturationCharts, setSaturationCharts] = useState([]);
  const [yearlyOptimizedSpendChartData, setYearlyOptimizedSpendChartData] =
    useState([]);
  const [yearlyOptimizedSpendChartLabel, setYearlyOptimizedSpendChartLabel] =
    useState([]);
  const [mediaYOYSpentChart, setMediaYOYSpentChart] = useState<any>(undefined);
  const [roiFinalData, setRoiFinalData] = useState<any[]>([]);
  const [roiLabels, setRoiLabels] = useState<string[]>([]);
  const [actualPredictedLabels, setActualPredictedLabels] = useState([]);
  const [actualSales, setActualSales] = useState([]);
  const [predictedSales, setPredictedSales] = useState([]);
  const [y1, setY1] = useState(normalizeData(inputDataJson.y1));
  const [y2, setY2] = useState(normalizeData(inputDataJson.y2));
  const [correlationLabels, setCorrelationLabels] = useState(
    inputDataJson.date
  );
  const [dateVariables, setDateVariables] = useState<string[]>([]);
  const [mediaVariables, setMediaVariables] = useState<string[]>([]);
  const [targetVariables, setTargetVariables] = useState<string[]>([]);
  const [marketLocations, setMarketLocations] = useState<string[]>([]);
  const [selectedMarket, setSelectedMarket] = useState<string>("");
  const [waterFallChartLabel, setWaterFallChartLabel] = useState(undefined);
  const [waterFallChartData, setWaterFallChartData] = useState(undefined);
  const [salesDecompositionLabel, setSalesDecompositionLabel] = useState([]);
  const [salesDecompositionData, setSalesDecompositionData] = useState([]);
  const [historicSpendContributionLabel, setHistoricSpendContributionLabel] =
    useState<any>([]);
  const [historicSpendContributionData, setHistoricSpendContributionData] =
    useState<any>([]);
  const [effectShareLabel, setEffectShareLabel] = useState<string[]>([]);
  const [effectShareData, setEffectShareData] = useState<number[]>([]);
  const [elasticityColumn, setElasticityColumn] = useState<TableColumn[]>([]);
  const [elasticityRecord, setElasticityRecord] = useState<TableRecord[]>([]);
  const [seasonDataSet, setSeasonDataSet] = useState<LineData[]>([]);
  const [trendDataSet, setTrendDataSet] = useState<LineData[]>([]);
  const [seasonLabel, setSeasonLabel] = useState([]);
  const [modelAccuracy, setModelAccuracy] = useState([]);
  const [heatMapData, setHeatMapData] = useState<any>(undefined);
  const [similarityHeatMapData, setSimilarityHeatMapData] =
    useState<any>(undefined);
  const [adstockData, setAdstockData] = useState<any>(undefined);
  const [multiColinearityRecords, setMultiColinearityRecords] = useState<
    TableRecord[]
  >([]);
  const [isLoading, setLoading] = useState(true);
  const [showChartLabels, setShowChartLabels] = useState(false);
  const [jobConfig, setJobConfig] = useState<any>(null);

  // Helper function from index.tsx
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 5000);

    return () => clearTimeout(timer); // Cleanup function to clear timeout if component unmounts
  }, []);
  const generateSaturationCharts = (response: any) => {
    const colors = [
      "rgba(75, 192, 192, 1)",
      "rgba(255, 99, 132, 1)",
      "rgba(54, 162, 235, 1)",
      "rgba(255, 206, 86, 1)",
      "rgba(153, 102, 255, 1)",
      "rgba(255, 159, 64, 1)",
    ];

    const charts: any[] = [];
    const allKeys = Object.keys(response);

    allKeys.forEach((key, index) => {
      if (key.startsWith("Saturation_")) {
        const data = response[key];
        const datasetName = key
          .replaceAll("Saturation_", "")
          .split("_")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ")
          .replaceAll(/_/g, " ");
        const color = colors[index % colors.length];
        const labels = data.map((item: any) => item.Spends);
        const lineData = data.map((item: any) => {
          return item[key.replaceAll("Saturation_", "")];
        });
        const chartProps: LineChartProps = {
          labels,
          dataSets: [
            {
              name: datasetName,
              data: lineData,
              borderColor: color,
              backgroundColor: color.replaceAll("1)", "0.3)"),
            },
          ],
          xAxisTitle: "Spends",
          yAxisTitle: datasetName,
        };

        charts.push(
          <div
            key={`${key}-${index}`}
            className=""
            // style={{ width: "calc(50% - 8px)" }}
          >
            <div className="w-full">
              <Maximize title={datasetName}>
                <LineChart key={key} {...chartProps} />
              </Maximize>
            </div>
          </div>
        );
      }
    });

    return charts;
  };

  useEffect(() => {
    // Fetch job config
    // axiosInstance.get(`/api/jobConfig/${jobId}`).then((response) => {
    // const response = jobIdData;
    let response: any = jobIdData;
    const mediaVariables =
      response.dataMappingConfig.mappings.mmm.columnMapping.media_variables;
    const targetVariables =
      response.dataMappingConfig.mappings.mmm.columnMapping.target_variables;
    const dateVariables =
      response.dataMappingConfig.mappings.mmm.columnMapping.date_variables;
    const locations =
      response.dataMappingConfig.mappings.mmm.columnMapping.market_locations ||
      [];

    setMediaVariables(mediaVariables);
    setTargetVariables(targetVariables);
    setDateVariables(dateVariables);
    setMarketLocations(locations);
    setJobConfig(response.data);

    if (locations && locations.length > 0) {
      setSelectedMarket(locations[0]);
    }

    // Fetch contribution data
    // axiosInstance
    //   .post(CONTRIBUTION_PERC_API, {
    //     spendColumns: mediaVariables,
    //     jobConfigId: jobId,
    //     tenantId: getTenantId(),
    //   })
    //   .then((response) => {
    //     const contributionData: Record<string, any> = {
    //       totalContribution: {
    //         Print_Spend: 100.0,
    //       },
    //     };
    //     const labels: string[] = Object.keys(contributionData);
    //     const data: string[] = Object.values(contributionData);
    //     setHistoricSpendContributionLabel(labels);
    //     setHistoricSpendContributionData(data);
    //   });
    const contributionData: Record<string, any> = {
      totalContribution: {
        Print_Spend: 100.0,
      },
    };
    const labels: string[] = Object.keys(contributionData);
    const data: string[] = Object.values(contributionData);
    setHistoricSpendContributionLabel(labels);
    setHistoricSpendContributionData(data);
    // Fetch MMM output data
    // axiosInstance.get(`/api/mmm/output/${jobId}`).then((response) => {
    let outputJson: any = outPutDataJson.result;
    if (typeof outputJson === "string") {
      try {
        outputJson = JSON.parse(outputJson);
      } catch (error) {
        console.error("Failed to parse JSON string:", error);
      }
    }

    // Process all the data from outputJson
    if (outputJson.ROI_Final) {
      setRoiFinalData(outputJson.ROI_Final);
      setRoiLabels(outputJson.ROI_Final.map((item: any) => item.MediaChannel));
    }

    setModelAccuracy(outputJson["ModelAccuracy"]);
    const seasonalityData = outputJson["Seasonality"];

    if (seasonalityData) {
      const labels = seasonalityData.map((item: any) => item[dateVariables[0]]);
      const seasonData = seasonalityData.map((item: any) =>
        parseFloat(item.Season.toFixed(2))
      );
      const trendData = seasonalityData.map((item: any) =>
        parseFloat(item.Trend.toFixed(2))
      );

      setSeasonLabel(labels);
      setSeasonDataSet([
        {
          name: "Season",
          data: seasonData,
          borderColor: "rgba(75, 192, 192, 1)",
          backgroundColor: "rgba(75, 192, 192, 0.3)",
        },
      ]);
      setTrendDataSet([
        {
          name: "Trend",
          data: trendData,
          borderColor: "rgba(255, 99, 132, 1)",
          backgroundColor: "rgba(255, 99, 132, 0.3)",
        },
      ]);
    }

    // Process YoY Sales Contribution chart
    const yoySpentData = outputJson["YoY_Sales_Contribution_chart"];
    if (yoySpentData) {
      const colorPalette = [
        "#FFC300",
        "#FF5733",
        "#C70039",
        "#900C3F",
        "#581845",
        "#1F618D",
        "#117A65",
        "#F39C12",
      ];

      const barDatasets = Object.keys(yoySpentData[0])
        .filter((key) =>
          [...mediaVariables, "Base", "Seasonality"].includes(key)
        )
        .map((key, index) => ({
          name: key.replace(/_/g, " "),
          values: yoySpentData.map((item: any) => Math.round(item[key])),
          color: colorPalette[index % colorPalette.length],
        }));

      const labels = yoySpentData.map((item: any) => item.Year.toString());
      const chartProps: MultiBarLineChartProps = {
        labels,
        barDatasets,
        lineDatasets: [],
      };
      setMediaYOYSpentChart(chartProps);
    }

    // Process correlation matrix
    const correlationMatrix = outputJson["CorrelationMatrix"];
    if (correlationMatrix) {
      const targetVariableKey = targetVariables[0];
      const xLabels = Object.keys(correlationMatrix[0]).sort((a, b) => {
        if (a === targetVariableKey) return -1;
        if (b === targetVariableKey) return 1;
        return a.localeCompare(b);
      });
      const yLabels = xLabels;

      const sortedMatrix = xLabels.map((label) => {
        return correlationMatrix.find(
          (row: { [x: string]: number }) => row[label] === 1
        );
      });

      const zData = sortedMatrix.map((row: any) => xLabels.map((x) => row[x]));

      setHeatMapData({
        xLabels,
        yLabels,
        zData,
      });
    }

    // Process similarity matrix
    const similarityMatrix = outputJson["Similarity"];
    if (similarityMatrix) {
      const targetVariableKey = targetVariables[0];
      const xLabels = Object.keys(similarityMatrix[0]).sort((a, b) => {
        if (a === targetVariableKey) return -1;
        if (b === targetVariableKey) return 1;
        return a.localeCompare(b);
      });
      const yLabels = xLabels;

      const zData = similarityMatrix.map((row: any) =>
        xLabels.map((x) => row[x])
      );

      setSimilarityHeatMapData({
        xLabels,
        yLabels,
        zData,
      });
    }

    // Process adstock data
    const adstock = outputJson["adstock"];
    if (adstock) {
      const groupedData = adstock.reduce((acc: any, item: any) => {
        const parts = item.Var_Name.split("_");
        const prefix = parts.slice(0, -1).join("_");
        const metric = parts[parts.length - 1];

        if (!acc[prefix]) {
          acc[prefix] = { alpha: 0, gamma: 0, strength: 0, length: 0 };
        }

        acc[prefix][metric] = item.Adstock_Value;
        return acc;
      }, {});

      const labels = Object.keys(groupedData);
      const barDatasets = [
        {
          name: "Strength",
          values: labels.map((label) => groupedData[label]?.strength || 0),
          color: "#73C2FB",
        },
        {
          name: "Length",
          values: labels.map((label) => groupedData[label]?.length || 0),
          color: "#32CD32",
        },
      ];

      const chartProps: any = {
        labels,
        barDatasets,
        lineDatasets: [],
      };
      setAdstockData(chartProps);
    }

    // Process VIF factors
    if (outputJson["VifFactors"]) {
      const records: TableRecord[] = [];
      outputJson["VifFactors"]?.map(
        (item: { Variable: string; VIF: number }) => {
          records.push({
            variable: item.Variable.split("_")
              ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" "),
            value: item.VIF,
          });
        }
      );
      setMultiColinearityRecords(records);
    }

    // Process other data
    setSalesDecompositionData(outputJson["Sales_Decomposition"]);
    setSalesDecompositionLabel(
      outputJson["Sales_Decomposition"]?.map((item: any, index: any) => index)
    );

    const charts = generateSaturationCharts(outputJson);
    setSaturationCharts(charts as any);

    setContributionChartData(
      outputJson["Final_Optimizer"]?.map((item: any) =>
        Number.parseFloat(item.Contribution)
      )
    );
    setContributionChartLabel(
      outputJson["Final_Optimizer"]?.map((item: any) => item.Media)
    );

    setYearlyOptimizedSpendChartData(
      outputJson["Final_Optimizer"]?.map((item: any) =>
        Number.parseFloat(item.Yearly_Optimize_Spend)
      )
    );
    setYearlyOptimizedSpendChartLabel(
      outputJson["Final_Optimizer"]?.map((item: any) => item.Media)
    );

    if (outputJson["Contributions_chart"]) {
      const effectData = outputJson["Contributions_chart"]?.filter(
        (item: any) =>
          item.Variables !== "Base" && item.Variables !== "Seasonality"
      );
      const labels = effectData?.map((item: any) => item.Variables);
      const values = effectData?.map((item: any) => Number(item.Contributions));

      setEffectShareLabel(labels);
      setEffectShareData(values);
    }

    setActualPredictedLabels(
      outputJson["Actual_vs_predicted"]
        ?.map((item: any) => {
          const dateKey = Object.keys(item)?.find(
            (key) => key.trim() === "Date"
          );
          if (dateKey) {
            return item[dateKey];
          }
          return null;
        })
        .filter((date: any) => date !== null)
    );
    setActualSales(
      outputJson["Actual_vs_predicted"]?.map(
        (item: any) => item[targetVariables[0]]
      )
    );
    setPredictedSales(
      outputJson["Actual_vs_predicted"]?.map((item: any) => item.prediction)
    );

    const sortedContributionsChart = outputJson.Contributions_chart?.sort(
      (a: any, b: any) => {
        const isAImportant =
          a.Variables === "Seasonality" || a.Variables === "Base";
        const isBImportant =
          b.Variables === "Seasonality" || b.Variables === "Base";

        if (isAImportant && isBImportant) {
          return b.Contributions - a.Contributions;
        }

        if (isAImportant) return -1;
        if (isBImportant) return 1;

        return 0;
      }
    );

    setWaterFallChartLabel(
      sortedContributionsChart?.map((item: any) => item.Variables)
    );
    setWaterFallChartData(
      sortedContributionsChart?.map((item: any) =>
        Number.parseInt(item.Contributions)
      )
    );

    setElasticityColumn(
      Object.keys(outputJson?.Elasticity_Report[0])
        .sort((a, b) => {
          if (a === "MediaChannel") return -1;
          if (b === "MediaChannel") return 1;
          return 0;
        })
        .map((key) => ({
          key: key.trim(),
          label: key.trim().replaceAll("_", " "),
        }))
    );
    setElasticityRecord(
      outputJson.Elasticity_Report?.map((item: any) => {
        const record: TableRecord = {};
        Object.keys(item).forEach((key) => {
          const formattedKey = key
            .trim()
            .split("_")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");

          let formattedValue = item[key];
          if (typeof formattedValue === "string") {
            formattedValue = formattedValue
              .trim()
              .split("_")
              .map(
                (word: string) => word.charAt(0).toUpperCase() + word.slice(1)
              )
              .join(" ");
          }

          record[formattedKey] = formattedValue;
        });
        return record;
      })
    );
    // });
    // });
  }, [jobId]);

  const onCorrelationMediaVariableChange = (data: any) => {
    fetchCorrelationData(
      data.target.value,
      targetVariables[0],
      dateVariables[0]
    );
  };

  const onMarketLocationChange = (data: any) => {
    setSelectedMarket(data.target.value);
  };

  const fetchCorrelationData = (y1: string, y2: string, x: string) => {
    axiosInstance
      .post(MMM_INPUT_DATA, {
        jobConfigId: jobId,
        tenantId: getTenantId(),
        y1,
        y2,
        x,
      })
      .then((response) => {
        setCorrelationLabels(response.data["date"]);
        setY1(normalizeData(response.data["y1"]));
        setY2(normalizeData(response.data["y2"]));
      });
  };

  const correlationChart: LineChartProps = {
    labels: correlationLabels,
    dataSets: [
      {
        name: "Media Variable",
        data: y1,
        borderColor: "#ff0000",
        backgroundColor: "rgba(255, 0, 0, 0.3)",
      },
      {
        name: "Target Variable",
        data: y2,
        borderColor: "#0000ff",
        backgroundColor: "rgba(0, 0, 255, 0.3)",
      },
    ],
    xAxisTitle: "Days",
    yAxisTitle: "Sales",
  };

  const twoLineChartData: LineChartProps = {
    labels: actualPredictedLabels,
    dataSets: [
      {
        name: "Actual Sales",
        data: actualSales,
        borderColor: "#ff0000",
        backgroundColor: "rgba(255, 0, 0, 0.3)",
      },
      {
        name: "Predicted Sales",
        data: predictedSales,
        borderColor: "#0000ff",
        backgroundColor: "rgba(0, 0, 255, 0.3)",
      },
    ],
    xAxisTitle: "Days",
    yAxisTitle: "Sales",
  };

  return (
    <div className="px-8 py-2 flex-col flex justify-center">
      {isLoading ? (
        <div className="flex flex-col items-center justify-center p-4">
          <div className="w-full max-w-md text-center space-y-6">
            <Spin
              indicator={
                <LoadingOutlined
                  style={{
                    fontSize: 72,
                    color: "#1890ff",
                  }}
                  spin
                />
              }
              size="large"
            />
            <Title level={3} className="mt-4">
              AI Analysis in Progress
            </Title>
            <Paragraph>
              AI is currently analyzing your data. This process may take
              approximately 3 to 5 Seconds.
            </Paragraph>
            <div className="mt-4">
              <Paragraph type="warning">
                ⏳ Estimated Time: 3 to 5 Seconds.
              </Paragraph>
            </div>
          </div>
        </div>
      ) : (
        <div className="mx-8 w-full">
          <div className="bg-green-50 border border-green-200 rounded-lg p-4 flex items-center">
            <Award className="text-green-500 mr-3" size={24} />
            <div>
              <h3 className="text-lg font-semibold text-green-700">Congratulations!</h3>
              <p className="text-green-600">Your marketing mix model analysis has been completed successfully.</p>
            </div>
          </div>

          {/* Seasonality and Trend Charts */}
          {trendDataSet.length > 0 && seasonLabel.length > 0 && (
            <div className="grid grid-cols-1 w-full">
              <div className="mt-2">
                <Maximize title="Trend">
                  <div className="overflow-auto">
                    <LineChart
                      labels={seasonLabel}
                      dataSets={trendDataSet}
                      xAxisTitle="Date"
                      yAxisTitle="Level Component For Sale"
                    />
                  </div>
                </Maximize>
              </div>
            </div>
          )}

          {/* Spend Share and Effect Share */}
          <div className="flex w-full mr-0">
            {historicSpendContributionData &&
              historicSpendContributionLabel && (
                <div className="flex w-full">
                  <Maximize title="Spend Share">
                    <div >
                      <PieChartECharts
                        data={historicSpendContributionData}
                        labels={historicSpendContributionLabel}
                      />
                    </div>
                  </Maximize>
                </div>
              )}
          </div>

          {similarityHeatMapData && (
            <div className="w-full">
              <Maximize title="Similarity Matrix">
                <HeatMap data={similarityHeatMapData} />
              </Maximize>
            </div>
          )}

          {/* Adstock Chart */}
          {adstockData && (
            <div className="w-full">
              <Maximize title="Adstock Chart">
                <div className="flex justify-between items-center mb-2 px-4 py-4">
                  <div></div>
                  <button
                    onClick={() => setShowChartLabels(!showChartLabels)}
                    className="px-3 py-1 text-sm text-[#143969] border border-[#143969] rounded hover:bg-[#143969] hover:text-white transition-colors"
                  >
                    {showChartLabels ? "Hide Labels" : "Show Labels"}
                  </button>
                </div>
                <div style={{ height: "400px" }}>
                  <MultiBarLineChartECharts
                    {...adstockData}
                    showLabels={showChartLabels}
                  />
                </div>
              </Maximize>
            </div>
          )}

          {/* YOY Media Contribution */}
          {mediaYOYSpentChart && (
            <div className="w-full">
              <Maximize title="YOY Media Contribution">
                <div className="flex justify-between items-center mb-2 px-4 py-4">
                  <div></div>
                  <button
                    onClick={() => setShowChartLabels(!showChartLabels)}
                    className="px-3 py-1 text-sm text-[#143969] border border-[#143969] rounded hover:bg-[#143969] hover:text-white transition-colors"
                  >
                    {showChartLabels ? "Hide Labels" : "Show Labels"}
                  </button>
                </div>
                <div style={{ height: "400px" }}>
                  <MultiBarLineChartECharts
                    labels={mediaYOYSpentChart.labels}
                    barDatasets={mediaYOYSpentChart.barDatasets}
                    lineDatasets={mediaYOYSpentChart.lineDatasets}
                    showLabels={showChartLabels}
                  />
                </div>
              </Maximize>
            </div>
          )}

          {/* Elasticity Report */}
          {elasticityColumn &&
            elasticityColumn.length > 0 &&
            elasticityRecord &&
            elasticityRecord.length > 0 && (
              <div className="w-full">
                <Maximize title="Elasticity Report">
                  <div className="w-full px-2">
                    <Table
                      columns={elasticityColumn}
                      records={elasticityRecord}
                    />
                  </div>
                </Maximize>
              </div>
            )}

          {/* Saturation Curves */}
          {saturationCharts && saturationCharts.length > 0 && (
            <div className="w-full">
              <h1 className="text-2xl font-bold text-center my-4">
                Saturation Curves
              </h1>
              <div className="grid grid-cols-1 w-full">{saturationCharts}</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ViewResults;
