import React, { useState, useEffect, useRef } from "react";
import { FieldOption } from "./DynamicForm";
import { UseFormRegister, UseFormSetValue } from "react-hook-form";

interface MultiSelectWithChipsProps {
  fieldName: string;
  setValue: UseFormSetValue<any>;
  register: UseFormRegister<any>;
  selectedOptions: FieldOption[];
  availableOptions: FieldOption[];
}

const MultiSelectWithChips: React.FC<MultiSelectWithChipsProps> = ({
  fieldName,
  setValue,
  register,
  selectedOptions,
  availableOptions,
}) => {
  useEffect(() => {
    setValue(fieldName, selectedOptions);
  }, [fieldName, setValue, selectedOptions]);

  let [selectedOptionsState, setSelectedOptionsState] =
    useState(selectedOptions);
  let [availableOptionsState, setAvailableOptionsState] =
    useState(availableOptions);
  const [inputValue, setInputValue] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);
    setShowDropdown(true);
  };

  const handleOptionClick = (option: FieldOption) => {
    if (
      !selectedOptions.some(
        (selectedOption) => selectedOption.value === option.value
      )
    ) {
      setSelectedOptionsState([...selectedOptionsState, option]);
      setValue(fieldName, [...selectedOptionsState, option]);
      setAvailableOptionsState(
        availableOptionsState.filter((item) => item.value !== option.value)
      );
    }
    setInputValue("");
  };

  const handleRemoveOption = (optionToRemove: FieldOption) => {
    setSelectedOptionsState(
      selectedOptionsState.filter((item) => item.value !== optionToRemove.value)
    );
    setAvailableOptionsState([...availableOptionsState, optionToRemove]);
    setValue(fieldName, selectedOptionsState.filter((item) => item.value !== optionToRemove.value));
  };

  const handleInputFocus = () => {
    setShowDropdown(true);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target as Node)
    ) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="autocomplete w-full relative" ref={wrapperRef}>
      <div className="form-control">
        <div className="relative">
          <div
            className="input input-bordered w-full flex h-auto flex-wrap items-center p-2"
            onClick={handleInputFocus}
          >
            {selectedOptionsState.map((option) => (
              <div key={option.value} className="badge badge-primary m-1 py-3">
                {option.label}
                <button
                  onClick={() => handleRemoveOption(option)}
                  className="ml-2"
                >
                  ✕
                </button>
              </div>
            ))}
            <input
              {...register(fieldName)}
              type="text"
              className="hidden"
            />
            <input
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              className="flex-grow outline-none"
              placeholder={selectedOptions.length === 0 ? "" : ""}
              onFocus={handleInputFocus}
            />
          </div>
          {showDropdown && (
            <ul className="absolute z-10 mt-1 w-full bg-base-100 border border-base-300 rounded shadow-lg max-h-60 overflow-y-auto">
              {availableOptionsState
                .filter((option) =>
                  option.label.toLowerCase().includes(inputValue.toLowerCase())
                )
                .map((option) => (
                  <li
                    key={option.value}
                    onClick={() => handleOptionClick(option)}
                    className="cursor-pointer p-2 hover:bg-base-200"
                  >
                    {option.label}
                  </li>
                ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default MultiSelectWithChips;
