import React from "react";
import ReactECharts from "echarts-for-react";

interface WaterfallChartProps {
  data: number[];
  labels: string[];
  isBase100?: boolean; // Optional flag for base 100 calculation
}

const WaterfallChartECharts: React.FC<WaterfallChartProps> = ({ data, labels, isBase100 = false }) => {
  // Calculate the starting points and offsets for each bar
  const offsets = data.reduce(
    (acc, value, index) => {
      if (index === 0) {
        acc.push(0); // First bar starts at 0
      } else {
        acc.push(acc[index - 1] + data[index - 1]); // Each bar starts at the end of the previous bar
      }
      return acc;
    },
    [] as number[]
  );

  // Calculate the total sum of the data for percentage calculation
  const totalSum = data.reduce((acc, value) => acc + value, 0);

  const formatYAxisLabel = (value: number) => {
    if (value >= 1000000) {
      return (value / 1000000).toFixed(1) + "M"; // Convert millions to M
    } else if (value >= 1000) {
      return (value / 1000).toFixed(1) + "K"; // Convert thousands to K
    } else {
      return value.toString(); // No change for smaller numbers
    }
  };

  // Formatter to replace underscores with spaces and capitalize words
  const formatXAxisLabel = (label: string) => {
    return label
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const option = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
      formatter: (params: any) => {
        const currentValue = params[1].data;
        const cumulativeValue = params[1].data + offsets[params[1].dataIndex];
        return `${labels[params[1].dataIndex]}: ${Number(currentValue).toLocaleString("en-US", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        })} (Cumulative: ${Number(cumulativeValue).toLocaleString("en-US", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        })})`;
      },
    },
    grid: {
      left: "100px", // Increased left margin for y-axis labels
      right: "50px",
      bottom: "200px", // Increased bottom margin for rotated labels
      top: "50px",
    },
    xAxis: {
      type: "category",
      data: labels,
      axisLabel: {
        rotate: 45, // Rotate the labels 45 degrees
        interval: 0, // Show all labels
        fontSize: 16, // Larger font size for x-axis labels
        formatter: (value: string) => formatXAxisLabel(value), // Format x-axis labels
      },
    },
    yAxis: {
      type: "value",
      axisLabel: {
        fontSize: 16, // Larger font size for y-axis labels
        formatter: (value: number) => formatYAxisLabel(value), // Use formatter to convert large numbers
      },
    },
    series: [
      {
        type: "bar",
        stack: "total",
        itemStyle: {
          color: "transparent",
        },
        data: offsets,
      },
      {
        name: "Value",
        type: "bar",
        stack: "total",
        label: {
          show: true,
          position: "top",
          formatter: (params: any) => {
            const value = Number(params.data).toLocaleString("en-US", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            });

            // Calculate percentage based on totalSum (isBase100 = false) or base100 (isBase100 = true)
            const percentage = isBase100
              ? ((params.data / data[0]) * 100).toFixed(1) // Divide by base 100 if flag is true
              : ((params.data / totalSum) * 100).toFixed(1); // Default to total sum if flag is false

            return `${value} (${percentage}%)`; // Display value with percentage
          },
          fontSize: 14,
          fontWeight: "bold",
        },
        itemStyle: {
          color: (params: any) => {
            // Create a gradient of blues for positive values
            const positiveColors = [
              '#143969', // adrsta-blue (primary)
              '#1e4c8c', // slightly lighter
              '#285faf', // even lighter
              '#3272d2', // lightest
            ];
            
            // Use a complementary reddish color for negative values
            const negativeColor = '#69142f'; // complementary to adrsta-blue
            
            if (data[params.dataIndex] >= 0) {
              // Cycle through positive colors based on index
              return positiveColors[params.dataIndex % positiveColors.length];
            } else {
              return negativeColor;
            }
          },
        },
        data: data,
      },
    ],
  };

  return <ReactECharts option={option} style={{ height: "700px", width: "100%" }} />;
};

export default WaterfallChartECharts;
