import React from "react";
import NavBar from "../NavBar";
import AppSidebar from "./AppSidebar";
import SiteHeader from "./SiteHeader";

const AppLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div className="flex flex-col h-screen overflow-hidden">
      <SiteHeader />
      <div className="flex flex-1 overflow-hidden">
        <AppSidebar />
        <div className="flex-1  dark:bg-gray-900 overflow-auto">
          {children}
          {/* <NavBar>{children}</NavBar> */}
        </div>
      </div>
    </div>
  );
};

export default AppLayout;
