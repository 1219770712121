import React, { useState } from "react";
import DynamicForm, { ExtendedFieldConfig } from "../DynamicForm";
import { z } from "zod";
import NavBar from "../NavBar";

const NextBestActionSchema: Record<string, ExtendedFieldConfig> = {
  name: {
    validation: z.string(),
    metadata: { type: "text", label: "Name", group: "row1" },
  },
  actionFile: {
    validation: z.any(),
    metadata: {
      type: "file",
      label: "Action CSV",
      group: "row2a",
      accepts: ".csv",
      showIf: [],
    },
  },
  customerFile: {
    validation: z.any(),
    metadata: {
      type: "file",
      label: "Customer CSV",
      group: "row3b",
      accepts: ".csv",
      showIf: [],
    },
  },
  channelFile: {
    validation: z.any(),
    metadata: {
      type: "file",
      label: "Channel CSV",
      group: "row3c",
      accepts: ".csv",
      showIf: [],
    },
  },
  modelVersion: {
    validation: z.string(),
    metadata: {
      type: "select",
      label: "Job Version",
      options: [
        { value: "v1", label: "Version 1" },
        { value: "v2", label: "Version 2" },
        { value: "v3", label: "Version 3" },
      ],
      group: "row4",
    },
  },
  confidence: {
    validation: z.string(),
    metadata: {
      type: "text",
      label: "Confidence",
      group: "row5",
    },
  },
};

const JobConfigNextBestAction: React.FC = () => {
  const [isLoading, setLoading] = useState(false);
  const [isAlert, setAlert] = useState(false);
  const onSubmit = (data: Record<string, any>) => {
    try {
      setLoading(true);
      // const jobConfigService: JobConfigService = new JobConfigServiceFactory(router).createJobConfigService(jobType);
      // await jobConfigService.createJob(data);

      handleAlert();

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  };
  const handleAlert = () => {
    setAlert(true);
    setTimeout(() => {
      setAlert(false);
    }, 1000);
  };
  return (
    <div className="h-full flex flex-col justify-center font-mono">
      <div className="w-full flex justify-center">
        <div className="md:w-7/12 w-full">
          <div className="text-center font-bold text-2xl my-10">
            Create New Job
          </div>
          <DynamicForm
            formName="Create NBA Job Form"
            formSchema={NextBestActionSchema}
            onSubmit={onSubmit}
            onSubmitText="Create Job"
            isLoading={isLoading}
          />
          {isAlert && (
            <div
              role="alert"
              className="alert alert-success absolute bottom-8 right-8 w-auto"
            >
              <span className="ml-2">Job Config created successfully!</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default JobConfigNextBestAction;
