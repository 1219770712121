import React, { useEffect, useState } from "react";
import DashboardCharts from "../../../../components/DashboardCharts";
import { DollarSignIcon, TrendingUpIcon } from "lucide-react";
import { Button, Modal, Avatar } from "antd";
import "./ChartModal.css"; // Import the CSS file
import LineChart from "../../../../components/LineChart";

interface AggregateResponse {
  lift: {
    totalLiftPercentage: number;
    totalSalesWithCampaign: number;
    totalSalesWithoutCampaign: number;
  };
  roas: {
    totalROAS: number;
    totalAdSpendForSyntheticMarkets: number;
    totalIncrementSales: number;
  };
  graph: {
    totalLiftPercentage: number;
    totalSalesWithCampaign: number;
    totalSalesWithoutCampaign: number;
    salesWithCampaignData: number[];
    salesWithoutCampaignData: number[];
    labels?: any;
  };
}

interface LiftData {
  percentage: number;
  title: string;
  confidence: number;
  date: string;
  spend: number;
  sales: number;
}

interface SalesData {
  withoutCampaign: number;
  withCampaign: number;
  incrementalSales: number;
  totalLiftPercentage: number;
  adSpend?: number; // Add this if it's optional
}

interface PlotData {
  [key: number]: number;
}

interface LiftPlot {
  t_obs: PlotData;
  c_obs: PlotData;
  Time: PlotData;
}

interface AttPlot {
  Time: PlotData;
  Estimate: PlotData;
}

interface GeoLiftResponse {
  lift_plot: LiftPlot;
  att_plot: AttPlot;
  salesWithoutCampaign: number;
  pvalue: number;
  salesWithCampaign: number;
  average_att: number;
  lift_percentage: number;
  incremental: number;
  start_duration: number;
  end_duration: number;
}

type ResponseData = GeoLiftResponse | AggregateResponse;

interface ViewResultsProps {
  resultData: ResponseData;
}
const isGeoLiftResponse = (data: ResponseData | null | undefined): data is GeoLiftResponse => {
  return !!data && typeof data === "object" && "lift_plot" in data && "salesWithoutCampaign" in data;
};

const ViewResults: React.FC<ViewResultsProps> = ({ resultData }) => {
  const [geoLiftPostObsLabel, setGeoLiftPostObsLabel] = useState<string[]>([]);
  const [postTreatmentValue, setPostTreatmentValue] = useState<any[]>([]);
  const [liftData, setLiftData] = useState<LiftData | undefined>(undefined);
  const [salesData, setSalesData] = useState<SalesData | undefined>(undefined);
  const [attLabels, setATTLabels] = useState<string[]>([]);
  const [estimatedValue, setEstimatedValue] = useState<any[]>([]);
  const [chartKey, setChartKey] = useState<number>(Date.now());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState<string>("");
  const [timeLabels, setTimeLabels] = useState<string[]>([]);
  const [postTestStartDuration, setPostTestStartDuration] = useState<number>(0);

  // Modal Handlers
  const showModal = (content: string) => {
    setModalContent(content);
    setIsModalOpen(true);
  };


  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    if (isModalOpen) {
      setTimeout(() => setChartKey(Date.now()), 300);
    }
  }, [isModalOpen]);

  // Format Currency
  const formatCurrency = (value: number) =>
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(value || 0);

  // Process data safely inside useEffect
  useEffect(() => {
    if (!resultData) return;
    if (isGeoLiftResponse(resultData)) {
      const today = new Date();
      const formattedDate = today.toLocaleDateString("en-US", {
        day: "2-digit",
        month: "short",
      });
      const formattedTime = `${today.getHours()}:${today.getMinutes()}`;

      // Update Lift Data
      setLiftData({
        percentage: resultData.lift_percentage,
        title: "Geo lift for Adrsta",
        confidence: 60,
        date: `${formattedDate} | ${formattedTime}`,
        spend: 20000,
        sales: 68000,
      });


      // Update Sales Data
      setSalesData({
        withoutCampaign: Math.ceil(resultData.salesWithoutCampaign),
        withCampaign: Math.ceil(resultData.salesWithCampaign),
        incrementalSales: resultData.incremental,
        totalLiftPercentage: resultData.lift_percentage,
      });

      // const transformedData: SalesData = {
      //   withoutCampaign: resultData.lift.totalSalesWithoutCampaign,
      //   withCampaign: resultData.lift.totalSalesWithCampaign,
      //   adSpend: resultData.roas.totalAdSpendForSyntheticMarkets,
      //   incrementalSales: resultData.lift.totalLiftPercentage * 100,

      // Process ATT Plot
      const attPlot = resultData.att_plot;
      const attLabel = Object.values(attPlot.Time).map((time) => time.toString());
      const estimateData = Object.values(attPlot.Estimate).map((value) => parseFloat(value.toFixed(2)));

      setATTLabels(attLabel);
      setEstimatedValue([
        {
          type: "line",
          lineType: "dashed",
          name: "ATT",
          data: estimateData,
          borderColor: "#3b82f6",
          backgroundColor: "rgba(59, 130, 246, 0.0)",
        },
      ]);

      // Process Lift Plot
      const geoLiftPlot = resultData.lift_plot;
      const labels = Object.values(geoLiftPlot.Time).map((time) => time.toString());
      const c_obsData = Object.values(geoLiftPlot.c_obs);
      const t_obsData = Object.values(geoLiftPlot.t_obs);

      setGeoLiftPostObsLabel(labels);
      setPostTreatmentValue([
        {
          name: "Synthetic Control",
          lineType: "dashed",
          data: c_obsData,
          borderColor: "#d6279a",
          backgroundColor: "rgba(214, 39, 154, 0)",
        },
        {
          name: "Test Location",
          data: t_obsData,
          borderColor: "#006400",
          backgroundColor: "rgba(0, 100, 0, 0)",
        },
      ]);

      // Reset Chart Key for re-render
      setChartKey(Date.now());

    }
    else {
      const today = new Date();
      const formattedDate = today.toLocaleDateString("en-US", {
        day: "2-digit",
        month: "short",
      });
      const formattedTime = `${today.getHours()}:${today.getMinutes()}`;

      // Update Lift Data
      setLiftData({
        percentage: resultData.lift.totalLiftPercentage,
        title: "Geo lift for Adrsta",
        confidence: 60,
        date: `${formattedDate} | ${formattedTime}`,
        spend: 20000,
        sales: 68000,
      });
      // Process AggregateResponse
      setSalesData({
        withoutCampaign: Math.ceil(resultData.lift.totalSalesWithoutCampaign),
        withCampaign: Math.ceil(resultData.lift.totalSalesWithCampaign),
        incrementalSales: resultData.roas.totalIncrementSales,
        adSpend: resultData.roas.totalAdSpendForSyntheticMarkets,
        totalLiftPercentage: resultData.lift.totalLiftPercentage,
      });
      if (resultData.graph) {
        const { salesWithCampaignData, salesWithoutCampaignData, labels } = resultData.graph;
        const swc = salesWithCampaignData || [];
        const swoc = salesWithoutCampaignData || [];
        const generatedLabels = (labels && labels.length > 0) ? labels : Array.from({ length: swc.length }, (_, i) => `${i + 1}`);
        // setSalesWithCampaignData(swc);
        // setSalesWithoutCampaignData(swoc);
        setTimeLabels(generatedLabels);
        console.log('Time Series Data:', {
          salesWithCampaign: resultData.lift.totalSalesWithCampaign,
          salesWithoutCampaign: resultData.lift.totalSalesWithCampaign,
          labels: generatedLabels
        });
      }

    }
  }, [resultData]);

  return (
    <>
      <p className="text-md font-bold text-gray-500 w-[80%] ml-12 mb-8">
        Congratulations! Now Measure 360 impact of your campaign using interactive charts.
      </p>
      <div className="flex justify-around">
      <div className="col-span-1 md:col-span-5 bg-white p-4 rounded-xl shadow-md">
          <DashboardCharts
            salesData={{
              withCampaign: salesData?.withCampaign || 0,
              withoutCampaign: salesData?.withoutCampaign || 0,
            }}
            spendData={{
              spend: 0,
              sales: 0,
            }}
            height={300}
            showLegend={false}
            chartType="sales"
          />

          <div className="space-y-4">
            <div className="flex items-center justify-between p-3 bg-gray-50 rounded-lg">
              <div className="flex items-center gap-2">
              <Avatar shape="square" size="large" />
                <DollarSignIcon className="w-5 h-5 text-gray-600" />
                <span className="text-gray-600">Sales without Campaign</span>
              </div>
              <span className="font-semibold">
                ${(salesData?.withoutCampaign || 0).toLocaleString()}
              </span>
            </div>

            <div className="flex items-center justify-between p-3 bg-gray-50 rounded-lg">
              <div className="flex items-center gap-2">
              <Avatar shape="square" size="large" style={{ backgroundColor: '#52c41a', color: '#fff' }} />
                <DollarSignIcon className="w-5 h-5 text-gray-600" />
                <span className="text-gray-600">Sales with Campaign</span>
              </div>
              <span className="font-semibold">
                ${(salesData?.withCampaign || 0).toLocaleString()}
              </span>
            </div>

            <div className="flex items-center justify-between p-3 bg-gray-50 rounded-lg">
              <div className="flex items-center gap-2">
                <TrendingUpIcon className="w-5 h-5 text-green-500" />
                <span className="text-gray-600">
                  Incremental Sales per Ad Spend
                </span>
              </div>
              <span className="font-semibold text-green-500">
                +{new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(salesData?.incrementalSales ?? 0)}
              </span>
            </div>
            {isGeoLiftResponse(resultData) && (
              <Button
                type="primary"
                className="w-full"
                onClick={() => showModal("viewChart")}
              >
                View Test & Control Markets
              </Button>
            )}
          </div>
        </div>
        <div className="flex flex-col justify-between">
          <div className="w-[300px] mt-4 flex flex-col gap-4 bg-white px-4 pb-4 rounded-xl shadow-md">
            <h3 className="text-lg text-center font-semibold text-gray-700 mb-6">
              Latest Lift
            </h3>
            {liftData && (
              <>
                <div className="text-center">
                  <p className={`text-6xl font-bold ${salesData?.totalLiftPercentage !== undefined && salesData.totalLiftPercentage >= 0 ? "text-green-500" : "text-red-500"} mb-4`}>
                    {salesData?.totalLiftPercentage !== undefined
                      ? salesData.totalLiftPercentage >= 0
                        ? `+${new Intl.NumberFormat('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(salesData.totalLiftPercentage)}%`
                        : `${new Intl.NumberFormat('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(salesData.totalLiftPercentage)}%`
                      : 'N/A'}
                  </p>
                  <p className="text-gray-700 mb-2">{liftData.title}</p>
                  <p className="text-gray-600 mb-1">
                    Confidence &gt;{liftData.confidence}%
                  </p>
                </div>
              </>
            )}

            {isGeoLiftResponse(resultData) && (
              <Button type="primary" onClick={() => showModal("liftTrend")}>
                Lift Trend over time
              </Button>
            )}
          </div>

          {salesData?.adSpend && (
            <div className="w-[300px] mt-4 flex flex-col gap-4 bg-white px-4 pb-4 rounded-xl shadow-md">
              <DashboardCharts
                spendData={{
                  spend: salesData?.adSpend || 0,
                  sales: salesData?.withCampaign || 0,
                }}
                salesData={{
                  withCampaign: salesData?.withCampaign || 0,
                  withoutCampaign: salesData?.adSpend || 0,
                }}
                height={200}
                showLegend={false}
                chartType="metrics"
              />
              {/* ROAS Calculation Display */}
              <div className="text-center mt-2">
                <p className="text-lg font-semibold text-gray-700">
                  <span className={`font-semibold ${salesData?.adSpend && salesData?.withCampaign ? "text-green-500" : "text-gray-500"}`}>
                    {salesData?.adSpend && salesData?.withCampaign
                      ? `${new Intl.NumberFormat('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format((salesData.withCampaign / salesData.adSpend) * 100)}%`
                      : "N/A"}
                  </span>
                </p>
              </div>
              {/* <Button type="primary" onClick={() => showModal("SalesComparison")}>
              Sales Comparison Over Time
            </Button> */}
            </div>
          )}
        </div>
      
      </div>



      {/* Modal Section */}
      <Modal
        title={
          modalContent === "liftTrend"
            ? "Lift Trend Analysis"
            : modalContent === "viewChart"
              ? "Chart View"
              : ""
        }
        open={isModalOpen}
        onCancel={handleModalClose}
        footer={null}
        width={800}
      >
        {modalContent === "liftTrend" && resultData && (
          <div className="chart-container">
            <h3 className="text-lg font-semibold mb-4">Lift Trend Analysis</h3>
            <LineChart
              key={`treatment-effect-${chartKey}`}
              labels={attLabels}
              dataSets={estimatedValue}
              xAxisTitle="Time"
              yAxisTitle="Average Lift Value"
              highlightAreaFrom={isGeoLiftResponse(resultData) ? resultData.start_duration : undefined}
              verticalLineAt={isGeoLiftResponse(resultData) ? resultData.start_duration : undefined}
              showSymbols={true}
              height="400px"
            />
          </div>
        )}

        {modalContent === "viewChart" && resultData && (
          <div className="chart-container">
            <h3 className="text-lg font-semibold mb-4">Detailed Chart View</h3>
            <LineChart
              key={`control-treatment-${chartKey}`}
              labels={geoLiftPostObsLabel}
              dataSets={postTreatmentValue}
              xAxisTitle="Time"
              yAxisTitle="Control Observations (c_obs)"
              highlightAreaFrom={isGeoLiftResponse(resultData) ? resultData.start_duration : undefined}
              verticalLineAt={isGeoLiftResponse(resultData) ? resultData.start_duration : undefined}
              showSymbols={true}
              height="400px"
            />
          </div>
        )}
        {modalContent === "SalesComparison" && resultData && (
          <div className="chart-container">
            <h3 className="text-lg font-semibold mb-4">Detailed Chart View</h3>
            <LineChart
              key={`sales-comparison-${chartKey}`}
              labels={timeLabels}
              dataSets={[
                {
                  name: 'Sales With Campaign',
                  data: salesData?.withCampaign,
                },
                {
                  name: 'Sales Without Campaign',
                  data: salesData?.withoutCampaign,
                  lineType: "dashed"
                }
              ] as any}
              xAxisTitle="Time"
              yAxisTitle="Sales"
              // highlightAreaFrom={postTestStartDuration}
              // verticalLineAt={postTestStartDuration}
              showSymbols={true}
              height="500px"
            />
          </div>
        )}
      </Modal>
    </>
  );
}

export default ViewResults;
