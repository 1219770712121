import { useState, useEffect } from "react";
import { Moon, Sun, Bell, Globe, Search, Menu } from "lucide-react";
import { Avatar } from "antd";
import Logo  from "../../../src/assets/image.png";


interface SiteHeaderProps {
  progress?: number;
  onTrackProgress?: () => void;
}

const SiteHeader = ({ progress = 75, onTrackProgress }: SiteHeaderProps) => {
  const [darkMode, setDarkMode] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [darkMode]);

  return (
    <header className="relative">
      {/* Main Header */}
      <div className="flex flex-col lg:flex-row p-4 border-b bg-white dark:bg-gray-900 dark:border-gray-700">
        <div className="flex items-center w-full lg:w-[240px] lg:flex-shrink-0">
          {/* Mobile Menu Button - Only visible on mobile */}
          <button
            className="lg:hidden mr-4"
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          >
            <Menu className="w-6 h-6 text-gray-600 dark:text-gray-300" />
          </button>

          <img
            className="h-10 w-auto ml-10"
            src={Logo}
            />

          {/* Mobile Header Icons - Only visible on mobile */}
          <div className="flex lg:hidden items-center gap-2 ml-auto">
            <img
              src="./country.png"
              alt="US"
              className="rounded-full w-6 h-6"
            />
            <button onClick={() => setDarkMode(!darkMode)}>
              {darkMode ? (
                <Sun className="w-5 h-5 text-yellow-500" />
              ) : (
                <Moon className="w-5 h-5 text-gray-600 dark:text-gray-300" />
              )}
            </button>
          </div>
        </div>

        {/* Right Side Icons - Desktop */}
        <div className="hidden lg:flex items-center gap-4 ml-auto">
          <div className="flex items-center rounded-full">
            <img
              src="./country.png"
              alt="US"
              className="rounded-full w-6 h-6 mr-2"
            />
          </div>

          <Bell className="w-5 h-5 text-gray-600 dark:text-gray-300 cursor-pointer" />
          <button onClick={() => setDarkMode(!darkMode)}>
            {darkMode ? (
              <Sun className="w-5 h-5 text-yellow-500" />
            ) : (
              <Moon className="w-5 h-5 text-gray-600 dark:text-gray-300" />
            )}
          </button>
          <div className="w-8 h-8 bg-gradient-to-r from-blue-500 to-purple-500 rounded-full"></div>
        </div>
      </div>

      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <div className="lg:hidden absolute top-full left-0 right-0 bg-white dark:bg-gray-900 border-b dark:border-gray-700 p-4 z-50">
          <div className="flex justify-around">
            <Bell className="w-5 h-5 text-gray-600 dark:text-gray-300 cursor-pointer" />
          </div>
        </div>
      )}
    </header>
  );
};

export default SiteHeader;
