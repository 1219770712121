import { TableColumn } from "../../../components/Table";
import React, { useEffect, useState } from "react";
import { JobPendingComponentProps } from "../../../models/interfaces";
import JobConfigView from "../../../components/JobConfigView";
import { JobType } from "../../../constants/JobTypes";
import axiosInstance from "../../../utils/axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import {
  fetchJobConfigBidOptimizer,
  fetchJobRunBidOptimizer,
} from "../../../redux/bidOptimizerSlice";
import Loader from "../../../components/Loader";

const columns: TableColumn[] = [
  { key: "name", label: "Name" },
  {
    key: "createdDate",
    label: "Created Date",
    formatter: {
      type: "date",
    },
  },
  { key: "status", label: "Status" },
];

const geoBreadCrumbs = [
  {
    name: "Home",
    url: "/",
  },
  {
    name: "Buying",
    url: "/optimization/bid-optimizer",
  },
  {
    name: "Bid Optimizer",
    url: "/optimization/bid-optimizer",
  },
];

const BidOptimizer: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { jobConfigList, jobRunList, loading } = useSelector(
    (state: RootState) => state.bidOptimizer
  );

  useEffect(() => {
    dispatch(fetchJobConfigBidOptimizer());
    dispatch(fetchJobRunBidOptimizer());
  }, [dispatch]);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <JobConfigView
          jobConfigList={jobConfigList}
          jobRunList={jobRunList}
          breadCrumbs={geoBreadCrumbs}
          jobType={JobType.BidOptimizer}
          tableColumns={columns}
        />
      )}
    </>
  );
};

export default BidOptimizer;
