import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';

interface ToastContextType {
  addToast: (message: string) => void;
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export const ToastProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [toastQueue, setToastQueue] = useState<string[]>([]);

  const addToast = (message: string) => {
    setToastQueue((prevQueue) => [...prevQueue, message]);
  };

  useEffect(() => {
    if (toastQueue.length > 0) {
      const timer = setTimeout(() => {
        setToastQueue((prevQueue) => prevQueue.slice(1));
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [toastQueue]);

  return (
    <ToastContext.Provider value={{ addToast }}>
      {children}
      {toastQueue.length > 0 && (
        <div className="toast toast-bottom">
          <div className="alert alert-warning">
            <span>{toastQueue[0]}</span>
          </div>
        </div>
      )}
    </ToastContext.Provider>
  );
};

export const useToast = (): ToastContextType => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};
