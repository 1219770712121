import React, { useEffect, useState } from "react";
import { JobPendingComponentProps } from "../../../models/interfaces";
import JobConfigView from "../../../components/JobConfigView";
import { TableColumn } from "../../../components/Table";
import { JobType } from "../../../constants/JobTypes";
import axiosInstance from "../../../utils/axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import {
  fetchJobConfigNextBestAction,
  fetchJobRunNextBestAction,
} from "../../../redux/nextBestActionSlice";

const columns: TableColumn[] = [
  { key: "name", label: "Name" },
  { key: "jobType", label: "Job Type" },
  { key: "sourceType", label: "Source Type" },
  { key: "jobVersion", label: "Job Version" },
  { key: "confidence", label: "Confidence" },
  { key: "status", label: "Status" },
];

const nbaBreadCrumbs = [
  {
    name: "Home",
    url: "/",
  },
  {
    name: "Buying",
    url: "/optimization/next-best-action",
  },
  {
    name: "Next Best Action",
    url: "/optimization/next-best-action",
  },
];

const NextBestAction: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>(); // Typed dispatch
  const { jobConfigList, jobRunList, loading } = useSelector(
    (state: RootState) => state.nextBestAction
  );

  useEffect(() => {
    dispatch(fetchJobConfigNextBestAction());
    dispatch(fetchJobRunNextBestAction());
  }, [dispatch]);

  return (
    <>
      <JobConfigView
        jobConfigList={jobConfigList}
        jobRunList={jobRunList}
        breadCrumbs={nbaBreadCrumbs}
        tableColumns={columns}
        jobType={JobType.NextBestAction}
      />
    </>
  );
};

export default NextBestAction;
