import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "../utils/axiosInstance";
import { JobConfig, JobRun, NextBestActionState } from "../models/interfaces";

// Initial State
const initialState: NextBestActionState = {
  jobConfigList: [],
  jobRunList: [],
  loading: false,
};

// Async Thunks
export const fetchJobConfigNextBestAction = createAsyncThunk<JobConfig[]>(
  "nextBestAction/fetchJobConfig",
  async () => {
    const response = await axiosInstance.get(
      "/api/jobConfig/jobType/NEXT_BEST_ACTION"
    );
    return response.data.items;
  }
);

export const fetchJobRunNextBestAction = createAsyncThunk<JobRun[]>(
  "nextBestAction/fetchJobRun",
  async () => {
    const response = await axiosInstance.get(
      "/api/jobRun/jobType/NEXT_BEST_ACTION"
    );
    return response.data.items;
  }
);

// Redux Slice
const nextBestActionSlice = createSlice({
  name: "nextBestAction",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchJobConfigNextBestAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        fetchJobConfigNextBestAction.fulfilled,
        (state, action: PayloadAction<JobConfig[]>) => {
          state.jobConfigList = action.payload;
          state.loading = false;
        }
      )
      .addCase(fetchJobConfigNextBestAction.rejected, (state) => {
        state.loading = false;
      })
      .addCase(
        fetchJobRunNextBestAction.fulfilled,
        (state, action: PayloadAction<JobRun[]>) => {
          state.jobRunList = action.payload;
        }
      );
  },
});

export default nextBestActionSlice.reducer;
