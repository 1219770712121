export const LOCALE_LIST = [
    {
        name: 'English (US)',
        key: 6,
        gg_ads_key: 1006,
        gg_locale: 'en_US'
    },
    {
        name: 'Catalan',
        key: 1,
        gg_ads_key: 1038,
        gg_locale: 'ca'
    },
    {
        name: 'Czech',
        key: 2,
        gg_ads_key: 1021,
        gg_locale: 'cs'
    },
    {
        name: 'Cebuano',
        key: 56,
        gg_ads_key: null,
        gg_locale: null
    },
    {
        name: 'Welsh',
        key: 3,
        gg_ads_key: 1060,
        gg_locale: 'cy'
    },
    {
        name: 'Danish',
        key: 4,
        gg_ads_key: 1009,
        gg_locale: 'da'
    },
    {
        name: 'German',
        key: 5,
        gg_ads_key: 1001,
        gg_locale: 'de'
    },
    {
        name: 'Basque',
        key: 59,
        gg_ads_key: 1063,
        gg_locale: 'eu'
    },
    {
        name: 'Spanish',
        key: 23,
        gg_ads_key: 1003,
        gg_locale: 'es'
    },
    {
        name: 'Spanish (Spain)',
        key: 7,
        gg_ads_key: 1003,
        gg_locale: 'es'
    },
    {
        name: 'Guarani',
        key: 66,
        gg_ads_key: 1071,
        gg_locale: 'gn'
    },
    {
        name: 'Finnish',
        key: 8,
        gg_ads_key: 1011,
        gg_locale: 'fi'
    },
    {
        name: 'French (France)',
        key: 9,
        gg_ads_key: 1002,
        gg_locale: 'fr'
    },
    {
        name: 'Galician',
        key: 65,
        gg_ads_key: 1070,
        gg_locale: 'gl'
    },
    {
        name: 'Hungarian',
        key: 30,
        gg_ads_key: 1024,
        gg_locale: 'hu'
    },
    {
        name: 'Italian',
        key: 10,
        gg_ads_key: 1004,
        gg_locale: 'it'
    },
    {
        name: 'Japanese',
        key: 11,
        gg_ads_key: 1005,
        gg_locale: 'ja'
    },
    {
        name: 'Korean',
        key: 12,
        gg_ads_key: 1012,
        gg_locale: 'ko'
    },
    {
        name: 'Norwegian (bokmal)',
        key: 13,
        gg_ads_key: 1013,
        gg_locale: 'no'
    },
    {
        name: 'Norwegian (nynorsk)',
        key: 84,
        gg_ads_key: 1061,
        gg_locale: 'nn'
    },
    {
        name: 'Dutch',
        key: 14,
        gg_ads_key: 1010,
        gg_locale: 'nl'
    },
    {
        name: 'Frisian',
        key: 63,
        gg_ads_key: 1067,
        gg_locale: 'fy'
    },
    {
        name: 'Polish',
        key: 15,
        gg_ads_key: 1030,
        gg_locale: 'pl'
    },
    {
        name: 'Portuguese (Brazil)',
        key: 16,
        gg_ads_key: 1016,
        gg_locale: 'pt_BR'
    },
    {
        name: 'Portuguese (Portugal)',
        key: 31,
        gg_ads_key: 1014,
        gg_locale: 'pt'
    },
    {
        name: 'Romanian',
        key: 32,
        gg_ads_key: 1032,
        gg_locale: 'ro'
    },
    {
        name: 'Russian',
        key: 17,
        gg_ads_key: 1031,
        gg_locale: 'ru'
    },
    {
        name: 'Slovak',
        key: 33,
        gg_ads_key: 1033,
        gg_locale: 'sk'
    },
    {
        name: 'Slovenian',
        key: 34,
        gg_ads_key: 1034,
        gg_locale: 'sl'
    },
    {
        name: 'Swedish',
        key: 18,
        gg_ads_key: 1015,
        gg_locale: 'sv'
    },
    {
        name: 'Thai',
        key: 35,
        gg_ads_key: 1044,
        gg_locale: 'th'
    },
    {
        name: 'Turkish',
        key: 19,
        gg_ads_key: 1037,
        gg_locale: 'tr'
    },
    {
        name: 'Northern Kurdish (Kurmanji)',
        key: 76,
        gg_ads_key: null,
        gg_locale: null
    },
    {
        name: 'Simplified Chinese (China)',
        key: 20,
        gg_ads_key: 1017,
        gg_locale: 'zh_CN'
    },
    {
        name: 'Traditional Chinese (Hong Kong)',
        key: 21,
        gg_ads_key: 1150,
        gg_locale: 'zh_HK'
    },
    {
        name: 'Traditional Chinese (Taiwan)',
        key: 22,
        gg_ads_key: 1018,
        gg_locale: 'zh_TW'
    },
    {
        name: 'Afrikaans',
        key: 36,
        gg_ads_key: 1047,
        gg_locale: 'af'
    },
    {
        name: 'Albanian',
        key: 87,
        gg_ads_key: 1125,
        gg_locale: 'sq'
    },
    {
        name: 'Armenian',
        key: 68,
        gg_ads_key: 1076,
        gg_locale: 'hy'
    },
    {
        name: 'Azerbaijani',
        key: 53,
        gg_ads_key: 1051,
        gg_locale: 'az'
    },
    {
        name: 'Belarusian',
        key: 54,
        gg_ads_key: 1053,
        gg_locale: 'be'
    },
    {
        name: 'Bengali',
        key: 45,
        gg_ads_key: 1056,
        gg_locale: 'bn'
    },
    {
        name: 'Bosnian',
        key: 55,
        gg_ads_key: 1075,
        gg_locale: 'bs'
    },
    {
        name: 'Bulgarian',
        key: 37,
        gg_ads_key: 1020,
        gg_locale: 'bg'
    },
    {
        name: 'Croatian',
        key: 38,
        gg_ads_key: 1039,
        gg_locale: 'hr'
    },
    {
        name: 'Flemish',
        key: 83,
        gg_ads_key: null,
        gg_locale: null
    },
    {
        name: 'English (UK)',
        key: 24,
        gg_ads_key: 1007,
        gg_locale: 'en_GB'
    },
    {
        name: 'Esperanto',
        key: 57,
        gg_ads_key: 1062,
        gg_locale: 'eo'
    },
    {
        name: 'Estonian',
        key: 58,
        gg_ads_key: 1043,
        gg_locale: 'et'
    },
    {
        name: 'Faroese',
        key: 62,
        gg_ads_key: 1066,
        gg_locale: 'fo'
    },
    {
        name: 'French (Canada)',
        key: 44,
        gg_ads_key: 1002,
        gg_locale: 'fr'
    },
    {
        name: 'Georgian',
        key: 72,
        gg_ads_key: 1082,
        gg_locale: 'ka'
    },
    {
        name: 'Greek',
        key: 39,
        gg_ads_key: 1022,
        gg_locale: 'el'
    },
    {
        name: 'Gujarati',
        key: 67,
        gg_ads_key: 1072,
        gg_locale: 'gu'
    },
    {
        name: 'Hindi',
        key: 46,
        gg_ads_key: 1023,
        gg_locale: 'hi'
    },
    {
        name: 'Icelandic',
        key: 69,
        gg_ads_key: 1026,
        gg_locale: 'is'
    },
    {
        name: 'Indonesian',
        key: 25,
        gg_ads_key: 1025,
        gg_locale: 'id'
    },
    {
        name: 'Irish',
        key: 64,
        gg_ads_key: 1068,
        gg_locale: 'ga'
    },
    {
        name: 'Javanese',
        key: 71,
        gg_ads_key: 1081,
        gg_locale: 'jv'
    },
    {
        name: 'Kannada',
        key: 75,
        gg_ads_key: 1086,
        gg_locale: 'kn'
    },
    {
        name: 'Kazakh',
        key: 73,
        gg_ads_key: 1083,
        gg_locale: 'kk'
    },
    {
        name: 'Latvian',
        key: 78,
        gg_ads_key: 1028,
        gg_locale: 'lv'
    },
    {
        name: 'Lithuanian',
        key: 40,
        gg_ads_key: 1029,
        gg_locale: 'lt'
    },
    {
        name: 'Macedonian',
        key: 79,
        gg_ads_key: 1097,
        gg_locale: 'mk'
    },
    {
        name: 'Malay',
        key: 41,
        gg_ads_key: 1102,
        gg_locale: 'ms'
    },
    {
        name: 'Marathi',
        key: 81,
        gg_ads_key: 1101,
        gg_locale: 'mr'
    },
    {
        name: 'Mongolian',
        key: 80,
        gg_ads_key: 1099,
        gg_locale: 'mn'
    },
    {
        name: 'Nepali',
        key: 82,
        gg_ads_key: 1106,
        gg_locale: 'ne'
    },
    {
        name: 'Punjabi',
        key: 47,
        gg_ads_key: 1110,
        gg_locale: 'pa'
    },
    {
        name: 'Serbian',
        key: 42,
        gg_ads_key: 1035,
        gg_locale: 'sr'
    },
    {
        name: 'Swahili',
        key: 88,
        gg_ads_key: 1129,
        gg_locale: 'sw'
    },
    {
        name: 'Filipino',
        key: 26,
        gg_ads_key: 1042,
        gg_locale: 'tl'
    },
    {
        name: 'Tamil',
        key: 48,
        gg_ads_key: 1130,
        gg_locale: 'ta'
    },
    {
        name: 'Telugu',
        key: 49,
        gg_ads_key: 1131,
        gg_locale: 'te'
    },
    {
        name: 'Malayalam',
        key: 50,
        gg_ads_key: 1098,
        gg_locale: 'ml'
    },
    {
        name: 'Ukrainian',
        key: 52,
        gg_ads_key: 1036,
        gg_locale: 'uk'
    },
    {
        name: 'Uzbek',
        key: 91,
        gg_ads_key: 1141,
        gg_locale: 'uz'
    },
    {
        name: 'Vietnamese',
        key: 27,
        gg_ads_key: 1040,
        gg_locale: 'vi'
    },
    {
        name: 'Khmer',
        key: 74,
        gg_ads_key: 1085,
        gg_locale: 'km'
    },
    {
        name: 'Tajik',
        key: 89,
        gg_ads_key: 1132,
        gg_locale: 'tg'
    },
    {
        name: 'Arabic',
        key: 28,
        gg_ads_key: 1019,
        gg_locale: 'ar'
    },
    {
        name: 'Hebrew',
        key: 29,
        gg_ads_key: 1027,
        gg_locale: 'iw'
    },
    {
        name: 'Urdu',
        key: 90,
        gg_ads_key: 1041,
        gg_locale: 'ur'
    },
    {
        name: 'Persian',
        key: 60,
        gg_ads_key: 1064,
        gg_locale: 'fa'
    },
    {
        name: 'Pashto',
        key: 85,
        gg_ads_key: null,
        gg_locale: null
    },
    {
        name: 'Sinhala',
        key: 86,
        gg_ads_key: null,
        gg_locale: null
    },
    {
        name: 'Japanese (Kansai)',
        key: 70,
        gg_ads_key: 1005,
        gg_locale: 'ja'
    },
    {
        name: 'English (All)',
        key: 1001,
        gg_ads_key: 1000,
        gg_locale: 'en'
    },
    {
        name: 'Spanish (All)',
        key: 1002,
        gg_ads_key: 1003,
        gg_locale: 'es'
    },
    {
        name: 'French (All)',
        key: 1003,
        gg_ads_key: 1002,
        gg_locale: 'fr'
    },
    {
        name: 'Chinese (All)',
        key: 1004,
        gg_ads_key: 1008,
        gg_locale: 'zh'
    },
    {
        name: 'Portuguese (All)',
        key: 1005,
        gg_ads_key: 1005,
        gg_locale: 'ja'
    }
]