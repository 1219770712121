import React, { useState, ChangeEvent, useEffect, useRef, useImperativeHandle, forwardRef } from "react";
import {
  Upload,
  Button,
  Table,Row,Col,
  message,
  Input,
  Radio,
  RadioChangeEvent,
} from "antd";
import type { UploadFile, UploadProps } from "antd/es/upload/interface";
import { UploadOutlined, CloseOutlined, RightOutlined } from "@ant-design/icons";
import Papa from "papaparse";
import { GeoLiftJobConfigService } from "../../../../services/jobConfigServiceFactory/GeoLiftJobConfigService";

interface PreCampaignProps {
  jobConfigId: string;
  fileData?: string;
  onValuesChange?: (values: {
    geoLiftStudyName: string;
    buyType: "national" | "non-national";
    fileList: File[];
  }) => void;
  initialValues?: {
    geoLiftStudyName: string;
    buyType: "national" | "non-national";
    fileList: File[];
  };
  setFileUploaded?: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface PreCampaignRef {
  getData: () => {
    geoLiftStudyName: string;
    buyType: "national" | "non-national";
    fileList: File[];
  };
}

const PreCampaign = forwardRef<PreCampaignRef, PreCampaignProps>(
  (
    { jobConfigId, fileData, onValuesChange, initialValues, setFileUploaded },
    ref
  ) => {
  const geoLiftJobConfigService = new GeoLiftJobConfigService();
  const initialValuesRef = useRef(initialValues);
  const isMounted = useRef(false);

  const [geoLiftStudyName, setTestName] = useState(
    initialValues?.geoLiftStudyName || ""
  );
  const [buyType, setBuyType] = useState<"national" | "non-national">(
    initialValues?.buyType || "non-national"
  );
  const [loading, setLoading] = useState(false);
  const [csvData, setCsvData] = useState<any[]>([]);
  const [columns, setColumns] = useState<
    { title: string; dataIndex: string }[]
  >([]);
  const [fileList, setFileList] = useState<File[]>(
    initialValues?.fileList || []
  );

  useEffect(() => {
    if (initialValuesRef.current !== initialValues) {
      setTestName(initialValues?.geoLiftStudyName || "");
      setBuyType(initialValues?.buyType || "non-national");
      setFileList(initialValues?.fileList || []);
      initialValuesRef.current = initialValues;
    }
  }, [initialValues]);

      // Trigger parent callback on value changes
      useEffect(() => {
        if (isMounted.current && onValuesChange) {
          onValuesChange({ geoLiftStudyName, buyType, fileList });
        } else {
          isMounted.current = true;
        }
      }, [geoLiftStudyName, buyType, fileList]);
  
      // Expose data to parent using forwardRef
      useImperativeHandle(ref, () => ({
        getData: () => ({ geoLiftStudyName, buyType, fileList }),
      }));
  

  useEffect(() => {
    if (initialValues?.fileList.length) {
      const file: any = initialValues.fileList[0];
      if (file) {
        Papa.parse(file, {
          complete: (result) => {
            if (result.data.length > 0) {
              const headers = result.data[0] as string[];
              const generatedColumns = headers.map((header) => ({
                title: header,
                dataIndex: header.toLowerCase().replace(/\s+/g, "_"),
              }));

              const tableData = result.data.slice(1).map((row: any, index) => ({
                key: index,
                ...headers.reduce((acc, header, i) => {
                  acc[header.toLowerCase().replace(/\s+/g, "_")] = row[i];
                  return acc;
                }, {} as Record<string, any>),
              }));

              setColumns(generatedColumns);
              setCsvData(tableData);
            }
          },
          error: (error) => {
            console.error("CSV parsing error:", error);
            setCsvData([]);
          },
          skipEmptyLines: true,
        });
      }
    }
  }, [initialValues?.fileList]);

  useEffect(() => {
    if (fileList.length > 0 && csvData.length === 0) {
      const file = fileList[0];
      if (file) {
        Papa.parse(file, {
          complete: (result) => {
            if (result.data.length > 0) {
              const headers = result.data[0] as string[];
              const generatedColumns = headers.map((header) => ({
                title: header,
                dataIndex: header.toLowerCase().replace(/\s+/g, "_"),
              }));

              const tableData = result.data.slice(1).map((row: any, index) => ({
                key: index,
                ...headers.reduce((acc, header, i) => {
                  acc[header.toLowerCase().replace(/\s+/g, "_")] = row[i];
                  return acc;
                }, {} as Record<string, any>),
              }));

              setColumns(generatedColumns);
              setCsvData(tableData);
            }
          },
          error: (error) => {
            console.error("CSV parsing error:", error);
            setCsvData([]);
          },
          skipEmptyLines: true,
        });
      }
    }
  }, [fileList]);

  const handleBuyTypeChange = (e: RadioChangeEvent) => {
    const newValue = e.target.value;
    setBuyType(newValue);
  };
  const handleTestNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setTestName(newValue);
  };

  const uploadProps: UploadProps = {
    name: "file",
    accept: ".csv",
    beforeUpload: async (file) => {
      setLoading(true);
      try {
        await geoLiftJobConfigService.uploadFile(file, `${jobConfigId}/input/geo_lift_data.csv`);
  

        // Parse CSV after successful upload
        await new Promise((resolve, reject) => {
          Papa.parse(file, {
            complete: (result) => {
              if (result.data.length > 0) {
                const headers = result.data[0] as string[];
                const generatedColumns = headers.map((header) => ({
                  title: header,
                  dataIndex: header.toLowerCase().replace(/\s+/g, "_"),
                }));
  
                const tableData = result.data.slice(1).map((row: any, index) => ({
                  key: index,
                  ...headers.reduce((acc, header, i) => {
                    acc[header.toLowerCase().replace(/\s+/g, "_")] = row[i];
                    return acc;
                  }, {} as Record<string, any>),
                }));
  
                setColumns(generatedColumns);
                setCsvData(tableData);
                setFileUploaded?.(true);
                message.success(`${file.name} uploaded and processed successfully`);
              } else {
                message.error("Empty CSV or invalid format.");
                setFileUploaded?.(false);
              }
              resolve(true);
            },
            error: (error) => {
              console.error("CSV parsing error:", error);
              setCsvData([]);
              setFileUploaded?.(false);
              reject(error);
            },
            skipEmptyLines: true,
          });
        });
  
        // Update state using File[]
        setFileList([file]);
        return false;
      } catch (error) {
        console.error("Upload failed:", error);
        message.error("File upload failed");
        setLoading(false);
        setFileUploaded?.(false);
        return false;
      } finally {
        setLoading(false);
      }
    },
  
    onRemove: () => {
      setFileList([]);
      setCsvData([]);
      setFileUploaded?.(false);
    },
  
    showUploadList: {
      showRemoveIcon: true,
      removeIcon: <CloseOutlined className="text-red-500" />,
    },
  };

  return (
    <div className="space-y-8 mt-6">
      <div className="space-y-3" >
        <label className="block font-medium text-gray-700" >Name</label>
        <Input
          placeholder="Geo Lift"
          value={geoLiftStudyName}
          onChange={handleTestNameChange}
          style={{ width: '100%' }}
        />
      </div>

      <div className="space-y-6">
        <Radio.Group
          onChange={handleBuyTypeChange}
          value={buyType}
          className="w-full"
        >
          <Row >
            <Col className="p-3 hover:bg-gray-50 rounded-lg transition-colors" span={12}>
              <Radio value="national">
                <div className="ml-2">
                  <div className="font-medium text-base">National Buy</div>
                  <div className="text-gray-500 text-sm mt-1">
                    Your historic campaign data across whole nation
                  </div>
                </div>
              </Radio>
            </Col>
            <Col className="w-full p-3 hover:bg-gray-50 rounded-lg transition-colors" span={12}>
              <Radio value="non-national">
                  <div className="font-medium text-base">Non National Buy</div>
                  <div className="text-gray-500 text-sm">
                    Your historic campaign data across selective locations
                </div>
              </Radio>
            </Col>
          </Row>
        </Radio.Group>
      </div>

      <div className="space-y-3">
        <Row className="flex justify-center items-center h-full">
          <Upload {...uploadProps} className="w-full flex justify-center" >
            <Button
              icon={<UploadOutlined />}
              size="large"
              className="flex items-center mt-5 "             
            >
              Upload Your Historic Campaign Data
            </Button>
          </Upload>
          {fileData && (
            <div className="flex justify-between items-center p-3">
              <span className="text-sm text-center text-green-600">
                Existing campaign is already loaded, you can upload a new file to replace it
              </span>
            </div>
          )}

          {fileList.length > 0 && (
            <div className="flex justify-between items-center p-3 bg-gray-50 rounded-lg">
              <span className="text-sm text-gray-600">
                Selected file: {fileList[0].name}
              </span>
              <Button
                type="link"
                danger
                onClick={() => {
                  setFileList([]);
                  setCsvData([]);
                }}
                icon={<CloseOutlined />}
              >
                Clear File
              </Button>
            </div>
          )}
        </Row>


        {csvData.length > 0 && (
          <div className="w-full mt-6">
            <div className="flex justify-between items-center mb-3">
              <h3 className="text-lg font-semibold">CSV Data Preview</h3>
              <Button
                type="default"
                onClick={() => {
                  setCsvData([]);
                }}
                icon={<CloseOutlined />}
              >
                Clear Preview
              </Button>
            </div>
            <Table
              columns={columns}
              dataSource={csvData.slice(0, 10)}
              pagination={false}
              scroll={{ x: true }}
              bordered
              size="middle"
              className="w-full shadow-sm"
            />
          </div>
        )}
      </div>
    </div>
  );
});

export default PreCampaign;
