import React, { useState, ChangeEvent, useEffect } from "react";

import {
  Steps,
  Input,
  Radio,
  Upload,
  Button,
  Select,
  UploadFile,
  message,
} from "antd";
import Papa from "papaparse";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import UploadData from "./UploadData";
import ViewResults from "./ViewResult";
import FinalizeDependentVariables from "./FinalizeDependentVariables";
import { TableRecord } from "../../../../components/Table";
import axiosInstance from "../../../../utils/axiosInstance";
import { useLocation, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { getTenantId } from "../../../../utils/auth";
import { DataStructure } from "../../../../models/interfaces";

dayjs.extend(customParseFormat);

const MMMJobsCreate: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const query = new URLSearchParams(useLocation().search);
  const [fileUploaded, setFileUploaded] = useState(false);
  const jobConfigId = query.get("jobConfigId");
  const jobId = jobConfigId == undefined ? uuidv4() : jobConfigId;
  const [name, setName] = useState<string | null>("");
  const [dataType, setDataType] = useState<string>("sales-model");
  const [file, setFile] = useState<any | null>(null);
  const [current, setCurrent] = useState(0);
  const [trigger, setTrigger] = useState<boolean>(false);
  const [dependentVariables, setDependentVariables] = useState<any>([]);
  const [csvData, setCsvData] = useState<any[]>([]);
  const [IsBusiness, setIsBusiness] = useState<string>("IsBusinessNot");
  const [seasonDataSet, setSeasonDataSet] = useState<any[]>([]);
  const [seasonLabel, setSeasonLabel] = useState<any[]>([]);
  const [columnMappings, setColumnMappings] = useState<{
    dateColumn: string;
    locationColumn: string;
    kpiColumn: string;
  }>({
    dateColumn: "date",
    locationColumn: "location",
    kpiColumn: "y",
  });
  const [variableSummaryStatistic, setVariableSummaryStatistic] =
    useState<any[]>();
  const [multiColinearityRecords, setMultiColinearityRecords] = useState<
    TableRecord[]
  >([]);
  const [heatMapData, setHeatMapData] = useState<any>(undefined);
  const [allVariiables, setAllVariiables] = useState<DataStructure>({
    spendVariable: {
      title: "Spend Variable",
      subtitle: "Select the spend variable",
      items: [],
    },
    impressionVariable: {
      title: "Impression Variable",
      subtitle: "Select the impression variable",
      items: [],
    },
    nonMediaVariable: {
      title: "Non-Media Variable",
      subtitle: "Select the non-media variable",
      items: [],
    },
  });

  const [metaFields, setMetaFields] = useState<string[]>([]); // Store meta.fields
  const [preCampaignData, setPreCampaignData] = useState<{
    geoLiftStudyName: string;
    buyType: "national" | "non-national";
    fileList: UploadFile[];
  }>({
    geoLiftStudyName: "",
    buyType: "non-national",
    fileList: [],
  });
  const handleNextButtonClick = () => {
    setTrigger((prev) => !prev);
  };

  const handlePreCampaignChange = (values: {
    geoLiftStudyName: string;
    buyType: "national" | "non-national";
    fileList: UploadFile[];
  }) => {
    setPreCampaignData(values); // Preserve all PreCampaign data
    if (values.fileList.length > 0) {
      const file: any = values.fileList[0];
      setFile(file);
      if (file) {
        Papa.parse(file, {
          header: true,
          complete: (result) => {
            const { data, meta } = result;

            setMetaFields(meta.fields || []); // Save meta.fields
            // Auto-detect columns based on common patterns
            const autoDetectedColumns = {
              dateColumn:
                meta.fields?.find((f) => f.toLowerCase().includes("date")) ||
                "date",
              locationColumn:
                meta.fields?.find((f) =>
                  f.toLowerCase().includes("location")
                ) || "location",
              kpiColumn:
                meta.fields?.find((f) =>
                  f.toLowerCase().match(/y|sales|revenue/)
                ) || "y",
            };

            setColumnMappings(autoDetectedColumns);

            const parsedData = data
              .filter(
                (item: any) =>
                  item[autoDetectedColumns.dateColumn] &&
                  item[autoDetectedColumns.locationColumn]
              )
              .map((item: any) => ({
                date: item[autoDetectedColumns.dateColumn],
                location: item[autoDetectedColumns.locationColumn].trim(),
                value: parseFloat(item[autoDetectedColumns.kpiColumn]) || 0,
              }));

            setCsvData(parsedData);
          },
          error: (error) => {
            console.error("CSV parsing error:", error);
            setCsvData([]);
          },
        });
      }
    } else {
      setCsvData([]);
    }
  };
  const submitFeatureForm = async () => {
    // console.log("Form Data:", formData);
    try {
      setLoading(true);

      if (jobConfigId == undefined) {
        const mmmFormData = new FormData();
        mmmFormData.append("file", file);
        const mmmFilePath = jobId + "/input/mmm_data.csv";
        mmmFormData.append("path", mmmFilePath);
        await axiosInstance.post("/api/s3/upload", mmmFormData);
      }
      const payload = {
        jobConfigId: jobId,
        name: name,
        jobType: "MMM",
        status: "RUNNING",
        tenantId: getTenantId(),
        dataSourceConfig: {
          dataSourceType: "FILE_CSV",
        },
        dataTransformationConfig: {},
        dataMappingConfig: {
          mappings: {
            mmm: {
              columnMapping: {
                media_variables: dependentVariables
                  .filter((ele: any) => ele.includes("_Spend"))
                  .map((ele: any) => ele),
                non_media_variables: dependentVariables
                  .filter(
                    (ele: any) =>
                      !ele.includes("_Spend") && !ele.includes("_Impressions")
                  )
                  .map((ele: any) => ele),
                impression_variables: dependentVariables
                  .filter((ele: any) => ele.includes("_Impressions"))
                  .map((ele: any) => ele),
                target_variables: ["Out_of_Home_Spend"],
                date_variables: ["Date"],
              },
            },
          },
        },
        modelVersion: "V1",
        modelParameterConfig: {
          parameters: {
            dateFormat: "%m/%d/%Y",
            isSeasonal: IsBusiness === "IsBusinessYes",
            modelType: dataType,
          },
        },
        modelOutputConfig: {},
      };
      const response = await axiosInstance.post("/api/jobConfig", payload);
      console.log(response.data);
      message.success("Job created successfully");
      await axiosInstance.post(
        "/api/jobRun",
        {
          jobConfigId: jobId,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("authToken"),
          },
        }
      );
      setCurrent(2);
      setLoading(false);
    } catch (error) {
      console.error("Error in submitFeatureForm:", error);
      setLoading(false);
    }
  };

  const steps = [
    {
      title: "Upload  data",
      description: "Historic data upload",
      content: (
        <UploadData
          onValuesChange={handlePreCampaignChange}
          setFileUploaded={setFileUploaded}
          initialValues={preCampaignData}
          setVariableSummaryStatistic={setVariableSummaryStatistic}
          setMultiColinearityRecords={setMultiColinearityRecords}
          setHeatMapData={setHeatMapData}
          setCurrent={setCurrent}
          current={current}
          setAllVariiables={setAllVariiables}
          setName={setName}
          name={name}
          setFile={setFile}
          trigger={trigger}
          setTrigger={setTrigger}
          setLoading={setLoading}
          file={file}
          setIsBusiness={setIsBusiness}
          IsBusiness={IsBusiness}
          setDataType={setDataType}
          dataType={dataType}
          setSeasonLabel={setSeasonLabel}
          seasonLabel={seasonLabel}
          seasonDataSet={seasonDataSet}
          setSeasonDataSet={setSeasonDataSet}
        />
      ),
    },
    {
      title: "Finalize dependent Variables",
      description: "Take informed decision using various data points  ",
      content: (
        <FinalizeDependentVariables
          csvData={csvData}
          columnMappings={columnMappings}
          metaFields={metaFields}
          variableSummaryStatistic={variableSummaryStatistic}
          multiColinearityRecords={multiColinearityRecords}
          heatMapData={heatMapData}
          submitFeatureForm={submitFeatureForm} // Pass the function as a prop
          allVariiables={allVariiables}
          setDependentVariables={setDependentVariables}
          dependentVariables={dependentVariables}
          seasonLabel={seasonLabel}
          seasonDataSet={seasonDataSet}
        />
      ),
    },

    {
      title: "View Results",
      description: "Measure impact on KPI - Lift",
      content: <ViewResults jobId={jobId} />,
    },
  ];

  const handlePrev = () => {
    setCurrent((current) => Math.max(current - 1, 0)); // Ensure it doesn't go below 0
  };
  const handleNext = () => {
    if (current === 0) {
      if (!name || name.trim().length === 0) {
        message.error("Please enter a name for the job");
        return;
      } else if (!fileUploaded) {
        message.error("Please upload a CSV file");
        return;
      }
      handleNextButtonClick();
    } else if (current === 1) {
      if (dependentVariables.length > 0) {
        submitFeatureForm();
      } else {
        message.error("Please select dependent variables");
        return;
      }

      // Ensure it doesn't go above steps.length - 1
    } else if (current === 2) {
      navigate("/planning/marketing-mix-model");
    }
  };
  return (
    <div className="w-full mx-auto  bg-white rounded-lg shadow-sm">
      <div className="flex justify-between items-start ml-10">
        <div className="w-1/4 pr-8 overflow-hidden">
          <h2 className="text-2xl font-semibold mt-5 mb-9 text-gray-900 max-w-[300px] truncate">
            {name ? name : "MMM Test for Pepsi 2"}
          </h2>
          <Steps
            direction="vertical"
            current={current}
            onChange={() => {}} // Disable click-based navigation
            items={steps.map((step) => ({
              title: step.title,
              description: step.description,
              disabled: true, // Disable all steps for click-based navigation
            }))}
            className="[&_.ant-steps-item]:mb-16 [&_.ant-steps-item-tail]:!top-3 [&_.ant-steps-item-tail]:!h-[110px] [&_.ant-steps-item-tail]:!w-[4px] [&_.ant-steps-item-icon]:!text-2xl [&_.ant-steps-item-icon]:!w-10 [&_.ant-steps-item-icon]:!h-10 [&_.ant-steps-item-icon]:!font-bold [&_.ant-steps-item-icon]:!flex [&_.ant-steps-item-icon]:!items-center [&_.ant-steps-item-icon]:!justify-center [&_.ant-steps-item-title]:!font-medium [&_.ant-steps-item-title]:!text-base"
          />
        </div>
        <div
          className=" min-h-screen py-7 pb-24 w-3/4 px-10 border-l overflow-y-auto "
          style={{ height: "100vh" }}
        >
          {steps[current].content}
        </div>
      </div>
      {current > 0 && current !== 2 && (
        <Button
          onClick={handlePrev}
          size="large"
          className="fixed bottom-8 left-1/2 transform -translate-x-1/2 z-50"
        >
          Previous
        </Button>
      )}

      {current <= steps.length - 1 ? (
        <Button
          // disabled={!name || !fileUploaded}
          type="primary"
          size="large"
          loading={loading}
          className="fixed bottom-8 right-8 z-50"
          onClick={handleNext}
        >
          {current === steps.length - 1 ? "Finish" : "Next"}
        </Button>
      ) : null}
    </div>
  );
};

export default MMMJobsCreate;
