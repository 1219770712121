import React from "react";
import ReactECharts from "echarts-for-react";
import { EChartsOption, SeriesOption } from "echarts";

export interface LineData {
  name: string;
  data: number[];
  borderColor: string;
  backgroundColor: string;
  lineType?: "solid" | "dashed"; // Add line type as an optional property
}

export interface LineChartProps {
  labels: string[];
  dataSets: LineData[];
  xAxisTitle: string;
  yAxisTitle: string;
  verticalLineAt?: number;
  highlightAreaFrom?: number;
  showSymbols?: boolean;
  height?: string;
}

const LineChart: React.FC<LineChartProps> = ({
  labels,
  dataSets,
  xAxisTitle,
  yAxisTitle,
  verticalLineAt,
  highlightAreaFrom,
  showSymbols = true,
  height = "400px"
}) => {
  // Formatter for y-axis
  const formatYAxisLabel = (value: number) => {
    if (value >= 1000000) {
      return (value / 1000000).toFixed(1) + "M";
    } else if (value >= 1000) {
      return (value / 1000).toFixed(1) + "K";
    } else {
      return value.toFixed(1);
    }
  };

  // Formatter for x-axis
  const formatXAxisLabel = (label: string) => {
    return label
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  // Formatter for axis titles
  const formatAxisTitle = (title: string) => {
    return title
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  // Formatter to capitalize each word in the legend label
  const formatLegendLabel = (label: string) => {
    return label
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const series: SeriesOption[] = dataSets.map((dataSet, index) => {
    // Create a gradient of blues for different lines
    const colors = [
      '#004aad', // adrsta-blue (primary)
      '#a832a0', // slightly lighter
      '#3e964b', // even lighter
      '#96833e', // lightest
    ];
    
    return {
      name: formatLegendLabel(dataSet.name),
      type: "line",
      data: dataSet.data,
      lineStyle: {
        color: colors[index % colors.length],
        type: dataSet.lineType || "solid",
        width: 3, // Slightly thicker lines
      },
      itemStyle: {
        color: colors[index % colors.length],
      },
      areaStyle: {
        color: colors[index % colors.length],
        opacity: 0.1, // Light area fill
      },
      symbol: showSymbols ? "circle" : "none",
      symbolSize: 4, // Reduced from 8 to 4 pixels
      label: {
        show: false, // Disable value labels on data points
      },
      markLine: verticalLineAt !== undefined
        ? {
          data: [
            {
              xAxis: verticalLineAt,
              lineStyle: {
                type: "dashed",
                color: "#004aad", // complementary to adrsta-blue
                width: 2
              },
              label: {
                show: false,
              },
            },
          ],
          symbol: "none",
        }
        : undefined,
      markArea: highlightAreaFrom !== undefined
        ? {
          data: [
            [
              {
                xAxis: highlightAreaFrom,
                itemStyle: {
                  color: "rgba(20, 57, 105, 0.1)", // adrsta-blue with low opacity
                },
              },
              {
                xAxis: "max",
              },
            ],
          ],
        }
        : undefined,
    };
  });

  const option: EChartsOption = {
    tooltip: {
      trigger: "axis",
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
      borderColor: '#004aad',
      borderWidth: 1,
      textStyle: {
        color: '#004aad',
      },
      formatter: (params: any) => {
        let tooltipContent = `${params[0].axisValue}<br/>`;
        params.forEach((param: any) => {
          const value = Math.round(param.value);
          tooltipContent += `${param.marker} ${param.seriesName}: ${value}<br/>`;
        });
        return tooltipContent;
      }
    },
    legend: {
      data: dataSets.map((dataSet) => formatLegendLabel(dataSet.name)),
      top: "5%",
      left: "2%",
      right: "5%",
      textStyle: {
        color: '#004aad',
        fontSize: 14,
        fontWeight: 'bold'
      }
    },
    grid: {
      left: '100px',
      right: '20px',
      bottom: '100px',
      top: '30%',
    },
    xAxis: {
      type: "category",
      data: labels,
      name: formatAxisTitle(xAxisTitle),
      nameLocation: "middle",
      nameGap: 70,
      nameTextStyle: {
        fontSize: 16,
        color: '#004aad',
        fontWeight: 'bold'
      },
      axisLabel: {
        formatter: (value: string) => formatXAxisLabel(value),
        rotate: 45,
        fontSize: 14,
        color: '#004aad',
        fontWeight: 'bold',
        margin: 15
      },
      axisLine: {
        lineStyle: {
          color: '#004aad',
          width: 2
        }
      },
    },
    yAxis: {
      type: "value",
      name: formatAxisTitle(yAxisTitle),
      nameLocation: "middle",
      nameGap: 70,
      nameRotate: 90,
      nameTextStyle: {
        fontSize: 16,
        color: '#004aad',
        fontWeight: 'bold'
      },
      axisLabel: {
        formatter: (value: number) => formatYAxisLabel(value),
        fontSize: 14,
        color: '#004aad',
        fontWeight: 'bold'
      },
      axisLine: {
        lineStyle: {
          color: '#004aad',
          width: 2
        }
      },
      splitLine: {
        lineStyle: {
          color: 'rgba(20, 57, 105, 0.1)',
          type: 'dashed'
        }
      },
      min: 'dataMin',
      max: 'dataMax',
    },
    series,
  };

  return (
    <ReactECharts option={option} style={{ height: height, width: "100%" }} />
  );
};

export default LineChart;
