import { Table, Breadcrumb, Tag, Button } from "antd";
import React, { useEffect, useState, useCallback, useRef } from "react";
import { JobPendingComponentProps } from "../../../models/interfaces";
import { JobType } from "../../../constants/JobTypes";
import {
  JobConfig,
  JobRun,
} from "../../../models/interfaces";
import axiosInstance from "../../../utils/axiosInstance";
import { useDispatch } from "react-redux";
import mixpanel from "mixpanel-browser";

import type { AppDispatch } from "../../../redux/store";
import {
  fetchJobConfigList,
  fetchJobRunList,
  resetJobConfigList,
} from "../../../redux/geoLiftSlice";
import { useSelector } from "react-redux";
import type { RootState } from "../../../redux/store";
import Loader from "../../../components/Loader";
import { useDebounce } from "../../../utils/hooks";
import dayjs from "dayjs";
import { Link, useNavigate } from "react-router-dom";
import { Events } from "../../../constants/Event";
import {
  ClockCircleOutlined,
  SyncOutlined,
  CheckCircleOutlined,
  QuestionCircleOutlined,
  CloseCircleOutlined,
  PlusCircleOutlined
} from '@ant-design/icons';
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';


const geoBreadCrumbs = [
  {
    name: "Home",
    url: "/",
  },
  {
    name: "Measurement",
    url: "/measurement/geo-lift",
  },
  {
    name: "Geo Lift Measurement",
    url: "/measurement/geo-lift",
  },
];

const GeoLift: React.FC = () => {
   const dispatch = useDispatch<AppDispatch>();
  // const { jobRunList, totalCount } = useSelector(
  //   (state: RootState) => state.geoLift
  // );
  // const [page, setPage] = useState(1);
  // const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const abortControllerRef = useRef<AbortController | null>(null);

  // const [hasMore, setHasMore] = useState(true);
  // const limit = 10;
  // const [currentPage, setCurrentPage] = useState(1);
  // const [isInitialLoad, setIsInitialLoad] = useState(true);

   // Access state from Redux
   const jobConfigList = useSelector((state: RootState) => state.geoLift.jobConfigList);
   const jobRunList = useSelector((state: RootState) => state.geoLift.jobRunList);
 
   useEffect(() => {
     const urlParams = new URLSearchParams(window.location.search || window.location.hash.split('?')[1]);
     const limit = 10;
     const offset = 0;
     const fetchData = async () => {
       try {
         dispatch(fetchJobConfigList({ 
                   limit, 
                   offset,
                 }));
         dispatch(fetchJobRunList());
       } catch (error) {
         console.error("Error fetching data:", error);
       }
     };
 
     fetchData();
   }, [dispatch]);




  const handleViewClick = (
    jobType: JobType,
    jobConfigId: string,
    jobName: string
  ) => {
    if (jobType === JobType.GeoLift) {
      navigate(`/measurement/geo-lift/${jobConfigId}?name=${jobName}`);
    }
    mixpanel.track(Events.BUTTON_CLICK, {
      jobType,
      action: "View",
      jobConfigId,
    });
  };

  // const getJobStatus = (jobConfig: JobConfig, jobRunList: JobRun[]) => {
  //   const jobRun = jobRunList.filter(
  //     (jobRun) => jobRun.jobConfigId === jobConfig.jobConfigId
  //   );
  //   if (jobRun && jobRun.length === 1) {
  //     if (jobRun[0].jobStatus === "RUNNING") {
  //       return (
  //         <div className="flex items-center gap-2">
  //           <div className="animate-spin rounded-full h-4 w-4 border-2 border-primary border-t-transparent"></div>
  //           <span>Running</span>
  //         </div>
  //       );
  //     } else if (jobRun[0].jobStatus === "PENDING") {
  //       return (
  //         <div className="flex items-center gap-2">
  //           <div className="flex gap-1">
  //             <div className="w-1.5 h-1.5 bg-primary rounded-full animate-bounce [animation-delay:-0.3s]"></div>
  //             <div className="w-1.5 h-1.5 bg-primary rounded-full animate-bounce [animation-delay:-0.15s]"></div>
  //             <div className="w-1.5 h-1.5 bg-primary rounded-full animate-bounce"></div>
  //           </div>
  //           <span>In Queue</span>
  //         </div>
  //       );
  //     }
  //   }
  //   if (jobRun && jobRun.length === 1 && jobRun[0].jobStatus === "FAILED") {
  //     return (
  //       <div className="flex items-center gap-2 text-error">
  //         <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  //           <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
  //         </svg>
  //         <span>Failed</span>
  //       </div>
  //       // <button
  //       //   className="btn btn-sm"
  //       //   onClick={() => onExecuteClick(jobConfig.jobConfigId)}
  //       //   disabled={loadingJobs[jobConfig.jobConfigId]}
  //       // >
  //       //   {loadingJobs[jobConfig.jobConfigId] ? "Executing..." : "Execute"}
  //       // </button>
  //     );
  //   } else if (
  //     jobRun &&
  //     jobRun.length === 1 &&
  //     jobRun[0].jobStatus === "SUCCESSFUL"
  //   ) {
  //     return (
  //       <div>
  //         <button
  //           className="btn btn-sm"
  //           onClick={() =>
  //             handleViewClick(jobRun[0].jobType, jobRun[0].jobConfigId, jobConfig.name)
  //           }
  //         >
  //           {jobRun[0].jobType === JobType.GeoLift ? 'View Synthetic Control' : 'View'}
  //         </button>
  //       </div>
  //     );
  //   } 
    
  // };

  const getJobStatus = (jobConfig: JobConfig, jobRunList: JobRun[]): string => {
    // const jobRun = jobRunList.find(
    //   (jobRun) => jobRun.jobConfigId === jobConfig.jobConfigId
    // );
  
    // if (!jobRun) return "UNKNOWN"; // Fallback case
    return jobRunList[0].jobStatus; // Return raw status string
  };


  const records = [...jobConfigList]
    .sort((a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime())
    .map((jobConfig) => ({
      key: jobConfig.jobConfigId, // Ensure unique row keys
      name: jobConfig.name,
      createdDate: jobConfig.createdDate,
      status: getJobStatus(jobConfig, jobRunList),
      jobType: jobConfig.jobType,
      jobConfigId: jobConfig.jobConfigId, // Needed for links
    }));

  // const getJobStatus = (jobConfig: JobConfig, jobRunList: JobRun[]) => {
  //   const jobRun = jobRunList.filter(
  //     (jobRun) => jobRun.jobConfigId === jobConfig.jobConfigId
  //   );
  //   if (jobRun && jobRun.length === 1) {
  //     if (jobRun[0].jobStatus === "RUNNING") {
  //       return (
  //         <div className="flex items-center gap-2">
  //           <div className="animate-spin rounded-full h-4 w-4 border-2 border-primary border-t-transparent"></div>
  //           <span>Running</span>
  //         </div>
  //       );
  //     } else if (jobRun[0].jobStatus === "PENDING") {
  //       return (
  //         <div className="flex items-center gap-2">
  //           <div className="flex gap-1">
  //             <div className="w-1.5 h-1.5 bg-primary rounded-full animate-bounce [animation-delay:-0.3s]"></div>
  //             <div className="w-1.5 h-1.5 bg-primary rounded-full animate-bounce [animation-delay:-0.15s]"></div>
  //             <div className="w-1.5 h-1.5 bg-primary rounded-full animate-bounce"></div>
  //           </div>
  //           <span>In Queue</span>
  //         </div>
  //       );
  //     }
  //   }
  //   if (jobRun && jobRun.length === 1 && jobRun[0].jobStatus === "FAILED") {
  //     return (
  //       <div className="flex items-center gap-2 text-error">
  //         <svg
  //           xmlns="http://www.w3.org/2000/svg"
  //           className="h-4 w-4"
  //           fill="none"
  //           viewBox="0 0 24 24"
  //           stroke="currentColor"
  //         >
  //           <path
  //             strokeLinecap="round"
  //             strokeLinejoin="round"
  //             strokeWidth={2}
  //             d="M6 18L18 6M6 6l12 12"
  //           />
  //         </svg>
  //         <span>Failed</span>
  //       </div>
  //       // <button
  //       //   className="btn btn-sm"
  //       //   onClick={() => onExecuteClick(jobConfig.jobConfigId)}
  //       //   disabled={loadingJobs[jobConfig.jobConfigId]}
  //       // >
  //       //   {loadingJobs[jobConfig.jobConfigId] ? "Executing..." : "Execute"}
  //       // </button>
  //     );
  //   } else if (
  //     jobRun &&
  //     jobRun.length === 1 &&
  //     jobRun[0].jobStatus === "SUCCESSFUL"
  //   ) {
  //     return (
  //       <div>
  //         <button
  //           className="btn btn-sm"
  //           onClick={() =>
  //             handleViewClick(
  //               jobRun[0].jobType,
  //               jobRun[0].jobConfigId,
  //               jobConfig.name
  //             )
  //           }
  //         >
  //           {jobRun[0].jobType === JobType.GeoLift
  //             ? "View Synthetic Control"
  //             : "View"}
  //         </button>
  //       </div>
  //     );
  //   } 
  // };

  // const loadMoreJobs = useCallback(
  //   async (page: number) => {
  //     const offset = (page - 1) * limit;

  //     // Cancel previous request
  //     if (abortControllerRef.current) {
  //       abortControllerRef.current.abort();
  //     }

  //     const abortController = new AbortController();
  //     abortControllerRef.current = abortController;

  //     try {
  //       const result = await dispatch(fetchJobConfigList({ 
  //         limit, 
  //         offset,
  //       }));

  //       if (fetchJobConfigList.fulfilled.match(result)) {
  //         // Verify the response is for the current page
  //         if (page !== currentPage) return;

  //         const newTotal = result.payload.totalCount;
  //         setHasMore(offset + limit < newTotal);
  //         setIsInitialLoad(false);
  //       }
  //     } catch (error) {
  //       if (!axios.isCancel(error)) {
  //         console.error("Error fetching jobs:", error);
  //       }
  //     } finally {
  //       if (abortControllerRef.current === abortController) {
  //         abortControllerRef.current = null;
  //       }
  //     }
  //   },
  //   [dispatch, limit, currentPage]
  // );

  // const handleScroll = useDebounce((e: React.UIEvent<HTMLDivElement>) => {
  //   if (isInitialLoad) return;

  //   const element = e.currentTarget;
  //   const { scrollTop, scrollHeight, clientHeight } = element;

  //   if (!loading && hasMore && scrollHeight - scrollTop <= clientHeight * 1.2) {
  //     setCurrentPage(prev => prev + 1);
  //   }
  // }, 300);

  // useEffect(() => {
  //   if (currentPage === 1 && isInitialLoad) {
  //     loadMoreJobs(currentPage);
  //   }
  // }, [currentPage, isInitialLoad, loadMoreJobs]);

  // useEffect(() => {
  //   dispatch(fetchJobRunList());
  // }, [dispatch]);

  // useEffect(() => {
  //   return () => {
  //     if (abortControllerRef.current) {
  //       abortControllerRef.current.abort();
  //     }
  //     dispatch(resetJobConfigList());
  //   };
  // }, [dispatch]);

  return (
    <div className="h-full overflow-y-auto">
      <div className="mb-6 flex justify-between items-center p-4">
        <div>{/* This empty div helps maintain proper spacing */}</div>
        <Link to={`/measurement/geo-lift/${uuidv4()}`}>
          <Button
            type="primary"
            icon={<PlusCircleOutlined />}
            className="flex items-center gap-2"
          >
            Create Geo Lift Job
          </Button>
        </Link>
      </div>

      {/* Job Config Table */}
      <Table
        columns={[
          {
            title: "Name",
            dataIndex: "name",
            key: "name",
            sorter: (a, b) => a.name.localeCompare(b.name),
            render: (text: string, record: any) => (
              <Link
                to={`/measurement/geo-lift/${record.jobConfigId}`}
                className="text-blue-600 hover:text-blue-800 hover:underline"
              >
                {text}
              </Link>
            ),
          },
          {
            title: "Created Date",
            dataIndex: "createdDate",
            key: "createdDate",
            render: (date) => dayjs(date).format("MMM D, YYYY HH:mm"),
            sorter: (a, b) => dayjs(a.createdDate).unix() - dayjs(b.createdDate).unix(),
          },
          {
            title: "Status",
            dataIndex: "status",
            key: "status",
            filters: [
              { text: "Running", value: "RUNNING" },
              { text: "In Queue", value: "PENDING" },
              { text: "Failed", value: "FAILED" },
              { text: "Successful", value: "SUCCESSFUL" },
            ],
            onFilter: (value, record) => record.status === value,
            render: (status, record) => {
              switch (status) {
                case "RUNNING":
                  return (
                    <div className="flex items-center gap-2">
                      <div className="animate-spin rounded-full h-4 w-4 border-2 border-primary border-t-transparent"></div>
                      <span>Running</span>
                    </div>
                  );
                case "PENDING":
                  return (
                    <div className="flex items-center gap-2">
                      <div className="flex gap-1">
                        <div className="w-1.5 h-1.5 bg-primary rounded-full animate-bounce [animation-delay:-0.3s]"></div>
                        <div className="w-1.5 h-1.5 bg-primary rounded-full animate-bounce [animation-delay:-0.15s]"></div>
                        <div className="w-1.5 h-1.5 bg-primary rounded-full animate-bounce"></div>
                      </div>
                      <span>In Queue</span>
                    </div>
                  );
                case "FAILED":
                  return (
                    <div className="flex items-center gap-2 text-error">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                      <span>Failed</span>
                    </div>
                  );
                case "SUCCESSFUL":
                  return (
                    <button
                      className="btn btn-sm"
                      onClick={() =>
                        handleViewClick(record.jobType, record.jobConfigId, record.name)
                      }
                    >
                      {record.jobType === JobType.GeoLift
                        ? "View Synthetic Control"
                        : "View"}
                    </button>
                  );
                default:
                  return <span>Unknown</span>;
              }
            },
          },
        ]}
        dataSource={records} 
        rowKey="key"
        pagination={false}
        // loading={loading}
        scroll={{ y: "calc(100vh - 200px)" }}
      />;

      {/* Loading indicator for infinite scroll */}
      {/* {loading && page > 1 && (
        <div className="text-center p-4">
          <Loader size="small" />
        </div>
      )} */}
    </div>
  );
};

export default GeoLift;
