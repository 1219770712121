import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "./counterSlice";
import geoLiftReducer from "./geoLiftSlice";
import jobMMMReducer from "./jobMMMSlice";
import bidOptimizerReducer from "./bidOptimizerSlice";
import nextBestActionReducer from "./nextBestActionSlice";
// import geoLiftOutputReducer from "./geoLiftOutputSlice";

const store = configureStore({
  reducer: {
    counter: counterReducer,
    geoLift: geoLiftReducer,
    jobMMM: jobMMMReducer,
    bidOptimizer: bidOptimizerReducer,
    nextBestAction: nextBestActionReducer,
    // geoLiftOutput: geoLiftOutputReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
