import React, { useState, useEffect } from "react";
import GeoBasedIncrementility from "../../components/GeoBasedIncrementility";
import axiosInstance from "../../utils/axiosInstance";
import Loader from "../../components/Loader";
import { SalesApiResponse } from "../../models/interfaces";
import MMMDashboardCard from "../../components/MMMDashboardCard";

interface GeoTest {
  title: string;
  subtitle?: string;
  date: string;
  action?: "VIEW_RESULTS" | "VIEW_CONTROLS";
  jobConfigId: string;
}

interface LiftData {
  percentage: number;
  title: string;
  confidence: number;
  date: string;
  spend: number;
  sales: number;
}

interface SalesData {
  withoutCampaign: number;
  withCampaign: number;
  adSpend: number;
  incrementalSales: number;
  totalLiftPercentage: number;
}

interface JobConfig {
  jobConfigId: string;
  name: string;
  jobType: string;
  description: string | null;
  createdDate: string;
  modifiedDate: string;
  version: number;
}

interface ApiResponse {
  items: JobConfig[];
  total_count: number;
  limit: number;
  offset: number;
}

interface JobRun {
  jobRunId: string;
  tenantId: string;
  jobConfigId: string;
  jobType: string;
  jobStatus: string;
  jobRunCount: number;
  jobRunTimeout: number;
  jobExecutionTime: string;
  createdDate: string;
  modifiedDate: string;
}

interface JobRunApiResponse {
  items: JobRun[];
  total_count: number;
  limit: number;
  offset: number;
}

interface MMMJobConfig {
  jobConfigId: string;
  name: string;
  jobType: string;
  description: string | null;
  createdDate: string;
  modifiedDate: string;
  version: number;
  dataMappingConfig: {
    mappings: {
      mmm: {
        columnMapping: {
          media_variables: string[];
          impression_variables: string[];
          target_variables: string[];
          non_media_variables: string[];
          date_variables: string[];
        };
      };
    };
  };
}

interface MMMApiResponse {
  items: MMMJobConfig[];
  total_count: number;
  limit: number;
  offset: number;
}

const Dashboard: React.FC = () => {
  // State for API data
  const [geoTests, setGeoTests] = useState<GeoTest[]>([]);
  const [jobRuns, setJobRuns] = useState<JobRun[]>([]);
  const [mmmJobRuns, setMmmJobRuns] = useState<JobRun[]>([]);
  const [liftData, setLiftData] = useState<LiftData | undefined>();
  const [salesData, setSalesData] = useState<SalesData | undefined>();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [mmmData, setMmmData] = useState<MMMJobConfig[]>([]);

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);

    // Format date as "21 Jul"
    const formattedDate = date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
    });

    // Format time as "3.45PM"
    const formattedTime = date
      .toLocaleTimeString("en-GB", {
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      })
      .replace(":", ".")
      .toUpperCase();

    return `${formattedDate} | ${formattedTime}`;
  };

  useEffect(() => {
    const fetchAllData = async () => {
      setIsLoading(true);

      try {
        const [
          geoDataResponse,
          jobRunsResponse,
          mmmDataResponse,
          mmmJobRunsResponse,
        ] = await Promise.all([
          axiosInstance("/api/jobConfig/jobType/GEO_LIFT"),
          axiosInstance("/api/jobRun/jobType/GEO_LIFT"),
          axiosInstance("/api/jobConfig/jobType/MMM"),
          axiosInstance("/api/jobRun/jobType/MMM"),
        ]);

        // Process GeoTest data
        const geoData: ApiResponse = geoDataResponse.data;
        const transformedTests: GeoTest[] = geoData.items.map((item) => ({
          title: item.name,
          subtitle: item.description || undefined,
          date: formatDate(item.createdDate),
          action: "VIEW_RESULTS",
          jobConfigId: item.jobConfigId,
        }));
        setGeoTests(transformedTests);

        // Process JobRuns data
        const jobRunsData: JobRunApiResponse = jobRunsResponse.data;
        setJobRuns(jobRunsData.items);

        // Process MMM data
        const mmmData: MMMApiResponse = mmmDataResponse.data;
        setMmmData(mmmData.items);

        // Process MMM JobRuns data
        const mmmJobRunsData: JobRunApiResponse = mmmJobRunsResponse.data;
        setMmmJobRuns(mmmJobRunsData.items);
        setIsLoading(false);
      } catch (err) {
        setError(err instanceof Error ? err.message : "An error occurred");
      } finally {
        setIsLoading(false);
      }
    };

    fetchAllData();
  }, []);

  useEffect(() => {
    const fetchSalesData = async () => {
      try {
        setIsLoading(true);
        // Using dummy data for now
        const dummyData: SalesApiResponse = {
          lift: {
            totalLiftPercentage: 0.1,
            totalSalesWithCampaign: 15818258.40625,
            totalSalesWithoutCampaign: 15801110.78125,
          },
          roas: {
            totalROAS: 0.011496783481683788,
            totalAdSpendForSyntheticMarkets: 1491514.9987228084,
            totalIncrementSales: 17147.625,
          },
          graph: {
            totalLiftPercentage: 0.1,
            totalSalesWithCampaign: 15818258.369015511,
            totalSalesWithoutCampaign: 15801110.897783525,
            salesWithCampaignData: [
              1166554.178270676, 1109201.8778431525, 1057844.571496023,
            ],
            salesWithoutCampaignData: [
              1210199.2222754387, 1127781.2111894663, 1119739.365889609,
              1064655.6001515728,
            ],
          },
        };

        // Transform API data to match SalesData interface
        const transformedData: SalesData = {
          withoutCampaign: Math.ceil(dummyData.lift.totalSalesWithoutCampaign),
          withCampaign: Math.ceil(dummyData.lift.totalSalesWithCampaign),
          adSpend: dummyData.roas.totalAdSpendForSyntheticMarkets,
          incrementalSales: dummyData.lift.totalLiftPercentage * 100,
          totalLiftPercentage: dummyData.lift.totalLiftPercentage, // Convert to percentage
        };
        setSalesData(transformedData);
        setIsLoading(false);
      } catch (err) {
        setError(
          err instanceof Error
            ? err.message
            : "An error occurred fetching sales data"
        );
      } finally {
        setIsLoading(false);
      }
    };

    fetchSalesData();
  }, []);

  // Keep the mock data for liftData for now
  useEffect(() => {
    setLiftData({
      percentage: 4.1,
      title: "Geo lift for Adrsta",
      confidence: 60,
      date: "21 Jul | 08:20-10:30",
      spend: 20000,
      sales: 68000,
    });
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <GeoBasedIncrementility
        geoTests={geoTests}
        liftData={liftData}
        salesData={salesData}
        totalLiftPercentage={salesData?.totalLiftPercentage}
        jobRuns={jobRuns}
      />
      <MMMDashboardCard
        salesData={{
          withCampaign: salesData?.withCampaign || 0,
          withoutCampaign: salesData?.withoutCampaign || 0,
        }}
        spendData={{
          spend: 0,
          sales: 0,
        }}
        height={300}
        showLegend={false}
        chartType="sales"
        mmmTests={mmmData.map((item) => {
          const latestJobRun = mmmJobRuns.find(
            (run) => run.jobConfigId === item.jobConfigId
          );
          return {
            name: item.name,
            date: formatDate(item.createdDate),
            status:
              latestJobRun?.jobStatus === "COMPLETED"
                ? "completed"
                : latestJobRun?.jobStatus === "FAILED"
                ? "error"
                : latestJobRun?.jobStatus === "RUNNING"
                ? "in_progress"
                : "completed",
            mediaVariables:
              item.dataMappingConfig?.mappings?.mmm?.columnMapping
                ?.media_variables || [],
            jobConfigId: item.jobConfigId,
            showControls: !latestJobRun || latestJobRun.jobStatus !== "RUNNING",
          };
        })}
      />
      {/* <GeoLiftStudy/> */}
    </>
  );
};

export default Dashboard;
