import { useState } from "react";
import {
  Home,
  BarChart2,
  ShoppingCart,
  MessageSquare,
  Database,
  Mail,
  LineChart,
  Activity,
  ChevronLeft,
  ChevronRight,
  HomeIcon,
  Users,
  Share2,
  ChevronDown,
  ChevronRight as ChevronRightIcon,
  Earth,
  Phone,
  Mail as MailIcon,
  CreditCard,
  Box,
  ShoppingBag,
} from "lucide-react";
import { useNavigate, useLocation } from "react-router-dom";
import { Facebook } from "react-feather";

interface MenuItem {
  title: string;
  path: string;
  icon?: React.ReactNode;
  badge?: string;
  isHeader?: boolean;
  isDivider?: boolean;
  isBeta?: boolean;
  children?: MenuItem[];
  isExpanded?: boolean;
}

const menuItems: MenuItem[] = [
  {
    title: "Dashboard",
    path: "/",
    icon: <HomeIcon className="w-5 h-5" />,
  },
  {
    title: "Planning",
    path: "/planning/marketing-mix-model",
    icon: <Activity className="w-5 h-5" />,
  },
  {
    title: "Buying",
    path: "/optimization/bid-optimizer",
    icon: <ShoppingCart className="w-5 h-5" />,
  },
  {
    title: "Next Best Action",
    path: "/next-best-action",
    icon: <LineChart className="w-5 h-5" />,
    isBeta: true,
  },
  {
    title: "Measurement",
    path: "/measurement/geo-lift",
    icon: <BarChart2 className="w-5 h-5" />,
  },

  {
    isDivider: true,
    title: "",
    path: "",
  },
  {
    isHeader: true,
    title: "INTEGRATIONS",
    path: "",
  },
  {
    title: "Social Media",
    path: "/social/analytics",
    icon: <Earth className="w-5 h-5" />,
    children: [
      {
        title: "Facebook",
        path: "/integrations/facebook",
        icon: <Facebook className="w-5 h-5" />,
      },
      {
        title: "Google",
        path: "/integrations/google",
        icon: <Database className="w-5 h-5" />,
      },
      {
        title: "TikTok",
        path: "/integrations/tiktok",
        icon: <Share2 className="w-5 h-5" />,
        isBeta: true,
      },
      {
        title: "Amazon",
        path: "/integrations/amazon",
        icon: <ShoppingBag className="w-5 h-5" />,
        isBeta: true,
      },
    ],
  },
  {
    title: "CRM",
    path: "/crm",
    icon: <Users className="w-5 h-5" />,
    children: [
      {
        title: "Contacts",
        path: "/crm/contacts",
        icon: <Phone className="w-5 h-5" />,
      },
      {
        title: "Email",
        path: "/crm/email",
        icon: <MailIcon className="w-5 h-5" />,
      },
      {
        title: "Sales",
        path: "/crm/sales",
        icon: <CreditCard className="w-5 h-5" />,
      },
      {
        title: "Products",
        path: "/crm/products",
        icon: <Box className="w-5 h-5" />,
      },
    ],
  },
];

export default function AppSidebar() {
  const [isOpen, setIsOpen] = useState(true);
  const [expandedItems, setExpandedItems] = useState<string[]>([]);
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigation = (path: string) => {
    if (path) {
      navigate(path);
    }
  };

  const toggleExpand = (title: string) => {
    setExpandedItems((prev) =>
      prev.includes(title)
        ? prev.filter((item) => item !== title)
        : [...prev, title]
    );
  };

  const renderMenuItem = (item: MenuItem, index: number, level: number = 0) => {
    if (item.isDivider) {
      return <div key={index} className="my-4 border-t border-gray-200" />;
    }

    if (item.isHeader) {
      return isOpen ? (
        <div
          key={index}
          className="px-4 py-2 text-xs font-semibold text-gray-400"
        >
          {item.title}
        </div>
      ) : null;
    }

    const isActive =
      item.path === "/"
        ? location.pathname === "/"
        : location.pathname.startsWith(item.path);

    const hasChildren = item.children && item.children.length > 0;
    const isExpanded = expandedItems.includes(item.title);

    return (
      <div key={index}>
        <div
          className={`flex items-center px-4 py-2 cursor-pointer rounded-lg transition-colors
            ${
              isActive
                ? "bg-amber-500/10 text-amber-600"
                : "text-gray-600 hover:bg-gray-100"
            }
            ${item.isBeta ? "opacity-60" : ""}
            ${level > 0 ? "ml-4" : ""}`}
          onClick={() => {
            if (hasChildren) {
              toggleExpand(item.title);
            } else {
              handleNavigation(item.path);
            }
          }}
        >
          {item.icon && (
            <span
              className={`${isActive ? "text-amber-600" : "text-gray-500"}`}
            >
              {item.icon}
            </span>
          )}
          {isOpen && <span className="ml-3">{item.title}</span>}
          {isOpen && item.badge && (
            <span className="ml-auto text-xs bg-red-500 text-white px-2 py-0.5 rounded-full">
              {item.badge}
            </span>
          )}
          {isOpen && item.isBeta && (
            <span className="ml-auto text-xs bg-gray-200 text-gray-600 px-2 py-0.5 rounded-full">
              Beta
            </span>
          )}
          {isOpen && hasChildren && (
            <span className="ml-auto">
              {isExpanded ? (
                <ChevronDown className="w-4 h-4" />
              ) : (
                <ChevronRightIcon className="w-4 h-4" />
              )}
            </span>
          )}
        </div>
        {isOpen && hasChildren && isExpanded && (
          <div className="mt-1 overflow-y-auto max-h-96">
            {item.children?.map((child, childIndex) =>
              renderMenuItem(child, childIndex, level + 1)
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="flex">
      <div
        className={`h-screen bg-white transition-all duration-300 border-r relative ${
          isOpen ? "w-64" : "w-20"
        }`}
      >
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="absolute -right-3 top-4 bg-white border rounded-full p-1 hover:bg-gray-100 z-10"
        >
          {isOpen ? (
            <ChevronLeft className="w-4 h-4" />
          ) : (
            <ChevronRight className="w-4 h-4" />
          )}
        </button>
        <div className="space-y-1 p-4 h-full overflow-y-auto overflow-x-hidden">
          {menuItems.map((item, index) => renderMenuItem(item, index))}
        </div>
      </div>
    </div>
  );
}
